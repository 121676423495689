const rbakerJournal = [

    {
        id: 1,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Chaos Begins',
        content: `According to the highly official and definitely not ominous onboarding packet I received this morning, I am required to keep a personal research journal in lieu of mandatory therapy sessions. 
    
        This journal is encrypted, confidential, and "cannot be used against me in a court of law" (direct quote). Which, first of all - why say that? That’s not a reassuring thing to say. That’s the kind of thing you hear right before you get vaporized in a classified containment breach. 
        
        To whatever poor schmuck they've got reading these - I’ll be as entertaining as possible. It’s the least I could do.
        
        Well, anyways.
        
        I’ve done it. Somehow, against all odds (and several very stern warnings from my former colleagues), I’ve landed here at Demiurge Inc. The front gate alone looks like something out of a sci-fi movie - giant sleek panels, intimidating security scans, and what I’m 90% sure was an actual retinal scan. Or rectal scan. Either way, it was fancy. I half-expected them to hand me a laser gun instead of a keycard. Maybe the keycard is a laser gun?
        
        My new office is a step up from the closet I had at CERN (though, in my defense, the closet had a certain coziness after I rigged the thermostat). This place? Fully stocked lab, shiny equipment that probably costs more than my lifetime salary, and a whiteboard the size of a basketball court. Heaven.
        
        The people are a mixed bag so far. Jenkins - a guy who I assume works here but whose actual job remains a complete mystery - gave me the initial tour. He pointed out the coffee machine ("best in the building"), the break room ("avoid it"), and something called the Singularity Pen ("don’t ask"). Good start. I like his vibe.
        
        Then there’s Dr. Orion Vale. He’s like a walking, talking computer. Efficient, precise, and terrifyingly (too) serious. I tried to crack a joke about quantum entanglement being ‘the ultimate long-distance relationship,’ and he looked at me like I’d just insulted his entire ancestry. Noted.
        
        And then there’s Dr. Evelyn Strauss. Chief Entropy Researcher. The woman who quite literally studies how things fall apart for a living. I have met a lot of scientists in my time - some eccentric, some arrogant, some absolute lunatics - but Strauss is something else entirely. She walked into the room like she already owned it, looked me up and down like she was trying to gauge my expiration date, and then immediately went back to reviewing a data pad without so much as acknowledging my existence.
        
        I don’t know whether to be impressed or insulted. Probably both.
        
        I get the sense she’s the kind of person who doesn’t bother with social pleasantries because she’s too busy thinking. Not in the way Vale thinks - cold, calculated, as if the entire universe is just an equation waiting to be solved - but in a way that suggests she’s already five steps ahead of the problem and just waiting for the rest of us to catch up. She barely said three words to me, but I could tell she wasn’t amused by my wiseass remarks. That might be a challenge worth pursuing.
        
        They want me to help with something called Project EPOCH. Some quantum manipulation experiment that might actually reverse time flow on a subatomic level. Naturally, I said yes before they could finish explaining the safety protocols. Safety is the prophylactic of the lab - how can I feel anything if I'm not throwing myself into the deep end?
        
        So here I am. Day one. I haven't been escorted out of the building yet, which means things are already going better than I expected. I’m ready to break some laws (of physics), bend a few dimensions, and turn this lab into my playground. Feels like the start of something beautiful.`
    }
    ,

    {
        id: 2,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Acclimating (or: How to Make Friends and Piss Off Vale)',
        content: `Week one at Demiurge Inc. and I have already: 
        
        1. Rearranged half the labels in the supply closet so that everything reads just slightly wrong. Not enough to cause problems, just enough to make people question their reality. ("Liquid Nitrogen" is now "Solid Nitrogen," "Caution: Biohazard" now reads "Caution: Spicy Soup").
        
        2. Discovered that the break room coffee machine does, in fact, have a setting labeled “Reality-Shattering Espresso.” I pushed it. I am still waiting for the consequences. (Other than diarrhea.)
        
        3. Met Dr. Amelia Cross, the Reality Tuning genius. She walked into the lab, took one look at me, sighed deeply, and then proceeded to completely ignore my existence while adjusting the quantum resonance arrays. Respect.
        
        4. Learned that Dr. Orion Vale does not have a sense of humor. Or if he does, it’s buried somewhere deep in the ninth dimension. I installed a secondary power switch under his desk labeled "EMERGENCY TIME STOP" that actually just turns off his desk lamp. He didn’t notice for an hour. When he did, I didn't see so much as a smirk on his face. Dick.
        
        5. Got locked out of my own lab because I forgot my ID badge. Tried to convince security that I was just a future version of myself coming back in time to prevent a major catastrophe. They were also not amused.
        
        6. Found the Singularity Pen that Jenkins mentioned yesterday. It is not a metaphor. It is a literal black hole inside a containment field that looks just like a child's playpen. The sign next to it just says, “DO NOT DROP.” I have so many questions and exactly zero answers.
        
        7. Managed to replace every instance of the phrase "gravitational singularity" in today's research logs with "big ol’ space suck-off." IT WENT TO PRINT. No regrets.
        
        But perhaps the most interesting development of the week: Dr. Evelyn Strauss actually spoke to me.
        
        It was brief. It was direct. It was a borderline clinical evisceration of my most recent test results.  
        
        “Your calculations are off,” she said, dropping a marked-up copy of my own report onto my desk without breaking stride. “You didn’t account for resonance feedback in the fourth dimensional layering - your containment model is unstable.”  
    
        “I was planning to compensate for that in the next trial,” I shot back, even though, in full honesty, I absolutely was not.  
        
        She stopped, turned slightly, and gave me a look like she was seeing right through me. I suddenly understood why so many researchers avoid direct eye contact with her.  
    
        “Were you?”  
    
        “Yes,” I lied. Smiled.  
    
        She held my gaze for a few seconds longer, then nodded - whether out of acceptance or amusement, I have no idea - and walked off.  
    
        It wasn’t much, but it was something.  
    
        Dr. Nicholas Manhav showed up later that day. Apparently, he used to work in Xenobiology before moving into Temporal Studies  - which either means he’s a genius or completely insane. Possibly both. I like him already. He wandered into the lab while I was recalibrating the temporal field and casually compared my work to fungal network growth.  
    
        “Your resonance fluctuations are behaving like a self-replicating system,” he said, watching my screen like he was analyzing an organism instead of an experiment. “Spreading instead of stabilizing. If it were biological, I’d say it was a survival mechanism.”  
    
        I stared at him for a full five seconds before responding.  
    
        “I think I love you.”  
    
        He left the room without acknowledging that statement.  
    
        Jenkins told me there’s an unwritten rule here at Demiurge: if you survive the first week without getting fired or erased from reality, you’re officially part of the team.  
    
        One more day to go.  
    
        TODAY'S MISSION:  
        - Survive.  
        - Cause minimal structural damage.  
        - Figure out how to stop calling Dr. Strauss ‘Dr. Sternface’ in my head.`
    },

    {
        id: 3,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Why You Don’t Mix Explosives with Philosophers',
        content: `I've been assigned to a project with DARPA and their entropic weapons division.  
    
        Today was the first time I worked directly with Dr. Evelyn Strauss, the Chief Entropy Researcher. Strauss walked into the lab with the energy of someone who already knows they’re the smartest person in the room. She wasn't wrong. She gave me a look that wasn’t quite disdain, but close enough to make me consider ironing my shirt for the first time in a decade.  
    
        “Dr. Baker,” she said, crisp and formal, as if she were trying out the words before deciding whether I deserved them. “I’ve heard about you.”  
    
        “Don’t believe everything you hear,” I replied, flashing what I hoped was a charming grin. “Some of it’s probably true.”  
    
        She didn’t laugh. I like a challenge.  
    
        The experiment of the day involved creating an entropic destabilization field capable of collapsing molecular cohesion without, you know, annihilating the entier facility and everyone inside of it. Simple stuff. I’d been tinkering with the energy distribution array for hours, trying to channel the noise into something useful, when Strauss decided to take over.  
    
        “No,” she said, pointing at my calculations. “You’re missing the resonance feedback from secondary harmonics. That’ll destabilize the field before it forms.”  
    
        “Secondary harmonics are a myth,” I shot back. “Like Bigfoot or Vale's sense of humor.”  
    
        She didn’t dignify that with a response, just stepped past me and started rewriting my equations on the board. Her handwriting was infuriatingly neat, the kind of handwriting that could be a goddamn font, and she jotted it so quickly it was as if it was already on the board and she was just revealing it.
    
        The field test started promisingly enough. The entropic destabilizer hummed to life, a low, unsettling vibration that made my teeth buzz. The target - an unfortunate filing cabinet - began to shimmer, its molecular bonds loosening under the pressure. Everything was going smoothly, until it wasn’t.  
    
        There’s a phenomenon I like to call “entropic jazz.” It’s when chaos decides to go off-script, improvising out of scale and out of tune. In other words, a fucking catastrophe. Today’s improvisation involved the destabilization field resonating with Strauss’ earrings.  
    
        Within seconds, the lab was a swirling maelstrom of gravity-defying office supplies and spectral filing cabinets phasing in and out of existence. A whiteboard marker started writing equations on its own (almost with Strauss-like speed) and a stapler levitated and began glowing ominously.  I don't trust the staplers in this lab. 
    
        Strauss, to her credit, barely blinked. “Shut it down,” she said, calm as ever.  
    
        “Shut it down?” I yelled over the cacophony. “This is the best part!”  
    
        She shot me a glare so sharp it could’ve collapsed my own molecular structure. I reluctantly hit the emergency failsafe, and the chaos subsided with a mournful whine.  
    
        The aftermath was a masterpiece of surrealism. The filing cabinet had folded into a perfect Möbius strip. A Möbius cabinet. The stapler was now chanting something that sounded suspiciously like Latin. I've heard of this happening before. Strauss’ earrings were still resonating, a high-pitched whine that made me wonder if they were secretly alien artifacts or the key to antoher dimension. But they're just earrings (nice ones, I might add).
    
        “Your gut hypothesis was reckless,” she said, brushing debris off her sleeve. “But effective. Barely.”  
    
        “That’s the highest compliment I’ve ever gotten,” I replied. “You should see me on a bad day.”  
    
        She didn’t smile, but I swear I saw the corner of her mouth twitch. Just a little. Score one for Ray!  
    
        Working with Strauss is going to be interesting. She’s precise, brilliant, and kinda scary. Exactly the kind of person you want on your side in a disaster - or causing one.  
    
        TODAY'S MISSION: Refine the destabilization field without summoning eldritch office supplies and convince Strauss I’m not a complete idiot.`
    },
    
    {
        id: 4,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Time Loop Problem (Not to Be Confused with The Coffee Problem)',
        content: `Mandatory journal entry, Day... I actually don’t know. 
        
        Time is broken.
    
        So, "yesterday" I accidentally created an infinite coffee loop. Apparently, management did not consider that a catastrophic failure. In fact, they promoted the idea to Phase Two testing. 
    
        Great news: I have been given even more access to temporal field manipulation.  
        Bad news: I may have already made it worse.  
    
        The experiment was supposed to be simple. Expand the previous field to test its stability under larger-scale conditions. Instead of a coffee cup, we used a chair. The hypothesis was that - when subjected to the same controlled field - it would phase-shift into a fixed temporal cycle without incident.  
    
        Reality had other plans.  
    
        Here is a non-exhaustive list of things that went wrong:  
    
        - The chair began vibrating at 400 Hz (concerning).  
        - The chair began screaming - like actual human screming - at 600 Hz (deeply concerning).  
        - The chair became two chairs (technically progress).  
        - One of the chairs was from the future (problematic).  
        - The future chair was already on fire (very problematic).  
        - Both chairs then phased out of our dimension.  
    
        Vale did not like this.  
    
        Fun fact: When you create an unintentional temporal rift, everyone suddenly cares about chairs.  
    
        Strauss, ever the professional, redirected the anomaly's waveform interference, and for a glorious five seconds, we had an image of the missing chairs.  
    
        They were in the break room.  
    
        In 1997. 
    
        Additional Notes:  
        - The break room did not exist in 1997.  
        - This means the chairs are in an alternate timeline.  
        - Jenkins insists this is "a win for archaeology."  
        - Vale is now threatening to use my bones for carbon dating. 
    
        Current action plan:  
        1. Locate and retrieve the chairs.  
        2. DO NOT accidentally retrieve anything else from 1997.  
        3. Convince Vale that this is, in fact, a valuable learning experience (unlikely).  
        4. Figure out why the future chair was already on fire. Is the future made of fire?
    
        In conclusion:  
        - The field is working. 
        - The containment grid is not.
        - Time is more flexible than anticipated.  
        - Vale is now looking into the legalities of "scientific exile."
    
        Will update when I have less fire-related results.`
    },

    {
        id: 5,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Schrödinger’s Oven',
        content: `Incident Report #23251. "Unauthorized Use of the Cross-Dimensional Data Transmission Array." 
    
        Alleged Offense:  
        - "Personal misuse of lab resources."  
        - "Bypassing security protocols."  
        - "Wasting valuable experimental time for a non-scientific purpose."  
    
        What Actually Happened:  
        I used advanced cross-dimensional quantum surveillance technology to check if I had left my oven on.  
    
        In my defense, this was a completely justified use of resources.  The array is designed to remotely observe parallel realities.  My house exists in one of those realities.Therefore, logically, this was a valid test of the equipment.  
    
        Manhav and Vale disagreed.  
    
        "Baker, you redirected an entire quantum entanglement array - during an active experiment - because you couldn't remember if you turned a knob."  
    
        "Incorrect," I said. "I did it because I value safety."  
    
        “You delayed our data collection by five minutes.”  
    
        "Yeah, but what if my house had burned down in that time? Then where would we be? Homeless and sad that’s where."  
    
        He handed me a disciplinary report and a formal warning.  
    
        Noteworthy Findings:  
        - My oven was indeed off.  
        - There was a cat on my kitchen counter. Not my cat. Unknown origin.  
        - The transmission array briefly flickered into another version of my house where I had apparently painted the walls lime green. I have never done this. I am now deeply unsettled.  
        - Jenkins watched the entire thing unfold, nodded, and said, "Honestly, that was a good use of science."  
    
        Action Taken:  
        - I was assigned to recalibrate the equipment. (Fair.)  
        - Access to the array has been restricted. (Unfair.)  
        - I have to attend a lab protocol briefing next week. (Pointless.)  
    
        Lessons Learned:  
        - The Cross-Dimensional Data Transmission Array is extremely effective at remote viewing.  
        - My alternate selves have terrible taste in paint colors.  
        - Next time, I will pretend I’m running a safety test and avoid getting caught.  
    
        I need to figure out whose cat that was.`
    },   

    {
        id: 6,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Reality Is Optional, Actually',
        content: `According to Vale, Project EPOCH is "on hold indefinitely pending containment recalibration." According to Strauss, it is "a failed experiment with a high risk of temporal destabilization." According to me, it was fucking awesome and got shut down way too soon.
    
        The way I see it, if a project ends with only one minor causality paradox and a single incident of recursive time loops involving a hypercaffeinated Jenkins, then that’s a win. But sure, fine, whatever. Time is too dangerous to play with. Boring.
    
        So, naturally, I pivoted to something far safer: Controlled Reality Fragmentation. 
    
        Turns out, reality is a lot flimsier than advertised. I barely had to push before it started coming apart at the seams. One minute, I’m running a simple resonance test inside a containment grid, and the next I have two break rooms. Identical. Same coffee stains, same flickering light in the corner, same passive-aggressive note about "not nuking fish" on the microwave door. The only difference is that one of them is from the 1980s. We're going even further back in time...
    
        Jenkins, being Jenkins, immediately tried to make coffee in both. I didn’t stop him because, well, I was curious. Turns out that was a mistake. The coffee came out self-aware. It started asking existential questions. We shut it down before it could have a full-blown crisis, but the damage was done. We officially created a beverage with sentience. 
    
        Vale, to no one's surprise, is deeply unhappy. He asked me if I had any idea how I was going to "repair the structural integrity of spacetime." I said, "Define ‘repair.’" He left the room. I think I heard him whispering about accelerated retirement plans.
    
        And then, just when I thought my day couldn’t get any weirder, in walks Dr. Manhav. 
    
        Walks in, takes one look at the duplicated break rooms, and instead of screaming, or backing away slowly, or calling security, he just nods. Says something about how fungal networks sometimes do this on a cellular level. Like that’s a normal sentence. Like that explains anything.
    
        I have questions.
    
        First of all, what the hell kind of fungi has this guy been studying? Second, why is he so calm about this? Third, does he even know where he is? Because if I had walked into a workplace actively duplicating itself, I might have at least hesitated before launching into casual metaphors.
    
        He’s weird. I love him.
    
        Strauss, meanwhile, is already tired of us. She’s stopped reacting. Just sighs when I enter a room now, like she’s emotionally pre-bracing for the inevitable.
    
        But she didn’t shut it down.
    
        Vale is furious, Jenkins is unfazed, Manhav is intrigued, and Strauss? She’s watching. Not stopping me. Not shutting me down. Just watching.
    
        So. Where does that leave us?  
        - Break room count: Two.  
        - Time since last coffee-based anomaly: Four hours.  
        - Manhav: A potential cryptid.  
        - Vale: Considering alternate careers.  
        - Strauss: I don’t know.  
        - Me: About to push the fragmentation field even further.  
    
        Because if I can duplicate a break room by accident, imagine what I can do on purpose.`
    },

    {
        id: 7,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Science Should Be Fun, Dammit',
        content: `Incident Report #40635. "Unauthorized Modifications to the Biomechanical Neural Interface."  
    
        The Allegation:  
        - "Interference with active brainwave mapping sessions."  
        - "Unethical manipulation of test subjects’ neural stimuli."  
        - "Involuntary laughter, confusion, and spontaneoous orgasms."  
    
        What Actually Happened:  
        Look. Science is supposed to be fun. Neural mapping is inherently boring - it’s all about monitoring brain activity while people sit still and think about numbers and light pulses and bullshit like that. So when I noticed how predictable the Biomechanical Neural Interface’s stimuli response system was, I figure why not spice things up a little?  
    
        I reprogrammed the interface to inject randomized emotional stimuli. The subjects experienced unexpected emotional spikes during mapping. The result was scientifically fascinating and also fucking hilarious.  
    
        Subject 1 started laughing mid-meditation and couldn't stop (they later rated the experience "refreshing"). Subject 2 was suddenly convinced they were a duck. No idea why, but it was fascinating nonetheless. Alarmingly, subject 3 started going into full body seizures until I realized he was mid-multiple-orgasm. Vale stormed into the lab as subject 3 was still panting and demanded to know what the hell was going on.  
    
        “Baker, what did you do?”  
    
        “Enhancing cognitive engagement.”  
    
        “You reprogrammed the system to randomly spike emotions during active scans.”  
    
        “Yes. And?”  
    
        “And someone just came all over the observation table.”  
    
        “Which proves my hypothesis: The brain is hilariously easy to hack.”  
    
        I was immediately removed from the project. I was reassigned to recalibrate the neural mapping equipment. I was warned that if I did this again, I would be suspended from the lab.But here’s the kicker: The data was actually really interesting. The brainwave readings showed heightened engagement and rapid neuron synchronization. In other words, my "joke" may have actually unlocked a better way to stimulate cognitive function.
        
        Science is often predicated on accidents. Time and time again, scientific discovery is rooted in mistakes, wild ideas, or runaway experiments. Why not a joke for once? 
        
        Manhav refuses to acknowledge this. Vale wants me as far away from neuro-tech as possible. I'll try not to get banned from another research division and see if I can access my old data before they wipe it.
        
        This tech could revolutionize the medical industry. Or at least the sex toy industry.`
    },    

    {
        id: 8,
        date: 'DATE: ███████ TIME: ████████',
        title: 'What If We Could Age in Reverse? (Spoiler: We Shouldn’t.)',
        content: `The thing about time is that people assume it moves in one direction. They assume that just because we experience it linearly, that’s the way it has to work. 
    
        That assumption is wrong. And today, I proved it.  
    
        Chrono-Biological Regression Therapy. The idea was simple. If we can alter the state of a quantum field, why not a biological state? Why not turn back the clock on living tissue? Restore cells to an earlier form, heal injuries, maybe even reverse aging itself. 
    
        Manhav walked into my lab today and actually hesitated before sitting down, which I think means he’s finally figured out that working with me is a bad idea. Unfortunately for him, he’s too curious for his own good.  
    
        “You’re serious about this?” he asked. 
    
        “Oh, absolutely not. But I’m doing it anyway.”  
    
        He didn’t argue, which is how I know he’s interested.  
    
        So we ran the first test. 
    
        Phase One: Cellular Sample Trials.  
        - Applied a contained chronon field to an extracted tissue sample.  
        - Expected result: Time reversion at a cellular level.  
        - Actual result: It worked.  

        The tissue reverted to an earlier state, regenerating damaged cells. 
        
        Manhav looked genuinely impressed, which is rare.  
    
        “Alright,” he said, examining the live data. “That’s not nothing.”  
    
        “That’s definitely not nothing,” I said, already prepping for the next test.  
    
        Phase Two: Small Organism Exposure.  
        - Used a controlled field on a test subject.  
        - The subject: A lab rat named Pascal.  
         
        For exactly two minutes, Pascal got younger. He moved faster. Heart rate dropped to juvenile levels. He was, by all accounts, a younger version of himself. 
        
        Then, he started phasing. Time didn’t just slow down for him - it stopped obeying cause and effect.  
    
        “That’s not supposed to happen,” Manhav muttered, watching the data shift.  
    
        And then Pascal blinked out of existence.  
    
        Not dead. Not gone. Just... somewhere else.  

        “That’s not supposed to happen,” Manhav repeated.
    
        “Where did he go?” I asked.  
    
        Manhav stared at the readout like it was personally offending him.  
    
        “I don’t think it’s where,” he said. “I think it’s when.”  
    
        Phase Three: Let’s Not Talk About Phase Three.  
    
        (Fine. If you must know, we tried it on meat - just a muscle sample. The reversal worked, but instead of stopping at its original form, it kept going. All the way back. Back to before it had been muscle. Back to stem cells. And then, well. It started multiplying.)
    
        That’s not supposed to happen.
    
        “This isn’t just cellular regression,” Manhav said. “It’s rewriting biological history.”  
    
        Which is technically fascinating, but also means I may have accidentally invented runaway tissue de-differentiation, which is a very fancy way of saying organic matter turning into something that shouldn’t exist.  
    
        We shut that test down before it could become an SCP entry.  
    
        Final Notes.  
        - Yes, we reversed biological aging.  
        - No, we do not understand the long-term effects.  
        - No, we do not know where Pascal is.  
        - No, Manhav is not okay with any of this.  
    
        Vale is going to have an aneurysm when he finds out. Manhav hasn’t stopped looking at the data since Pascal vanished.  
    
        Which means I was right about one thing - time isn’t linear. And if it isn’t linear, then what happens when we stop treating it like it is?`
    },   

    {
        id: 9,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Why I Am No Longer Allowed Near the Cafeteria (Scientific Morale Boosting)',
        content: `The thing about working in a high-stakes research facility where time can be folded, reality can be rewritten, and space itself occasionally develops sentience is that people can get lil tense. Scientists, by nature, are a fragile and cautious breed (for the most part). The slightest deviation in their calculations, the smallest anomaly in an otherwise controlled system, and suddenly their entire worldview crumbles.  
    
        It is, therefore, my moral imperative to remind my esteemed colleagues that none of this - none of this - is as serious as they like to pretend.  
    
        Enter: the mayonnaise jar incident.  Cafeteria, 12:30 PM. A room full of overworked, over-caffeinated scientists, methodically shoveling sustenance into their bodies while discussing things like quantum coherence and interdimensional stability. And then there’s me, sitting squarely in the middle of the room, unscrewing the lid of an entire mayonnaise jar and eating its contents with a spoon.  
    
        Except, of course, it wasn’t mayonnaise. It was vanilla pudding. But that distinction was lost on the horrified witnesses who saw me take slow, deliberate spoonfuls, pausing every now and then to nod approvingly as if I were savoring the nuanced complexity of room temp egg-based emulsion, dripping down my chin with a shit-eating grin.
    
        The psychological fallout was immediate and immeasurable. I watched in real-time as grown men and women struggled to reconcile their understanding of reality. One researcher actually left the room without finishing his sandwich. Another just sat there, fork hovering midair, staring at me like I had personally betrayed him.  
    
        I just wanted people to laugh, y'know? Shake things up a bit.  
    
        But the cafeteria debacle was just one of many scientific morale-boosting initiatives undertaken this week. I've also been asking Dr. Strauss if she had ever experienced déjà vu, then asking her again the next day, and the day after that. By day three, she stopped making eye contact with me. I love her. 

        I attached a sign with a QR code outside the restroom that led to a POOP QUALITY SURVEY. HR is, for some reason, upset about this. It's got valuable data! Seriously, go check it out at poopqualitysurvey.com   

        I've been repeatedly calling people, colleagues, subjects, mostly Vale, only to immediately say, “I’m a little busy, can I call you later?” before hanging up. Vale stopped answering my calls entirely. I love him.

        I almost got caught slipping flyers into the department mailboxes for the NATIONAL DICKSUCKING CHAMPIONSHIP, hosted by your mom. It was an old flyer I used to put around the dorm hallways at MIT. Big hit. Vale confiscated all the copies within an hour. I assume he was nervous about competition.  
    
        Unfortunately, some people in this lab fail to appreciate the value of social experimentation. Vale has accused me of being “a disruptive force” in the workplace, which is ironic given that his entire career is based on stopping reality from collapsing in on itself. One might argue that my efforts to test the cognitive resilience of my colleagues through minor psychological stressors are an equally important contribution to the field.  
    
        But sure. Go ahead and get upset about the Styrofoam Bonfire flyer.  
    
        Look, did I actually plan a bonfire? No. Was there a nonzero chance that Jenkins would have unironically tried to light a pile of polystyrene on fire? Also no - because Jenkins, unlike some people in this building, understands satire. I think.
    
        Regardless, the official memo has been issued. I am no longer allowed to post “unsanctioned notices” in common areas. I’m also banned from initiating conversation in restroom stalls. Still seems like an overreaction.  
    
        Despite all of Vale’s disapproval, despite the horrified glares, the confiscated materials, the emergency ethics review over the POOP QUALITY SURVEY, I know for a fact that Evelyn laughed.  
    
        Not in front of me, obviously. That would be too easy. But I caught it -just a flicker, the ghost of amusement passing over her face when she thought no one was looking. She’ll never admit it, but I know. And that is what makes it all worthwhile.  
    
        Still, it appears my efforts have been noticed by the higher-ups. I have been given an official disciplinary notice and assigned mandatory workplace behavioral training. It is scheduled for next Thursday.  
    
        I have already begun preparing my next experiment.`
    },
    

    {
        id: 10,
        date: 'DATE: ███████ TIME: ████████',
        title: 'You Ever Look at Reality and Think, "That Shouldn’t Be There?"',
        content: `Mandatory journal entry. Required for “personal reflection” and “long-term stability assessments.” Pretty sure this is just so they have a convenient paper trail when Vale finally convinces the higher-ups to classify me as an existential hazard. 
    
        Anyway. The break room situation has gotten worse. 
    
        It turns out that when you duplicate physical space using an entropic fragmentation field, it does not just stop duplicating because you ask it nicely. The two break rooms? Now there are four. I have officially created a fractal office environment.
    
        Vale came into the lab today and threw a clipboard onto my desk. I say “threw” because it made a very specific SMACK noise, and he didn’t say anything for at least ten seconds after that. Just stood there, glaring at me while I took a sip from my - well, one of my coffees. It’s hard to tell which one was from which break room at this point.
    
        “This is unsustainable,” he finally said. 
    
        “Technically, it’s self-sustaining,” I corrected. “That’s the problem.” 
    
        “You’re going to collapse the entire facility,” he shot back. 
    
        I flipped through the clipboard while he burned a hole in my skull with his gaze. New anomalies popping up all over the lab, minor stuff - recursive shadows, repeating audio, Jenkins getting stuck in a five-second loop where he kept walking into the same door over and over again. Weird, but not dangerous. Kinda funny, if you ask me.
    
        Manhav, however, is the only one who doesn’t seem remotely concerned. He stopped by later, completely unfazed, and asked if I’d noticed that the "reality fractures are following biological growth patterns." 
    
        “Think of it like a fungal network,” he said, pointing at the data model on my screen. “Each pocket of fragmented reality isn’t just appearing randomly. They’re branching. Spreading. Replicating.”
    
        “So you’re telling me reality has mycelium?” 
    
        “I’m telling you it behaves like a living system.”  
    
        I hate that he might be right. 
    
        The thing is, I can feel it now - the way space subtly vibrates in the areas where the fragmentation has taken hold. If you stand in one of the duplicated rooms for long enough, you start to hear something, a kind of low, oscillating hum, just beneath human perception. More of a feeling than a sound. And more of an emotional feeling than a physcial one.
    
        Not going to lie, it’s deeply unsettling. 
    
        Strauss finally stepped in today, not to stop me, but to ask questions. That’s how I know I’m onto something. She doesn’t waste time with dead projects. Vale is actively working against me now, but Evelyn might be on my side. Only time will tell.
    
        Manhav is already theorizing. He sees something in the pattern. 
    
        I should be worried. I should be more worried. But all I can think is, if this is how reality behaves when you push it, what happens when you SHOVE it?`
    },

    {
        id: 11,
        date: 'DATE: ███████ TIME: ████████',
        title: 'That Time Reality Got Motion Sickness',
        content: `Another incident report was filed against me today. To be fair, the words "containment breach" and "Dr. Baker" do show up together a lot, but in this case, I maintain my innocence. Mostly.  
    
        Okay so what happened was, we were running a stabilization test on the entropic fragmentation field - same one that’s been copy-pasting sections of the break room into different decades - and I wanted to push the containment grid further to see if we could map the edges of spatial integrity.  This, as it turns out, is not a thing that HAS edges.  
    
        The moment we expanded the grid, the field pulsed like it had just woken up from a bad dream. The lab twisted, bent inward, and for exactly 14 seconds, I was able to see myself standing in the doorway even though I was also across the room. Jenkins (poor bastard) ran into the rift and immediately vomited backwards into his own mouth, in a loop, nonstop. He was just caught in a conscious cycle of puking and then time looping back and returning it all from the floor back into his stomach, just to repeat the cycle again. A desk chair folded into an impossible non-euclidean shape and began beeping in Morse code. Manhav, for his part, just started taking notes like stenographer on meth. 
    
        Vale showed up right as the walls started melting. His reaction was as expected. Immediate shutdown. Typical.  
        
        Vale overrode the entire system and shut it down. The rift collapsed inward and reality just pretended nothing had happened.`  
    },    
    
    {
        id: 12,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Something Stepped Through',
        content: `The containment breach happened at 03:47 AM. I was there. I do my best work between midnight and morning. 
    
        Vale will say this was my fault, but let the record show - I was trying to fix it.  
    
        We’ve been monitoring the fragmentation field for the last forty-eight hours. It’s still spreading, slow but steady. Not random. Manhav keeps saying it looks intentional, which is not something I wanted to hear. I tried reinforcing the stabilization barriers, but all it did was make the distortions oscillate. The hum is louder now.
    
        I was in the lab running another calibration when I saw it.  
    
        The east hallway - Sector B. One of the older fractures, been there for about a week now, small but stable. Jenkins mentioned seeing "shadows moving weird" near it, but Jenkins says a lot of things and I figured he was just sleep-deprived.  
    
        But then the hum shifted. A different pitch. Like a note in a song I didn’t realize I was hearing.  
    
        And then the space inside the fracture bent inward.  
    
        It wasn’t light bending. It was the idea of space itself bending. If I had to describe it, I’d say it looked like a thought trying to become real.  
    
        And then it stepped out.  
    
        It wasn’t a shadow. It wasn’t an anomaly. It was something. A shape, but wrong. Not wrong like a mutation, wrong like a word you can’t pronounce even though it’s in your language. Wrong like looking in the mirror and seeing someone you don't recognize. Wrong like remembering something that never happened, but knowing it was real. It flickered in and out, like it was deciding whether or not to exist here.  
    
        And then it saw me.  
    
        That was the worst part. Because voidspace entities aren't supposed to be able to do that.  
    
        I don’t know if I moved first or if it did, but suddenly the emergency failsafes triggered - automatic spatial lockdown. The containment fields slammed into place and the fracture pulsed, the shape collapsing into itself like a wave pulling back. It was gone in microseconds.  
    
        I barely registered Vale shouting at me over the alarms, already at the console, locking down the entire wing. The fracture is sealed, for now, but that doesn’t mean it’s over.  
    
        Because I wasn’t imagining it. That thing looked back. It registered me.  
    
        And when it flickered out, it wasn’t like it disappeared. It was like it understood something.  
    
        Manhav, silent beside me, finally spoke.  
    
        "It responded," he said. "The fracture didn’t just let it through. It let it out."  
    
        I know what Vale is going to say tomorrow. He’s going to tell me that this proves I need to stop. That I’ve gone too far, that the fractures are a threat, that I’m going to tear a hole in reality and let something worse in.  
    
        He’s wrong.  
    
        Because I didn’t let something in.  
    
        Something was already there. I just gave it a door.`
    },

    {
        id: 13,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Time Loop Problem Part 2: Electric Boogaloo',
        content: `Another incident report. Time Loop Malfunction.  
    
        This was technically an accident. But in my defense, time shouldn't be this easy to break.  
    
        So, picture this: we're testing a new temporal containment field to see if we can slow down entropy on a localized scale. In layman terms, the field activates, time slows down for whatever’s inside, everyone high-fives me and calls me a genius.  
    
        Instead, what happened was the field locked onto me instead of the test object, I was stuck in a five-second loop for an hour (as if time matters anymore), and at some point, my past selves started making bets on what I’d say next.  
    
        Vale looked like he wanted to strangle me in multiple timelines.  
    
        "We should shut this down," Vale said, for the fiftieth time, like a fucking broken record.  
    
        "Just give me one more minute!" I yelled back. Or past me yelled back. Or future me yelled before me. It's hard to say.  Maybe all of them at once.
    
        Eventually, Strauss manually collapsed the field. Every version of me merged back into one, which felt like a reverse clown car. For the next five minutes, I could hear my own voice overlapping in my head. Jenkins swears he saw three of me shaking hands before I re-synced.  
    
        Vale’s verdict: 
        - Immediate termination of the experiment.  
        - Immediate termination of me (unconfirmed, but implied).  
        - "Scientific exile is still on the table."  

        Whatever. Buzzkill.
    
        In the meantime, I'll see if I can still finish the poker game I started with my past selves.`
    },    

    {
        id: 14,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Vale Can Eat a Dick',
        content: `Vale thinks this is over.  
    
        I got the shutdown order this morning. Officially classified, routed through three different oversight boards, signed, sealed, and delivered with a "Dr. Baker is indefinitely suspended from all active research." Very dramatic. Very official.  
    
        Very ignored.  
    
        I expected him to come storming in here after the breach, maybe start threatening me with clearance revocation, security escorts, something aggressive. But he didn’t. He just left the memo on my desk and walked out. That worries me more.  
    
        Because I saw his face. He’s not just trying to shut this down because it’s dangerous.  
    
        He’s shutting it down because he already knows what’s going to happen.  
    
        And that means he’s already lost.  
    
        The fractures aren’t mine anymore. They aren’t some experiment I can just unplug. They are spreading. Slowly, deliberately, following a pattern that isn’t random. They pulse now, like they’re breathing. If I stand near them long enough, I can feel the hum vibrating under my skin, shifting like a thought that hasn’t finished forming.  
    
        Manhav says they’re behaving like a living system. He’s been obsessing over the growth rates, running models, cross-referencing biological network behaviors. His latest theory? They aren’t just spreading, they’re communicating.  
    
        “They branch like fungal colonies,” he told me, scanning the latest frequency shifts. “The hum isn’t noise. It’s structured. It’s too precise to be random interference.”  
    
        “You think they’re talking to each other?”  
    
        “I think something is talking through them.”  
    
        Meanwhile, Evelyn hasn’t said a word. Not a reprimand, not a warning, not even a sarcastic remark about me being a walking liability. She’s just watching. Calculating. I know that look. She’s deciding what this means, how she can use it.  
    
        And that worries me more than Vale does.  
    
        I can tell she doesn’t disagree with him. But she also hasn’t stopped me. She’s positioning herself, like she’s waiting for something. Maybe waiting for me to see it too.  
    
        And the thing is... I think I do.  
    
        I’ve been running the resonance scans myself, isolating the fluctuations in the fractures. The hum shifts at specific intervals, almost like a pulse. And when I mapped it against our old Project EPOCH data, the timestamps matched. Perfectly.  
    
        That shouldn’t be possible.  
    
        Because that means the fractures aren’t just spreading - they’re syncing. Aligning to something.  
    
        I don’t know how. I don’t know why.  
    
        But I know one thing.  
    
        They aren’t random.  
    
        Something is watching.  
    
        And Vale is afraid because he knows it too.`
    },

    {
        id: 15,
        date: 'DATE: ███████ TIME: ████████',
        title: 'I’m Not Done Yet, and Neither is She',
        content: `The lab is quiet in a way it shouldn’t be.  
    
        Vale thinks I stopped. The shutdown order went through, the official documentation says I’m suspended, and yet - here I am. Still in my lab. Still working. Because if I stop, the fractures don’t stop with me.  
    
        I spent the last four hours mapping their growth patterns again, and Manhav was right. They aren’t random. They aren’t even unpredictable. They branch. They sync. They behave like a living system. And if something grows like a system, then something must be guiding it.  
    
        Manhav came by, carrying three cups of coffee, all from different break rooms, which is a rel problem because I shut down two of them last week. He set one down in front of me and said, “You need to look at this.”  
    
        “Is it another organism behaving in a way that should be impossible?”  
    
        “Sort of.”  
    
        He pulled up a frequency map from the last containment breach and overlaid it with the newest resonance readings from the fractures. The result was something I really, really didn’t want to see.  
    
        They were all in sync.  
    
        Not just structurally, not just in branching patterns - the resonance waveforms were identical.  
    
        “They aren’t just communicating,” Manhav muttered, scrolling through the data. “They’re listening. They’re reacting. They are adjusting to us.”  
    
        “That’s not how spacetime is supposed to work.”  
    
        “None of this is.”  
    
        He wasn’t wrong. But that wasn’t the part that got to me. It was the timing. The way the frequencies aligned. The fractures hadn’t just responded to the last breach - they had anticipated it.  
    
        Like something on the other side knew it was coming.  
    
        The air in the lab has felt different since that last breach. It isn’t just the hum anymore. There’s a weight to it, something that makes my skin crawl when I walk past certain containment areas. The fractures aren’t passive distortions.  
    
        They are aware.  
    
        And Evelyn knows it.  
    
        She hasn’t spoken to me about it directly, but she doesn’t have to. She’s been sitting on the observation deck for two days now, watching, reading, thinking. I know that look. It isn’t the look of someone trying to stop an experiment.  
    
        It’s the look of someone waiting for it to give her the right answer.  
    
        Which means she thinks there is an answer.  
    
        She hasn’t stopped me. She hasn’t helped me either, at least not in the way she could. But she’s still here. Vale is working against me, but Evelyn?  
    
        Evelyn is waiting.  For me.
    
        I can ignore a shutdown order. I can override security access. I can run my experiments underground.  
    
        But if Evelyn is waiting for something to happen, then that means she already knows it will.  And that means, sooner or later, I will too.`
    },

    {
        id: 16,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Evelyn',
        content: `The first time I realized Evelyn wasn’t just watching, but actively keeping me in the game, I told myself it was about the research.  
    
        That was easier.  
    
        I don’t know when it started. Maybe it was always there - her patience when she should have shut me down, the way she let my chaos run unchecked, the way she never quite pulled away as quickly as she should have.  
    
        But this, this is different.  
    
        Security has been closing in for days. I should have been locked out entirely by now. No access to my research logs, no way to continue stabilizing the fractures. Vale has been tightening the noose, and I should have been out of options.  
    
        I wasn’t.  
    
        Because Evelyn made a move.  
    
        I don’t need a signed confession to know it was her.  
    
        The security breach was surgical. At 0213 hours, my revoked credentials were reinstated for exactly eight minutes. Just long enough for me to recover my data before it would have been purged permanently. No alerts. No red flags. A clean override.  
    
        Manhav came by later. Didn’t say anything at first - just stood in the doorway, arms crossed, watching me. I kept my eyes on the terminal, pretending I didn’t notice him.  
    
        “This is different,” he said finally.  
    
        I didn’t answer.  
    
        “It’s not just the research anymore.”  
    
        I turned to him. He wasn’t looking at me. He was looking at the place where Evelyn had been standing earlier that night.  
    
        He sighed, shook his head, and left without another word.  
    
        I should have stopped this a long time ago. I should have taken the security lockdown as a sign, cut my losses, walked away.  
    
        But she is making sure I don’t. 
    
        And that changes everything.  
    
        When she came by later, I expected her to say something. To confirm what I already knew. To admit she had been covering for me this whole time, overriding protocols, shielding my work.  
    
        Instead, she just stood there, hands in the pockets of her lab coat, watching me in that way she does - like she’s waiting for me to reach the right conclusion on my own.  
    
        “You should stop,” she said.  
    
        I let out a breath of a laugh. “We both know I won’t.”  
    
        “I know.” A pause. The weight between us shifting. “But I had to say it.”  
    
        “So you have plausible deniability?”  
    
        She smiled. It wasn’t one of her sharp, cutting smiles. It was small, almost - almost soft.  
    
        I didn’t realize I had stepped closer until I felt the heat of her body, the barely-there space between us. She didn’t move away.  
    
        I don’t know who moved first. Maybe it was both of us. Maybe it was inevitable.  
    
        Her lips were cold when they met mine. A sharp contrast to the warmth of her skin, the electricity in the moment, the unspoken understanding that this was a mistake, but one we were both willing to make.  
    
        She pulled back first. Not because she wanted to - but because she had to.  
    
        “Move faster,” she murmured.  
    
        And then she was gone.  
    
        I don’t know how much time I have before Vale figures it out. But I know one thing.  
    
        I am not working alone.  
    
        And that changes everything.`
    },

    {
        id: 17,
        date: 'DATE: ███████ TIME: ████████',
        title: 'For Research Purposes',
        content: `There are a lot of things in this lab that we probably shouldn't be messing with. The Singularity Pen. The Infinite Staircase. The break room coffee machine. But the Reality Modulation Chamber? That was my best idea yet.  
    
        Look, when you work in a place where causality is more of a suggestion than a rule, there are certain opportunities you can take. And last night, Ev and I took one of those opportunities and turned it into something way more interesting than whatever Vale was planning to use that chamber for.  
    
        Not to brag, but as far as reckless, high-stakes "experiments" go, this one was a masterpiece. A scientific first. An act of controlled chaos so flawlessly executed that not a single sensor flagged it - well, at least, none that couldn’t be wiped. 

        You ever mutually orgasm while both of you are disassembled into a single monatomic vapor cloud?
    
        Sure, there were a few minor concerns. Like the gravitational fluctuations. And the electromagnetic field spike. And the brief moment where we may or may not have desynchronized from baseline reality. But did anything catastrophic happen? No.  
    
        Did it push the boundaries of what we thought was possible? Yes.  
    
        Did it push other boundaries? Also yes. (Smiling devil face emoji) 
    
        But now Ev is having one of her "I have a reputation to uphold" crises, but does she really think we can stop now? We’ve officially breached the event horizon, and there’s no turning back.  
    
        She says she doesn’t know how the lab didn’t pick up on what we were doing. I do. I made sure of it. I know the blind spots. I know how to scrub data logs before Vale can get his hands on them. And if Manhav asks why the gravitational readings spiked for fifteen minutes, I’ll just tell him it was an unrelated anomaly. Maybe Jenkins sneezed too hard and phased into a pile of gluons for a millisecond again.  It's not out of character for him.
    
        Ev is so many things. Brilliant, confident, a little too comfortable with the prospect of entropic annihilation. But above all, she’s curious. That’s why I know she’s not going to stop this either.  
    
        Next time, Zero-G. Maybe a Temporal Distortion Field if I can get access back.  
    
        Probably should keep it out of the Reality Modulation Chamber for now, though.  
    
        Probably.`
    },

    {
        id: 18,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Disaster at 5521-02',
        content: `I have lost people before. Not like this.
    
        Experiment 5521-02 was supposed to be a simple refinement of the previous tests, a controlled way to manipulate the permeability of the reality membrane. The goal was precision. Stability. The opposite of what actually happened.
    
        I should have seen it coming.
    
        Test parameters:
        - Probe calibrated to weak points in the membrane, allowing for smoother dimensional transitions.  
        - Small team of test subjects equipped with safety tethers to maintain a connection to baseline reality.  
        - Containment measures in place to prevent dimensional contamination.  
    
        Observed results:  
        - Everything went to shit in under twenty seconds.  
    
        When the probe activated, it worked. It worked beautifully. A controlled opening in the membrane, stable, readable, the kind of passage that could have redefined our understanding of interdimensional physics. The test subjects moved through.
    
        And then the probe pushed too deep. It'd be a funny statement if what followed wasn't catastrophic.  
    
        The resistance wasn’t in the numbers. It wasn’t in the data models. It wasn’t in any of the previous tests. But it was there, in the reality itself, in the way the air hummed in the wrong frequency, in the way the containment grid started vibrating before the collapse even began.
    
        The membrane didn’t just destabilize - it fought back.  
    
        I watched the tethers snap in real-time. One by one. The subjects were there, and then they weren’t. Not pulled into another dimension. Not phased out. Just gone.  
    
        I can still hear them screaming through the radio static.  
    
        And then the breach expanded.  
    
        The facility didn’t explode. It didn’t burn. It didn’t even collapse. It just stopped existing in the way it was supposed to.  
    
        The walls stretched, then folded. The floor started peeling away in segments like a bad rendering glitch. The space wasn’t breaking - it was rewriting itself.  
    
        I was outside the main testing area when it happened. The last thing I saw before the cameras cut out was the control panel showing every safety measure failing simultaneously.  
    
        It took five seconds for the entire site to be consumed.  
    
        Five seconds.  
    
        And then there was nothing.  
    
        Vale didn’t say anything when I emerged from the emergency containment lock. He just handed me a printed copy of my own experiment log with a red stamp on it:  
    
        "SUSPENDED. INDEFINITE TERMINATION."  
    
        I didn’t argue. 
    
        I couldn’t  
    
        Because this wasn’t a miscalculation. This wasn’t an accident.  
    
        This was the fractures reacting.  
    
        And for the first time, I understood that we were never in control of them.  
    
        I saw Evelyn in the observation deck hours later. She wasn’t looking at me. She was looking at the empty space where 5521-02 used to be.  
    
        And I swear, just for a moment, she looked like she already knew this was going to happen. There's something behind her eyes that betrays a deep knowledge, like she's seen it all before and she's just watching it play out again. 
    
        Vale wants me out.  
    
        Evelyn hasn’t said a word. 
    
        And I am going to keep working. Because whatever is on the other side of those fractures knows we’re looking now.`
    },    

    {
        id: 19,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Watching Grass Grow',
        content: `Vale finally made his move.  
    
        This morning, I arrived at my lab expecting to keep working on something important - reality fracturing, space-time anomalies, the usual. Instead, I was greeted with a reassignment notice taped to my desk with way too much force and underlined aggressively.  
    
        "Effective immediately, Dr. Baker is to oversee Experiment 7745: Long-Term Phototropic Observation of Artificially Cultivated Grass Samples."  
    
        In other words, they’ve put me on a project where my literal job is to watch fucking grass grow.  
    
        Vale didn’t even have the guts to tell me himself. He just left the notice and disappeared, probably to revel in the fantasy that I would actually sit here in a lab for weeks taking notes on the grass.  
    
        Manhav found out about it before I did and showed up just to watch my reaction. I think he was expecting something dramatic - maybe for me to flip my desk or stage an elaborate rebellion.  
    
        Then I remembered that I am physically incapable of following a directive that ridiculous.  
    
        So, naturally, I have turned this into a real experiment.  
    
        Introducing Experiment 8822.beta: Temporal Acceleration of Soil Microbial Activity.  
    
        If I have to sit here watching grass grow, I am going to make it the fastest-growing grass in recorded history.  
    
        I have already adjusted the lighting variables, introduced minor fluctuations in the quantum field oscillation, and am in the process of stabilizing a low-level chrono-excitation loop to speed up the biological processes in the soil.  
    
        Manhav caught me running calculations and just shook his head.  
    
        “You’re incapable of just doing nothing, aren’t you?”  
    
        “Oh, absolutely,” I said.  
    
        He watched me calibrate the field adjustments for a while and then, despite himself, pulled up a chair.  
    
        “Alright. Let’s break Vale’s stupid experiment.”  
    
        Evelyn hasn’t said anything about this, which tells me she already expected it to happen.  
    
        Which means she let it happen.  
    
        Which means she wants to see what happens next.  
    
        And honestly? So do I.`
    },

    {
        id: 20,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Bacteria Do Not Do That',
        content: `So, remember when Vale reassigned me to a completely harmless experiment to “keep me out of trouble”?  
    
        About that.  
    
        The microbes are not behaving.  
    
        The original idea was to use controlled temporal acceleration to speed up microbial growth in a contained soil ecosystem. I just wanted to see how fast we could push natural decomposition cycles and ecological succession.  
    
        And, to be fair, that part worked. The system flourished. We saw rapid nutrient cycling, symbiotic dependencies forming in record time, even some unexpected interspecies cooperation. Manhav actually got excited about it for a second.  
    
        Then the microbes started structuring themselves.  
    
        Not in the way colonies normally do - no biofilms, no typical growth patterns. Instead, they started forming geometric configurations.  
    
        That’s when Manhav stopped smiling.  
    
        “Bacteria do not do that,” he muttered, staring at the live feed. “They don’t organize like that. That’s - ” He paused, tilting his head. “That’s intentional.”  
    
        I hate that he said that.  He keeps saying that.
    
        I mean, sure, okay, we sped up evolutionary processes. Maybe they’re adapting at a rate we didn’t expect. But that doesn’t explain why they respond to stimuli in patterns.  
    
        It doesn’t explain why they react to observation.  
    
        Because bacteria shouldn’t know when they’re being watched.  
    
        So, logically, my next step should have been to shut the experiment down.  
    
        But did I do that?  
    
        No. No, I did not.  
    
        I introduced an external variable. Just to see what would happen. I adjusted the temperature gradient by half a degree. That’s it. A tiny change. Something they shouldn’t even register.  
    
        But they did.  
    
        And then they moved.  
    
        Not randomly. Not as part of a chemical reaction.  
    
        They moved in sync.  
    
        And they formed a perfect spiral.  
    
        Manhav turned off the screen and left the room without saying anything.  
    
        I stayed.  
    
        Because I know what I saw.  
    
        They didn’t just react.  
    
        They responded.  
    
        And that means they’re listening.  
    
        I’m going to tell Evelyn.  
    
        Not Vale.  
    
        Just Ev.`
    },

    {
        id: 21,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Buttplug',
        content: `There is something deeply satisfying about sending Amelia Cross an email that starts with "I've been tinkering with THE BUTTPLUG again." 
    
        To her credit, she didn’t ignore me. That, in itself, is a victory. 
    
        Amelia is one of those scientists who operates in a constant state of exasperated brilliance - like a conductor trying to keep an orchestra from setting their instruments on fire while I am, in fact, actively dousing my violin in gasoline. But she humors me, because she knows that sometimes, just sometimes, my catastrophes actually lead to breakthroughs. 
    
        And this one's got potential. 
    
        The Reality Tuning Device (henceforth and forever known as The Buttplug) is one of the most finely calibrated pieces of tech in the lab. We’ve used it to stabilize interdimensional resonance shifts, tune into alternate quantum states, and, on at least one occasion, accidentally receive a distress signal from what I can only describe as a "screaming meat universe" (we do not talk about that incident). 
    
        But I want to push it further. Past the "safe" adjacent realities, into the real unstable layers - the chaotic ones, the places where probability breaks down and nothing holds together for more than a few milliseconds. 
    
        Amelia, predictably, does not love this idea. 
    
        "Have you considered the implications of bridging chaotic layers to our own?" she wrote, which is scientist-speak for "Are you trying to get us killed?" 
    
        Yes, Amelia. Always. 
    
        But here’s the thing - those unstable dimensions? They don’t just collapse into entropy for no reason. There’s a pattern. A rhythm. Something beneath all that noise that keeps them from fully dissolving into nothing. And if there’s a pattern, that means there’s structure. If there’s structure, that means we can stabilize it. And if we can stabilize it, then we can use it. 
    
        She knows I have a point. Otherwise, she would’ve shut this down outright. Instead, she’s talking about containment fields and quantum resonance generators, which means she’s already thinking about how we make this work instead of whether we should. That’s the first step. 
    
        This is what I love about working with Amelia. She doesn’t pretend the laws of physics are immutable. She doesn’t look at the universe and say, "That’s impossible." She looks at the universe and says, "That’s stupid, and I hate it," and then rewrites the equations to make it work anyway. 
    
        So, yeah. We’re gonna do it. 
    
        Just need to make sure the Buttplug is tuned properly first. 
    
        TODAY’S MISSION:
        - Convince Amelia this is a good idea. (50/50 odds)
        - Avoid another screaming meat universe incident. (Low probability)
        - Make sure Vale doesn’t find out until after we get results.`
    },    

    {
        id: 22,
        date: 'DATE: ███████ TIME: ████████',
        title: 'You Kill What You Don’t Understand',
        content: `The lab is shut down. The containment chamber is locked. The experiment is over.  
    
        That’s what the official report says.  
    
        But let’s be clear about one thing - this wasn’t a failure.  
    
        The microbes didn’t die. They didn’t collapse. They didn’t lose cohesion. They were terminated because Vale got too scared.  
    
        They weren’t supposed to evolve like that. They weren’t supposed to react. But they did. And Vale did what Vale always does when faced with something outside his control - he erased it.  
    
        The thing is, I don’t think he did. Not entirely.  
    
        The last recorded resonance scan before containment lockdown showed something that shouldn’t have been there. Not just growth patterns, not just adaptive structuring - an actual signal.  
    
        The microbes weren’t just adapting. They were aligning.  
    
        To what? I don’t know.  
    
        But something else does.  
    
        Evelyn didn’t stop the termination, but she did something more interesting - she preserved the data. I saw her accessing the logs, making sure nothing was erased. She didn’t interfere, didn’t argue, didn’t even react when Vale made the call.  
    
        But she saved the data.  
    
        Which means she still thinks there’s something here.  
    
        So do I.  
    
        Manhav hasn’t spoken to me since the lockdown. That’s not unusual; he goes quiet when something bothers him. But I know what he’s thinking. He saw the same patterns I did. He saw the response.  
    
        And the worst part?  
    
        The microbes didn’t react to our experiments.  
    
        They reacted to the fractures.  
    
        And I don’t think they were just reacting.  
    
        I think they were waiting.`
    },
   
    {
        id: 23,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Why Did You Keep It?',
        content: `I waited until everyone else had cleared out.  
    
        The containment team finished sterilizing the microbial lab, Vale sent his final shutdown report, Manhav left without saying anything. I stayed behind, watching the data logs scroll by, trying to convince myself I wasn’t seeing what I was seeing.  
    
        Ev was still there. Of course she was.  
    
        “Why did you keep it?”  
    
        She didn’t look up from the console.  
    
        “Because it’s useful,” she said.  
    
        “That’s not an answer.”  
    
        She exhaled, barely even a sigh, just a subtle acknowledgment that I was pushing for something she wasn’t ready to say.  
    
        “Vale ordered the termination,” she said. “Not me.”  
    
        “But you didn’t stop it.”  
    
        “Because stopping it wasn’t the priority.”  
    
        “But saving the data was?”  
    
        She finally looked at me then, and I realized I wasn’t the one interrogating her—she was waiting for me to catch up.  
    
        “You already know the answer,” she said.  
    
        I wanted to argue, but she was right.  
    
        The microbes weren’t the experiment. They never were. The real experiment was the interaction.  
    
        They had reacted to the fractures.  
    
        They had listened.  
    
        And Ev had seen the same thing I had.  
    
        “You think they were connecting to something,” I said.  
    
        “I think they were responding,” she corrected.  
    
        She shut the console down and stood, the conversation already over in her mind.  
    
        “Vale will push for your removal after this,” she said. “It won’t be a request next time.”  
    
        “And what about you?”  
    
        She didn’t answer.  
    
        Just walked out of the lab, leaving the question hanging in the air between us.  
    
        Because I already knew the answer.  
    
        She wasn’t stopping me.  
    
        She never was.`
    },

    {
        id: 24,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Fault Lines',
        content: `Evelyn does not believe in fate. 
    
        She believes in probability, in equations that balance, in controlled collapse. She believes in entropy, but only because it is predictable - because if you can map decay, you can anticipate it. You can control it. The "storm" she calls it.
    
        And then there’s me. 
    
        I am not predictable. I am not controlled. I do not balance. I am the noise in the system, the statistical anomaly, the bad data point that ruins an otherwise perfect model. 
    
        And yet. 
    
        “You should stop.” That’s what she keeps saying. That’s what she tells me when the fractures pulse and Vale tightens his grip and the lab feels like it’s breathing under our feet. 
    
        But she never makes me stop. 
    
        She lets me in. 
    
        Last night, she let me in. 
    
        It wasn’t planned. It never is with us. There is too much energy between us, too much momentum, and when we collide, it is never careful. It is never soft. 
    
        It started the way it always does - with an argument. Something sharp, something cutting, something that should have driven us further apart. The containment breach. The microbes. The data Vale had destroyed and the data she had saved. 
    
        “You don’t trust him,” I said. “Not anymore.” 
    
        She had looked at me, eyes dark, calculating. “That doesn’t mean I trust you.” 
    
        “Then why are you here?” 
    
        A silence. Heavy. I could feel her thinking, could feel the weight of her hesitation, the war between logic and whatever this is. 
    
        “Because I don’t know how to stop,” she said finally. 
    
        And then she kissed me. 
    
        It was not tentative. It was not hesitant. It was a collapse, inevitable, an event horizon we had both already crossed a while ago. 
    
        Her fingers curled into the fabric of my shirt, and mine into the sharp angles of her jaw, and it was not soft, it was not gentle, it was need and pressure and the edge of something unsustainable. She was warm against me, warmer than she should have been, like her body ran a few degrees hotter than everyone else’s, like she was always burning and never let anyone close enough to feel it. 
    
        And god, I wanted to feel it. 
    
        Her breath was sharp when I backed her against the cold steel of the lab console, the emergency shutdown panel digging into her spine, but she didn’t stop me. She pulled me closer. Hands in my hair, on my throat, nails at my back, like she was trying to anchor herself to something, like she was trying to hold herself together. 
    
        We are both too stubborn to admit how much we need this. 
    
        We are both too reckless to stop. 
    
        In the low blue light of the lab, in the space between disaster and discovery, I felt her press her forehead against mine, felt the breath of a laugh ghost across my skin. 
    
        “We are going to get caught,” she murmured. 
    
        “Probably,” I said. “But you already knew that.” 
    
        Her lips curled at the corner. Not quite a smile. But close. 
    
        “One of these days, Ray,” she said, voice low, “you’re going to push too far.” 
    
        “And you’ll be right there with me.” 
    
        She didn’t deny it. 
    
        Because she knew. 
    
        Because this is not a controlled experiment. 
    
        Because this is the edge of the fault line, and we are standing on it. 
        
        Together.`
    },

    {
        id: 25,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Vale Says I’m Done. I Say He’s Wrong.',
        content: `Vale was waiting for me outside the lab.  
    
        That’s how I knew this wasn’t just another memo.  
    
        He didn’t waste time. No clipboard. No passive-aggressive bureaucratic phrasing. Just a long look, the kind that says he’s already had the argument in his head, already won it, and is now just waiting for the formality of saying the words out loud.  
    
        “You’re done.”  
    
        I smirked. I don’t know why I smirked. It wasn’t a conscious decision - it was just the natural response to the absurdity of the moment. Because Vale has been saying I’m done for weeks now, and yet, somehow, I’m still here.  
    
        “You keep saying that,” I said. “And yet, here I am.”  
    
        “Not for long.”  
    
        He stepped closer. Not a threat, not quite, but a weight. Like he needed me to understand, really understand, that this was different.  
    
        “I filed for immediate reassignment. Your security clearance is being revoked. You’ll be off-site by the end of the week.”  
    
        I didn’t laugh. Not because I believed him, but because I finally saw it - he wasn’t just trying to shut me down. He was afraid.  
    
        “You don’t get it, do you?” I said.  
    
        “No,” he snapped, “you don’t get it.”  
    
        His voice was sharp, but not angry. Not anymore. Just exhausted.  
    
        “This isn’t a debate. It’s not about whether your research is right or wrong. It’s not about how close you think you are to figuring it out. This is about containment. About keeping this place intact. About keeping people alive.”  
    
        I wanted to argue. I wanted to throw his own hypocrisy back in his face, because Demiurge was NEVER about playing it safe. That's why I joined in the first place. But something in the way he said it stopped me.  
    
        He wasn’t trying to win an argument.  
    
        He was trying to stop something much bigger.  
    
        “I saw the last scan logs,” I said. “I know the microbes weren’t just evolving. They were syncing.”  
    
        Vale’s jaw tightened.  
    
        “And I know Strauss saw it too,” I continued. “But she didn’t stop me.”  
    
        His eyes flickered at that. Not surprise - confirmation. He already knew.  
    
        “And you think that means she’s on your side?”  
    
        “I think it means she sees what I see.”  
    
        He let out a slow breath, like he was trying to weigh the cost of whatever he was about to say next. 
        
        Then, finally -   
    
        “That’s what I'm afraid of.”  
    
        We stood there for a long moment, neither of us moving.  
    
        “You’re off this project,” he said, softer now, final. “You’re off all of it.”  
    
        “And if I don’t leave?”  

        He shook his head and walked away in silence.
    
        He didn’t answer.  
    
        He didn’t have to.  
    
        Because for the first time, I finally understood what he wasn’t saying.  
    
        He wasn’t asking me to leave.  
    
        He was telling me while I still had the chance.`
    },

    {
        id: 26,
        date: 'DATE: ███████ TIME: ████████',
        title: 'No More Denial',
        content: `Ev didn’t look at me when she bypassed the security checkpoint.  
    
        She could have done it from her terminal, but she didn’t. She came down here, swiped her card, manually approved my clearance override, and then stood there for a moment, waiting.  
    
        Not for me to say anything.  
    
        Just waiting.  
    
        I didn’t ask how she did it. Didn’t ask how long I had before Vale figured it out. Didn’t ask if this was a mistake.  
    
        Because I already knew the answer.  
    
        “This won’t last,” she said finally, voice quiet.  
    
        “I know.”  
    
        A flicker of hesitation, a shift of weight. “Then move faster.”  
    
        And just like that, she was gone again.  
    
        The fractures are changing.  
    
        It’s not just reactionary shifts anymore. It’s not just pattern alignment. They are responding to me.  
    
        The last test sequence showed a direct correlation between my proximity to the primary fracture site and the waveform oscillation rate. The hum, the pulsing resonance, it matches my energy signature now.  
    
        I have become a variable in my own equation.  
    
        I don’t have time to analyze it. Vale has doubled containment security across the facility. Manhav has stopped looking me in the eye.  
    
        He knows what’s coming.  
    
        So do I.  
    
        And so does Evelyn.  
    
        She came back later. Didn’t say anything at first, just walked straight past the workstations and over to me. She reached for my wrist, turned my hand over, pressed something into my palm.  
    
        A keycard. Clearance level Alpha-2.  
    
        Higher than mine.  
    
        “This is a bad idea,” I said, even though I was already closing my fingers around it.  
    
        “Everything we’ve done is a bad idea.”  
    
        “Vale will know.”  
    
        “I know.” A pause. The quiet kind, where words are being weighed. “You still have time. Not much. But enough.”  
    
        I don’t know what she saw when she looked at me just then.  
    
        But whatever it was, it made her step forward, fingers curling into the fabric of my lab coat like she wanted to say something she didn’t have the words for.  
    
        “Move faster,” she murmured.  
    
        And then she kissed me.  
    
        It wasn’t a desperate thing, or a reckless thing. It wasn’t rushed or uncertain. It was deliberate. And that was what terrified me.  Because it meant we weren’t pretending anymore.  
    
        She pulled away first. Just enough to breathe, but not enough to let go.  
    
        “No more mistakes,” she whispered.  
    
        “No more denial,” I corrected.  
    
        And then she was gone again.  
    
        I don’t know how much time I have before Vale figures it out. Before I push this past the point of no return.  
    
        But I know this: I am not alone.  
    
        And that changes everything.`
    },
    
    {
        id: 27,
        date: 'DATE: ███████ TIME: ████████',
        title: 'How to Sell Your Soul for Science',
        content: `I have been asked to volunteer for Experiment 8934.omega.  
          
        Not "asked" in the traditional sense. More like "cornered in the break room by Vale and Manhav while trying to microwave a burrito" and handed a stack of non-disclosure agreements that definitely contained more redactions than actual words.  Most of which describing if I’d like to be torn apart at the subatomic level.
          
        "We need a test subject," Vale said, his usual robotic efficiency fully engaged. "Someone uniquely suited for high entropy stabilization under voidspace conditions."  
          
        "And you thought of me?" I asked, peeling open my burrito like it was an autopsy. "That's sweet. Normally, people only come to me when they need plausible deniability or a designated scapegoat."  
          
        "Both still apply," Vale muttered.  
          
        “We need a subject,” Manhav said, his tone as neutral as if he were asking me to pass the salt.  

        “For?”  

        “Experiment 8934.omega,” Manhav laughed, grinning like a lunatic. “Fractionation.”  

        "Fractionation," I repeated. "So, what, you want to split me into tiny Baker pieces and see if I still function?"  

        Manhav lit up, like I had just perfectly summarized an incredibly complicated procedure that took him months to develop. "Exactly! We isolate your entropic resonance signature, separate your quantum state into multiple coherent entities, and use the resultant void-loop stabilized fragments to generate perpetual energy!"  

        “Ah,” I said. “Right. Obviously.”  

        “The goal is long-term stabilization of entropic feedback loops,” Vale added, as if that clarified anything. “Your unique neural and quantum resonance patterns make you an ideal candidate.”  

        I took a moment to process this. “You are, essentially, asking me to let you rip my entire being apart and stuff my soul into a perpetual motion machine?”  

        “A controlled one,” Vale corrected.  

        “Theoretically controlled,” Manhav added.  

        “Theoretically controlled.” I nodded, rubbing my temples. “Cool. So my existence becomes a series of quantum duplicates running on infinite loops, and you just expect me to say yes to this?”  

        Manhav beamed. “We were hoping you’d see the scientific merit-”  

        “Oh, no no no, I absolutely do.” I waved my hands. “I love this idea. It’s hilarious. You’re offering me a first-class ticket to multiversal schizophrenia. I get to be my own research subject AND my own case study in existential horror.”  

        “So that’s a yes?” Vale asked, dead serious.  

        I looked between them, and that’s when it hit me: they weren’t joking. 

        Manhav, bless his fungus-infested mind, was vibrating with excitement, like a kid about to take apart a bug just to see what’s inside. Vale, meanwhile, looked like he had already moved past my response and was mentally filing the necessary paperwork.  

        “You do realize I am a living, breathing person with thoughts and emotions, right?” I asked. "Pretty important to the lab, too, if I oughta admit."  

        “Yes,” Vale said, unbothered.  

        “And you’re asking me to shatter my entire self into multiple, potentially unstable copies, and just see what happens?”  

        “With safety precautions,” Manhav added. “Probably.”  

        Jesus Christ. 

        “Look,” I said, running a hand down my face. “I do dumb, reckless, scientifically unsound shit all the time. But this? This is...” I trailed off, searching for the right word. "...fucking awesome.”  

        Manhav fist-pumped. Vale remain stone-faced, but a small nod.  

        Here’s the thing. I could have said no. Any rational person would have said no. But the second they laid this out in front of me, I knew I wanted to see what would happen. I would be able to experience something the likes of which nobody has ever seen. I would be a cosmonaut for the void. A being outside of time and space and reality itself.   

        I mean, come on. How could I resist?  

        What does it feel like to be split into multiple versions of yourself?  Would I be able to talk to myself? Argue with myself? Play cards with myself?  Would each of me have different personalities?  Would there be an evil one? (Or worse, would they all be evil, and I’m actually the boring one?)  Would I still remember being whole?  

        Would I ever be whole again?  

        I had a moment - just a second - where I hesitated. Because I knew, somewhere deep down, that this wasn’t just some fun science experiment.  

        “You’ll be making history,” Manhav said, sensing my pause.  

        “Not sure I want to make history as ‘The Man Who Got Turned into a Quantum Jigsaw Puzzle,’” I muttered.  

        “Think of it this way,” Vale said, in what I assume was his attempt at encouragement. “The individual fragments will still be you. In a sense.”  

        In a sense.  

        That should have been my cue to run.  

        Instead, I grinned, grabbed the tablet Vale was holding, and pressed ACCEPT.  

        Let’s find out what's on the other side.`
    },

    {
        id: 28,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Night Before',
        content: `Evelyn is worried.

        She hasn’t said it outright - well, okay, she has, in no uncertain terms, via email - but even if she hadn’t, I’d know. It’s in the way she keeps triple-checking the numbers, in the way her fingers hover over the console even when she isn’t actively running diagnostics. It’s in the way she lingers just a little longer in the lab, like she’s afraid that if she leaves, I might not be here when she gets back.

        I want to tell her it’s going to be fine. That she’s being overly cautious. That we’ve done the math, run the models, accounted for every conceivable variable. But I know Evelyn. She’s not afraid because of the things we can predict. She’s afraid because of the things we can’t.

        I get it. I really do.

        This isn’t just a routine experiment. It’s not like tweaking quantum oscillations or stabilizing a low-tier entropy collapse. This is my mind. My self. We’re about to split me into multiple pieces, and the assumption - the hope - is that I’ll be able to come back together like nothing happened. But we don’t actually know that, do we?

        That’s the kicker. That’s the part that gets under my skin if I sit still long enough to think about it.

        What if something goes wrong? What if the fractions don’t integrate properly? What if I come back, but I’m missing something - some part of me, some thought process, some piece of my identity that I don’t even realize is gone?

        Would I even know?

        And worse - what if I come back, but there’s more of me than there should be?

        I joked with Evelyn about it earlier - about the idea of waking up as six different versions of myself, each one progressively more insufferable than the last. But the truth is, the possibility of fragmentation lasting is real. And even if we can reverse it, even if we can stitch me back together, who’s to say it’ll be me in the end?

        Shit. I shouldn’t be thinking like this.

        It’s the night before the big experiment, and I should be focusing on the science, on the excitement of it all. This is what I do, right? Pushing the boundaries. Taking the risks no one else will. That’s why they brought me here in the first place. That’s why Ev isn’t stopping me, even though I know she wants to.

        Because deep down, she knows this is worth it.

        Fuck, I love her.

        So, I push down the doubts. I crank up the music. I make bad jokes and promise her that everything is going to be fine. Because the alternative, the idea that I might walk into that chamber tomorrow and not come out the same, is too big to face head-on.

        But if I’m being completely honest with myself - just for this one moment, in this one private log that no one will read unless I’m not here to stop them - I’ll admit it.

        I’m scared too.

        Not enough to back out. Not enough to let Ev see it. But enough to sit here, staring at the ceiling, running through every worst-case scenario in my head, wondering what it’ll feel like when the process starts.

        Wondering if I’ll still be me when it’s over.

        Wondering if I'll still be me when it's happening.

        Whatever. It’s late. I need to sleep.

        Tomorrow, we make history.

        And I come back. In one piece. No matter what.

        End log.`

    }    

]

export default rbakerJournal

    //if you're looking here, you've come too far. this is just one piece of the puzzle

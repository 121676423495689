import Home from '../pages/Home';
import News from '../pages/News';
import FAQ from '../pages/FAQ';
import Contact from '../pages/Contact';
import Careers from '../pages/Careers';
import Investors from '../pages/Investors';
import About from '../pages/About'; 
import Legal from '../pages/Legal';
import UserLanding from '../pages/UserLanding';
import Journal from '../pages/Journal';
import userJournals from '../data/journals';
import fileicon from '../img/imgicon.webp';
import { decodeBase64Unicode } from './Utils';

const PageContent = (
  setActivePage,
  currentUserData = { experiments: [], email: [], files: [], cctv: [] },
  setModalContent,
  setIsModalOpen,
  emailSelected,
  handleEmailSelect,
  currentEmail,
  handleBackToList,
  fullName,
  username,
  role
) => ({
  home: { title: "Home", content: <Home /> },
  news: { title: "News", content: <News /> },
  faq: { title: "FAQ", content: <FAQ /> },
  contact: { title: "Contact", content: <Contact /> },
  careers: { title: "Careers", content: <Careers /> },
  investors: { title: "Investors", content: <Investors /> },
  about: { title: "About Us", content: <About /> },
  legal: { title: "Legal", content: <Legal /> },

  log: {
    title: "EXPERIMENT LOG",
    content: (
      <div className="experiment-log">
        <button onClick={() => setActivePage('user-landing')}>Back to Dashboard</button>
        <div className="table-wrapper">
          <table className="experiment-log-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Experiment Name</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentUserData?.experiments && currentUserData.experiments.length > 0 ? (
                currentUserData.experiments.map((experiment, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      setModalContent(decodeBase64Unicode(experiment.content || 'No content available'));
                      setIsModalOpen(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <td>{experiment.id}</td>
                    <td>{experiment.name}</td>
                    <td>{experiment.date || 'XX/XX/XXXX'}</td>
                    <td>{experiment.status || '----'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No experiments available or still loading.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  },

  files: {
    title: "FILES",
    content: (
      <div className="files-page">
        <button onClick={() => setActivePage('user-landing')}>Back to Dashboard</button>
        <h2>File Management</h2>
        <table className="files-table">
          <thead>
            <tr>
              <th>File Name</th>
              <th>Type</th>
              <th>Size</th>
              <th>Date Uploaded</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentUserData?.files && currentUserData.files.length > 0 ? (
              currentUserData.files.map((fileObj, index) => (
                <tr key={index}>
                  <td>
                    <img src={fileicon} alt={fileObj.type} className="file-icon" />
                    {fileObj.title}
                  </td>
                  <td>{fileObj.type}</td>
                  <td>{fileObj.size}</td>
                  <td>{fileObj.uploadDate}</td>
                  <td>
                    <a href="#" onClick={(e) => {
                      e.preventDefault();

                      if (fileObj.type === 'image') {
                        setModalContent(`<img src="${fileObj.file}" alt="${fileObj.title}" style="max-width:100%;" />`);
                      } else if (fileObj.type === 'video') {
                        setModalContent(`<img src="${fileObj.file}" alt="${fileObj.title}" style="max-width:100%;" />`); 
                      } else if (fileObj.type === 'pdf') {
                        setModalContent(`<iframe src="${fileObj.file}" style="width:100%; height:500px;" frameborder="0"></iframe>`);
                      } else {
                        setModalContent(`Unable to preview this file type.`);
                      }

                      setIsModalOpen(true);
                    }}>
                      Preview
                    </a> | 
                    <a href={fileObj.file} download>Download</a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No files available or still loading.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  },

  email: {
    title: "INTERNAL MESSAGING SYSTEM",
    content: (
      <div className="email-container">
        {emailSelected && (
          <div className="email-back-button" onClick={handleBackToList}>
            Back to Email List
          </div>
        )}
  
        <div className="email-list" style={{ display: emailSelected ? 'none' : 'block' }}>
          {currentUserData?.email && currentUserData.email.length > 0 ? (
            currentUserData.email.map((log, index) => (
              <div key={index} className="email-list-item" onClick={() => handleEmailSelect(log)}>
                <span className="email-subject">{log.name}</span>
                <span className="email-timestamp">{log.timestamp || 'XX:XX'}</span>
              </div>
            ))
          ) : (
            <div>No emails available or still loading.</div>
          )}
        </div>

        <div className={`email-preview ${emailSelected ? 'open' : ''}`}>
          <div className="email-preview-header">
            <div className="email-header-subject">{currentEmail?.name || 'Select an email'}</div>
            <div className="email-sender">From: {currentEmail?.sender || '�������'}</div>
            <div className="email-timestamp">{currentEmail?.timestamp || 'XX/XX/XXXX XX:XX'}</div>
          </div>
          <div className="email-preview-content" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: currentEmail ? decodeBase64Unicode(currentEmail.content) : '' }} />
        </div>
      </div>
    )
  },

  journal: {
    title: "Journal",
    content: (
      <div>
        <button onClick={() => setActivePage('user-landing')}>Back to Dashboard</button>
        <Journal username={username} userJournals={userJournals} />
      </div>
    ),
  },
  
  

  cctv: {
    title: "CCTV FOOTAGE",
    content: (
      <div className="cctv-page">
        <button onClick={() => setActivePage('user-landing')}>Back to Dashboard</button>
        <h2>CCTV Surveillance</h2>
        {role === 'admin' ? ( 
          <div className="cctv-grid">
            {currentUserData?.cctvImages && currentUserData.cctvImages.length > 0 ? (
              currentUserData.cctvImages.map((image, index) => (
                <img
                  key={index}
                  src={image.file}
                  alt={image.title}
                  onClick={() => {
                    setModalContent(
                      `<img src="${image.file}" alt="${image.title}" style="max-width:100%;" />`
                    );
                    setIsModalOpen(true);
                  }}
                />
              ))
            ) : (
              <p>No CCTV footage available or still loading.</p>
            )}
          </div>
        ) : (
          <p>You do not have permission to view CCTV footage.</p>
        )}
      </div>
    ),
  },

  'user-landing': { title: "User Dashboard", content: <UserLanding setActivePage={setActivePage} username={username} role={role} /> }
});

export default PageContent;

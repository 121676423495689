const users = {
    admin: {
      password: process.env.REACT_APP_USER6_PASSWORD,
      pages: ['log', 'files', 'cctv'],
      dataPath: 'admin',
      fullName: 'Admin',
      role: ["admin"]
    },
    nmanhav: {
      password: process.env.REACT_APP_USER1_PASSWORD,
      pages: ['log', 'email', 'files', 'journal'],
      dataPath: 'nmanhav',
      fullName: 'Dr. Manhav',
      role: ["researcher", "journal"]
    },
    estrauss: {
      password: process.env.REACT_APP_USER2_PASSWORD,
      pages: ['log', 'email', 'files', 'journal'],
      dataPath: 'estrauss',
      fullName: 'Dr. Strauss',
      role: ["researcher", "journal"]
    },
    rbaker: {
      password: process.env.REACT_APP_USER3_PASSWORD,
      pages: ['log', 'email', 'files', 'journal'],
      dataPath: 'rbaker',
      fullName: 'Dr. Baker',
      role: ["researcher", "journal"]
    },
    across: {
      password: process.env.REACT_APP_USER4_PASSWORD,
      pages: ['log', 'email', 'files', 'journal'],
      dataPath: 'across',
      fullName: 'Dr. Cross',
      role: ["researcher", "journal"]
    },
    ovale: {
      password: process.env.REACT_APP_USER5_PASSWORD,
      pages: ['log', 'email', 'files', 'journal'],
      dataPath: 'ovale',
      fullName: 'Dr. Vale',
      role: ["researcher", "journal"]
    }
  };
  
  export default users;
  
//if you're reading this, you just LOST THE GAME

import React, { useState } from 'react';
import './News.css';

function News() {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  const newsItems = [
    // {
    //   title: "",
    //   content: "",
    // },

    {
      title: "January 2025: Breakthrough in Quantum Entanglement Stabilization",
      content: "Demiurge Inc. is proud to announce a major breakthrough in stabilizing quantum entanglement for practical applications. Our research team successfully developed protocols that significantly reduce decoherence in entangled systems, paving the way for advancements in quantum computing and secure communication networks. This achievement marks a milestone in our journey to harness the potential of quantum technologies for real-world use."
    },
    {
      title: "December 2024: Advanced Reality Simulation Program Launches",
      content: "Demiurge Inc. has launched the Advanced Reality Simulation Program (ARSP), an innovative tool designed to simulate complex scenarios in controlled virtual environments. ARSP is set to revolutionize fields such as urban planning, climate research, and emergency response training. Early adopters have praised the system for its accuracy and adaptability, demonstrating its potential to model intricate real-world systems."
    },
    {
      title: "November 2024: Collaborative AI Research Agreement Signed",
      content: "In November, Demiurge Inc. signed a groundbreaking collaborative agreement with leading artificial intelligence institutions. This partnership aims to accelerate the development of AI frameworks capable of managing multi-layered reality systems. By combining expertise, we are advancing toward creating adaptive, intelligent systems that can operate seamlessly across dimensions."
    },
    {
      title: "September 2024: Demiurge Inc. Unveils Next-Generation Reality Warping Technology",
      content: "Demiurge Inc. is excited to announce the successful development of our latest reality-warping technology, set to revolutionize various industries, including entertainment, defense, and medicine. This breakthrough will allow users to manipulate and experience augmented reality with unprecedented precision and control. The new technology integrates seamlessly with existing systems, making it accessible for widespread adoption across different sectors. Early testing has shown remarkable results, with users reporting an unparalleled level of immersion and interaction."
    },
    {
      title: "August 2024: Demiurge Inc. Launches Cutting-Edge Virtual Reality Training Program",
      content: "Demiurge Inc. has officially launched its groundbreaking Virtual Reality Training Program designed for professionals in high-risk industries such as aerospace, healthcare, and military operations. This innovative program immerses trainees in realistic, high-pressure scenarios, enabling them to develop critical skills in a controlled environment. Our advanced VR simulations are powered by proprietary technology that adapts to the user’s responses, providing a personalized training experience. The program has received acclaim for its potential to revolutionize training methodologies across multiple sectors."
    },
    {
      title: "July 2024: Collaboration with Global Research Institutes",
      content: "In July, Demiurge Inc. entered into strategic partnerships with several leading global research institutions. These collaborations aim to advance our understanding of quantum mechanics and its applications in real-world scenarios. Our joint projects are expected to yield groundbreaking results that will push the boundaries of what is scientifically possible. The collaboration also paves the way for future projects that could redefine our approach to complex scientific challenges."
    },
    {
      title: "June 2024: Expansion of Facilities",
      content: "Demiurge Inc. has expanded its state-of-the-art facilities to accommodate the growing demand for our cutting-edge research. The new laboratories and testing grounds will allow for more comprehensive experimentation and faster development cycles, ensuring we remain at the forefront of technological innovation. This expansion also includes the addition of specialized equipment that will enable our teams to explore new areas of research. Our commitment to providing the best resources for our scientists continues to drive our success and innovation."
    },
    {
      title: "May 2024: Public Lecture Series on Multiverse Theories",
      content: "To foster greater understanding of our work, Demiurge Inc. launched a public lecture series focused on multiverse theories and their practical applications. These lectures are led by our top scientists and are open to the public, providing a rare glimpse into the complex and fascinating world of advanced theoretical physics. Attendance has exceeded expectations, with participants expressing keen interest in the implications of multiverse research on future technology. The series has sparked widespread discussion and interest in the scientific community and beyond."
    },
    {
      title: "April 2024: Launch of the 'Future Vision' Internship Program",
      content: "Demiurge Inc. is proud to introduce the 'Future Vision' Internship Program, offering young scientists and engineers the opportunity to work alongside our experts. This program is designed to cultivate the next generation of innovators, providing them with hands-on experience in cutting-edge research and development. Participants will have the chance to contribute to real-world projects and gain invaluable insights into the future of science and technology. The program has already attracted top talent from universities around the globe, reflecting our commitment to shaping the future of innovation."
    },
    {
      title: "March 2024: Demiurge Inc. Recognized for Ethical Innovation",
      content: "In March 2024, Demiurge Inc. received the prestigious Ethical Innovation Award from the International Science Council. This award highlights our commitment to advancing technology while adhering to the highest ethical standards. Our dedication to responsible research practices sets us apart as a leader in the industry. We continue to prioritize ethical considerations in all our projects, ensuring that our innovations benefit society as a whole."
    },
    {
      title: "February 2024: New Research Grants Secured",
      content: "Demiurge Inc. secured several new research grants totaling over $50 million in February 2024. These funds will support our ongoing projects in quantum computing, artificial intelligence, and next-gen material sciences. The grants will also enable us to explore new avenues of research that could have far-reaching implications for the future. We are committed to using these resources to drive innovation and achieve significant scientific breakthroughs."
    },
    {
      title: "January 2024: Demiurge Inc. Launches 'Vision 2030' Initiative",
      content: "At the start of the year, Demiurge Inc. launched the 'Vision 2030' initiative, a long-term strategic plan aimed at positioning the company as a global leader in reality manipulation technologies. This ambitious roadmap outlines our goals for the next decade, including key milestones in research, development, and commercialization. As part of this initiative, we will be expanding our global presence and increasing our investment in emerging technologies. Our Vision 2030 plan is designed to ensure that Demiurge Inc. remains at the forefront of innovation for years to come."
    },
    {
      title: "December 2023: Record Year for Patent Filings",
      content: "December 2023 marked a record year for Demiurge Inc. with the filing of over 120 patents. These patents cover a wide range of innovations, from advanced neural interfaces to groundbreaking AR applications. This achievement underscores our relentless pursuit of innovation and our commitment to developing cutting-edge technologies. The new patents will play a crucial role in securing our position as a leader in the tech industry and ensuring our continued growth and success."
    },
    {
      title: "November 2023: Strategic Acquisition of Quantum Dynamics Ltd.",
      content: "In November 2023, Demiurge Inc. completed the strategic acquisition of Quantum Dynamics Ltd., a company specializing in quantum entanglement technologies. This acquisition strengthens our position in the quantum technology sector and provides us with the expertise needed to accelerate our research and development efforts. The integration of Quantum Dynamics’ expertise into our operations is already yielding significant advancements in our quantum research projects. We are excited about the potential this acquisition brings to enhance our technological capabilities."
    },
    {
      title: "October 2023: Demiurge Inc. Hosts First Annual Science and Technology Symposium",
      content: "October 2023 saw the inaugural Science and Technology Symposium hosted by Demiurge Inc. The event brought together leading scientists, engineers, and innovators from around the world to discuss the latest advancements in reality manipulation, quantum computing, and artificial intelligence. The symposium was a resounding success and is set to become an annual event. Participants shared groundbreaking ideas and collaborated on potential future projects, setting the stage for the next wave of scientific innovation."
    },
    {
      title: "September 2023: Breakthrough in Temporal Anchoring Technology",
      content: "Demiurge Inc. has successfully demonstrated the first application of temporal anchoring, allowing localized time manipulation in a controlled environment. This breakthrough opens up possibilities in medical, industrial, and research fields by enabling precise temporal control. Early tests suggest immense potential for improving complex processes, such as surgical procedures and large-scale manufacturing."
    },
    {
      title: "August 2023: Partnership with Global AI Consortium",
      content: "In August, Demiurge Inc. partnered with the Global AI Consortium to explore next-generation AI algorithms capable of integrating seamlessly with our reality manipulation systems. This collaboration is set to produce innovations in adaptive systems and neural networks, pushing the boundaries of what artificial intelligence can achieve."
    },
    {
      title: "July 2023: Unveiling the Multiversal Probe Initiative",
      content: "Demiurge Inc. unveiled its Multiversal Probe Initiative, a bold project aimed at exploring alternate dimensions using proprietary interdimensional portal technology. Initial tests have returned promising data, sparking significant interest from the scientific community and opening doors to groundbreaking discoveries in parallel universes."
    },
    {
      title: "June 2023: Reality Modulation Device Trials Begin",
      content: "Trials for the new Reality Modulation Device (RMD) officially began this month, with initial experiments demonstrating the ability to alter localized physical laws within controlled environments. The RMD represents a significant leap forward in harnessing the power of reality manipulation for practical and experimental applications."
    },
    {
      title: "May 2023: Quantum Biology Division Launched",
      content: "Demiurge Inc. has launched a new Quantum Biology Division to explore the intersection of quantum mechanics and biological systems. The division is focused on applications in medicine, including quantum-assisted drug development and advanced diagnostic tools. This initiative is expected to yield revolutionary advancements in the healthcare industry."
    },
    {
      title: "April 2023: Interdimensional Portal Achieves Stability",
      content: "After years of development, Demiurge Inc. has achieved stable operation of its interdimensional portal system. This milestone allows researchers to safely explore and retrieve data from alternate realities. The breakthrough has profound implications for physics, philosophy, and our understanding of existence."
    },
    {
      title: "March 2023: Ethical Reality Manipulation Standards Published",
      content: "Demiurge Inc. published its first comprehensive ethical standards for reality manipulation technologies. These guidelines ensure that all innovations are developed and deployed responsibly, minimizing risks and prioritizing societal benefit. The document has been widely praised for addressing complex ethical challenges in emerging fields."
    },
    {
      title: "February 2023: Neural Interface Patent Approved",
      content: "Demiurge Inc. secured a patent for its Neural Reality Interface, a technology that allows direct brain interaction with augmented and virtual reality systems. The interface uses advanced neurofeedback algorithms to provide seamless integration between thought and action, revolutionizing how users interact with digital environments."
    },
    {
      title: "January 2023: Demiurge Inc. Announces Multiverse Research Program",
      content: "Kicking off the year, Demiurge Inc. launched its Multiverse Research Program to explore the theoretical and practical implications of parallel dimensions. The program's ambitious goals include understanding dimensional overlap phenomena and harnessing multiverse energy for sustainable applications. Early theoretical models have already garnered attention from top physicists worldwide."
    }
    
  ];

  //if you're reading this, you just LOST THE GAME AGAIN

  return (
    <div className="container">
      {newsItems.map((item, index) => (
        <div
          key={index}
          className={`news-item ${openItem === index ? 'open' : ''}`}
        >
          <h3 className="news-title" onClick={() => toggleItem(index)}>
            {item.title}
          </h3>
          {openItem === index && <p>{item.content}</p>}
        </div>
      ))}
    </div>
  );
}

export default News;

import React, { useState, useEffect } from 'react';
import './Journal.css';

const Journal = ({ username, userJournals }) => {
  const [entries, setEntries] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [newEntry, setNewEntry] = useState({ title: '', content: '' });

  useEffect(() => {
    try {
      if (userJournals[username]) {
        console.log('Loaded from userJournals:', userJournals[username]);
        const predefinedEntries = userJournals[username].map(entry => ({
          ...entry,
          userAdded: false, 
        }));
        setEntries(predefinedEntries);
        localStorage.setItem(`journal_${username}`, JSON.stringify(predefinedEntries));
      } else {
        const storedEntries = JSON.parse(localStorage.getItem(`journal_${username}`));
        if (storedEntries && Array.isArray(storedEntries)) {
          console.log('Loaded from localStorage:', storedEntries);
          setEntries(storedEntries);
        } else {
          console.log('No entries found for user:', username);
          setEntries([]);
        }
      }
    } catch (error) {
      console.error('Error loading journal entries:', error);
      setEntries([]);
    }
  }, [username, userJournals]);

  useEffect(() => {
    localStorage.setItem(`journal_${username}`, JSON.stringify(entries));
  }, [entries, username]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleNewEntryChange = (e) => {
    const { name, value } = e.target;
    setNewEntry((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveEntry = () => {
  if (newEntry.title.trim() && newEntry.content.trim()) {
    const newEntryWithRedaction = {
      id: entries.length + 1,
      date: 'DATE: ███████ TIME: ████████',
      title: newEntry.title,
      content: newEntry.content,
      userAdded: true,
    };
    setEntries((prev) => [...prev, newEntryWithRedaction]);
    setNewEntry({ title: '', content: '' });
  }
};


  const handleDeleteEntry = (id) => {
    setEntries((prev) => prev.filter((entry) => entry.id !== id || !entry.userAdded));
  };

  const filteredEntries = [...entries]
  .filter(
    (entry) =>
      entry.title.toLowerCase().includes(searchTerm) ||
      entry.content.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="journal-container">
      <h1>{username}'s Journal</h1>

      <div className="search-bar">
        <input
          type="text"
          placeholder="Search entries..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      <h2>Previous Entries</h2>
      <div className="previous-entries">
        <ul>
  {filteredEntries.length > 0 ? (
    filteredEntries.map((entry) => (
      <li key={entry.id}>
        <h3>{entry.title}</h3>
        <div className="entry-content">
          {entry.content.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
        <p className="redacted-date">{entry.date}</p>
        {entry.userAdded && (
          <button
            className="delete-entry-button"
            onClick={() => handleDeleteEntry(entry.id)}
          >
            Delete
          </button>
        )}
      </li>
    ))
  ) : (
    <p className="no-entries-message">
      {searchTerm ? 'No matching entries found.' : 'No entries available.'}
    </p>
  )}
</ul>
      </div>

      <h2>Add A New Entry</h2>
      <div className="add-entry">
        <input
          type="text"
          name="title"
          placeholder="Title"
          value={newEntry.title}
          onChange={handleNewEntryChange}
        />
        <textarea
          name="content"
          placeholder="Content"
          value={newEntry.content}
          onChange={handleNewEntryChange}
        />
        <button
          onClick={handleSaveEntry}
          disabled={!newEntry.title.trim() || !newEntry.content.trim()}
        >
          Save Entry
        </button>
      </div>
    </div>
  );
};

export default Journal;

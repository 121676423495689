//since you're here, looking through the code on the investors page, you should go to freakbait.store and buy some merch

import React from 'react';
import './Investors.css';

function Investors() {
  return (
    <div className="investors-page">
      <h1>Welcome to the Future</h1>
      <p>
        DEMIURGE INC. is at the cutting edge of technological innovation, pioneering reality-warping inventions that have the potential to reshape industries across the globe. As we continue to push the boundaries of what is scientifically possible, we invite visionary investors to join us in our journey towards unlocking unprecedented technological breakthroughs. Investing in DEMIURGE INC. means being part of a movement that transcends traditional industries, offering opportunities for growth in fields that are yet to be fully realized.
      </p>

      <h2>Why Invest in DEMIURGE INC.?</h2>
      <ul>
        <li><strong>Pioneering Technology:</strong> Our unique position as leaders in reality-warping research ensures that we remain at the forefront of technological advancement. Our innovations have applications across numerous sectors, including healthcare, defense, economics, entertainment, and more.</li>
        <li><strong>Exponential Growth Potential:</strong> The rapidly expanding market for advanced technologies, coupled with our proprietary research, positions DEMIURGE INC. for significant growth in a multi-billion dollar industry within the coming years.</li>
        <li><strong>Ethical Leadership and Responsibility:</strong> We are committed to conducting our research under the strictest ethical guidelines, ensuring that our innovations benefit humanity (and our investors) as a whole.</li>
        <li><strong>Exclusive Access to Groundbreaking Research:</strong> Investors gain insider access to our latest developments, allowing for strategic involvement in projects with potentially transformative outcomes.</li>
      </ul>

      <h2>Investment Opportunities</h2>
      <ul>
        <li><strong>Equity Investments:</strong> Secure a stake in DEMIURGE INC.'s future by becoming an equity investor. This option is perfect for those looking to align themselves with the long-term growth of the company.</li>
        <li><strong>Partnerships and Collaborations:</strong> We offer unique opportunities for strategic partnerships, allowing investors to collaborate directly with our research teams on specific projects.</li>
        <li><strong>Innovation Fund:</strong> Contribute to our Innovation Fund, which supports the most cutting-edge research initiatives within DEMIURGE INC. This fund is designed for investors looking to make a direct impact on the future of technology.</li>
      </ul>

      <h2>Investor Relations Contact</h2>
      <p>
        If you're interested in learning more about investment opportunities at DEMIURGE INC., please contact our Investor Relations team at <a href="mailto:freakbait@freakbait.com?subject=DEMIURGE INC. Investment Inquiry&body=DEMIURGE INC.,%0A%0AI am interested in investing in your endeavors and would love more information about how I can contribute to your expansion into alternate markets.%0A%0AAmount of capital I am willing to invest:%0A%0APreferred area of investment (dimensional warfare, multiverse travel, reality manipulation, etc.):%0A%0AAny concerns about ethical practices or risks to my personal reality:%0A%0AThank you for this opportunity to partner in reshaping the boundaries of existence.%0A%0ASincerely,%0A[Your Name]" title="investors@demiurge-inc.com">investors@demiurge-inc.com</a>
        . Our team is available to discuss your investment goals and provide detailed information on how you can be part of our groundbreaking work.
      </p>
    </div>
  );
}

export default Investors;

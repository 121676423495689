const estraussJournal =[

    {
        id: 1,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Appointment as Chief Entropy Researcher',
        content: `My appointment to lead the Entropy Research Division was finalized today. The decision was preceded by months of evaluations and tests, culminating in a comprehensive review of my previous work at CERN and the Max Planck Institute. I am told this journal is a mandatory confidential excercise in lieu of prescribed therapy from Demiurge psychotherapists. I'll abide.

        The scale of this division’s ambition is evident. Resources are abundant, but logistical inefficiencies persist. Demiurge Inc. operates at the forefront of sciene, pursuing avenues that no other institution I have worked at would even consider. The opportunities here are singular and far-reaching.
    
        Project ECHO is the immediate priority. The feedback loops created by entropic disruptions require precise modeling to understand their long-term effects on parallel realities. Preliminary data indicates significant deviations from expected stability parameters, suggesting that entropy functions within multi-dimensional frameworks are less predictable than originally hypothesized.
    
        Work has also begun on Chaos Theory reformation. Current models fail to account for the interaction of entropic variables across dimensional membranes. The team has been tasked with redefining the principles to produce predictive algorithms capable of modeling reality fluctuations in unstable systems. The preliminary results are promising but require further testing.
    
        The facility's resources are extensive, though the operational environment is chaotic. Efficiency will need to be improved to meet the demands of the research. The lab's willingness to explore the uncharted is a strength, but it must be balanced with precision and control. Progress reports will be issued as benchmarks are met.`
    },

    {
        id: 2,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Preliminary Assessments of Entropic Energy Applications',
        content: `My short time at Demiurge Inc. has provided deeper insight into the scope of its operations. Beyond its public-facing research, there is a layer of activity that is decidedly more classified and less conventional. Much of this involves applications of entropic energy in domains that are rarely, if ever, discussed openly - military research being one of them.
    
        The current initiative focuses on the weaponization of entropic feedback mechanisms. The theoretical framework involves harnessing entropic energy as a destabilizing force, targeting the coherence of molecular and even dimensional structures. Preliminary testing suggests a devastating potential, though the results are far from stable or predictable. 
    
        Ethical considerations have arisen, though they are not part of the official discourse. The experimental parameters push boundaries, both scientifically and morally. However, the utility of such developments is clear: entropic destabilization could neutralize targets without traditional force, a prospect likely to garner considerable interest from military stakeholders. Imagine something like a targeted nuclear strike, completely contained within the confines of the human body.
    
        My role remains confined to the theoretical. For now. I am tasked with refining the energy distribution models to ensure controlled and directed applications. While this provides a layer of professional detachment, it does not mitigate the broader implications. Such thoughts are irrelevant to the task at hand, but they persist nonetheless.
    
        Demiurge’s ability to operate at the edge of feasibility - and occasionally beyond it - is unmatched. Whether this is admirable or reckless remains to be seen.`
    },

    {
        id: 3,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Integration with DARPA',
        content: `Demiurge’s collaboration with DARPA has shifted my focus from purely theoretical work to more applied research. Finally. The projects under this partnership are ambitious to the point of absurdity, straddling the line between advanced physics and speculative fiction. The most notable involves the controlled deployment of entropic destabilization fields, designed to selectively collapse structural integrity at a molecular level. Practical applications are obvious, though the mechanisms for field stability remain elusive.
    
        This endeavor has introduced me to Dr. Raymond Baker. He is renowned in the field for his unconventional methods and a reputation that precedes him - equal parts genius and jackass. He exhibits a bravado entirely unmatched in this environment, speaking to military representatives in a manner more suited to an irreverent comedian than a scientist. They tolerate it, though. It seems his brilliance grants him immunity.
    
        Dr. Baker’s contributions are as unorthodox as his demeanor. His work on entropic resonance amplification has accelerated progress significantly, even if his processes are anything but conventional. During one session, he dismissed weeks of painstaking calculations in favor of what he called a “gut hypothesis.” It worked. Somehow. The military observers appeared impressed and unsettled.
    
        I remain uncertain how to classify him - a disruptive force, certainly, but also a deeply insightful one. His confidence seems to create a bubble around him, a protective barrier against the skepticism of those in suits. It is a dynamic I have not encountered before and one I find compelling, though perhaps not entirely rational.
    
        As the DARPA projects continue, the ethical and practical implications grow more pronounced. The entropic weapons under development are, in theory, capable of catastrophic outcomes. These are not tools for containment or precision; they are instruments of irreversible chaos. This knowledge adds weight to every calculation, every parameter. Mistakes here would not simply set us back—they could redefine the boundaries of destruction itself.
    
        I have requested access to Dr. Baker’s notes to better understand his methodologies. His presence complicates an already complex environment.`
    },

    {
        id: 4,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Synthesis of Dimensional and Temporal Frameworks',
        content: `The past month has been dedicated to integrating results from a series of critical experiments under the broader framework of the Dimensional Entropy Stabilization Initiative (DESI). The objective remains the same: establish control over multi-dimensional systems while mitigating the inherent entropy gradients that destabilize them. Progress has been marked, though non-linear.
    
        Experiment 6204.a: Temporal Parallax Nullification, laid the groundwork for managing temporal cohesion across parallel timelines. Despite the partial success, the observed resistance of temporal structures to full synchronization has highlighted the limitations of current waveform interference protocols. The unexpected phenomenon of reverse chronology experienced by one subject underscores the need for adaptive feedback algorithms to address temporal instability in real time.
    
        Experiment 54.c (protocol-1): Inter-Spatial Tethering, represented a critical advance in spatial anomaly containment. The application of a multi-resonance harmonic lattice demonstrated that spatial drift can be temporarily arrested, though the energy expenditure required to maintain stability exceeded predictions by a factor of 3.2. Notably, the anomaly’s attempt to bifurcate into multiple entities suggests a dynamic, self-correcting behavior that complicates long-term containment strategies.
    
        Experiment 3911.b: Causal Reality Synthesis, ventured into uncharted territory, successfully synthesizing a stable alternate reality via entropy gradient manipulation. However, the unintended branching and fracturing of the primary timeline revealed an inherent fragility in causal frameworks. The emergence of “ghost” subjects in peripheral timelines warrants further study, particularly with respect to the potential for cross-timeline interference.
    
        Experiment 88.7-alpha: Neuromorphic Enhancement, provided invaluable insights into quantum neural interfacing. The neuromorphic interface demonstrated its capacity to enhance cognitive functions by 200%, though the unintended precognitive effects observed in one subject raise ethical and procedural questions. The underlying entanglement mechanisms appear to alter neural architecture in unpredictable ways, necessitating recalibration to mitigate long-term strain.
    
        Experiment 1976.x: Omni-Resonance Attunement, culminated in a landmark achievement—stable attunement across dimensional frequencies, enabling multi-dimensional traversal. However, the severe dimensional bleed-through and resultant perceptual disorientation experienced by subjects indicate that the process is not yet viable for sustained or practical use. Current omni-spectrum modulation techniques lack the precision necessary to safely navigate such environments.
    
        Collectively, these experiments outline a trajectory toward a unified model of dimensional and temporal coherence. The challenge lies in synthesizing these discrete methodologies into an operational system that balances stability with scalability. A proposal for the integration of temporal nullification protocols with omni-resonance attunement is under development, contingent upon securing additional energy resources and refining neuromorphic enhancement systems to offset the cognitive burden on human operators.
    
        The broader implications of DESI extend beyond theoretical or experimental validation. Each success represents a step closer to establishing dominion over entropy—a prospect both tantalizing and perilous. Whether this pursuit will lead to the stabilization of multi-dimensional grids or to an uncontrollable cascade of entropy remains undetermined. Further data is required.`
    },

    {
        id: 5,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Observations on Dr. Baker',
        content: `Dr. Raymond Baker’s behavior during today’s meeting with Jeff was, predictably, unorthodox. While the rest of us adhered to the unspoken rules of formality, he casually referred to Jeff as “bossman” and "el jefe," dismissed a high-level discussion on resource allocation as “bean counting,” and proceeded to sketch a crude entropic diagram on the whiteboard that he insisted was “close enough for government work.” Jeff didn’t so much as blink. 
    
        It is difficult to reconcile his irreverence with the undeniable brilliance of his contributions. His theoretical models, while unpolished in presentation, consistently prove to be leaps ahead of standard methodologies. He possesses a confidence that borders on arrogance, yet his results seem to justify it. 
    
        I find myself vacillating between dismissing him as a class clown and considering the possibility that he is, in fact, one of the most brilliant minds of our time. Perhaps the two are not mutually exclusive. His ability to challenge authority while maintaining the respect of even the most rigid figures in the organization is something I have yet to understand. 
    
        For now, I will continue to observe. Baker is a disruptive element, but one I suspect this environment - and perhaps I - may benefit from.`
    },

    {
        id: 6,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Initial Findings on Reality Displacement Mapping',
        content: `Experiment 1023.zeta has marked a pivotal step in the study of dimensional shifts and reality layer interactions. The objective was to map the displacement of reality layers during a controlled dimensional transition. While the imediate focus was data collection, the implications extend toward far more ambitious objectives.
    
        Utilizing a hyper-lattice containment field, the subject was subjected to phased dimensional pulses while mapping devices tracked displacement vectors. Initial results revealed minor shifts, though further pulses induced significant layering effects. Manifestations of overlapping realities became visible at the edges of the containment field, accompanied by corresponding auditory and visual distortions reported by the subject.
    
        The data confirms that reality layers behave predictably under controlled shifts, though the complexity of their intersections presents challenges for stability. Notably, the subject exhibited signs of cognitive overload as displacement increased, necessitating immediate extraction. Neural strain appears to correlate directly with the intensity of the layer overlap.
    
        The success of this experiment lies in the precise displacement mapping achieved, providing a framework for future transitions. However, the risks are substantial. Cognitive and physical strain on subjects exceeds safe thresholds under current protocols. Improvements in containment and energy distribution are necessary to mitigate these risks.
    
        The broader implications of this work are compelling. If reality layers can be mapped and navigated, it introduces the possibility of transitioning matter—organic or otherwise—into a non-physical state. The theoretical framework for reducing a subject to a state of pure energy and returning them unharmed is now within reach. The potential applications for this technology are extraordinary, but the ethical and practical challenges are considerable.
    
        I have requested additional resources to refine the hyper-lattice system and ensure safer testing conditions. This is uncharted territory, and the margin for error is nonexistent. The path forward demands caution, though the rewards may redefine our understanding of existence itself.`
    },

    {
        id: 7,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Collaboration on Temporal Reintegration Loop',
        content: `Experiment 4529.alpha is underway, marking a collaborative effort to address the reintegration of fragmented timelines into a cohesive, continuous loop. The objective is straightforward in principle: stabilize disrupted temporal flow and realign divergent timelines into a unified system. In practice, the process is anything but steraighforward.
    
        Dr. Amelia Cross has provided invaluable insights into quantum resonance, particularly in relation to temporal field alignment. Her work on dimensional tuning has informed the parameters for resonance modulation, which has already improved the stability of the temporal resonance fields. Her precision and methodical approach are evident in every aspect of her contribution. Cross’s understanding of quantum frequencies surpasses theoretical knowledge; it is an intuitive mastery I find both fascinating and enviable.
    
        Dr. Orion Vale has approached the project with characteristic efficiency. His focus lies in predictive modeling, calculating potential failure points within the loop before they manifest. His strategic input has been critical in anticipating disruptions and adjusting parameters preemptively. Vale’s demeanor remains formal and unyielding, though his results are difficult to argue with. His ability to foresee and mitigate risks reflects a sharp, almost mechanical intellect. One of the few things upon which I can agree with him.
    
        Initial alignment of the temporal resonance fields has shown promising results. The fragmented timelines are beginning to exhibit cohesion, though minor disruptions persist. Subjects within the loop have reported experiencing discontinuities - visual distortions, sensory anomalies, and an unnerving sense of repetition. These observations align with anticipated challenges of stabilizing temporal flow, but they highlight the precarious nature of the experiment.
    
        Maintaining the loop requires constant monitoring and adjustment to prevent breakdown. Even small deviations in resonance can lead to cascading failures, unraveling the alignment and destabilizing the entire system. For now, the loop holds, but the energy requirements for continued stability are unsustainable in the long term.
    
        The potential applications of temporal reintegration are profound. The ability to create isolated, stable temporal loops could provide a framework for containing entropic energy, though this concept remains theoretical. For now, the focus is on refining the resonance fields and achieving complete stability within the loop. 
    
        Collaboration with Cross and Vale has proven productive, though their methodologies and personalities are starkly different. Cross’s precision complements Vale’s strategic foresight, and both have challenged me to reevaluate my approach to temporal dynamics. These differences, while sometimes frustrating, have driven progress in a way I would not have achieved alone.`
    },
    
    {
        id: 8,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Beckenstein Breach Incident',
        content: `Today’s events were nothing short of surreal, even by Demiurge standards. What began as routine maintenance on the event horizon information layer quickly escalated into what Dr. Vale has dubbed the “Beckenstein Breach.” In simpler terms, we reached the universe’s information capacity, and reality responded by spilling over. 
    
        The lab devolved into a Salvador Dalí painting brought to life. Walls bled numbers. Objects ceased to exist as themselves and became something else entirely. At one point, a stapler began chanting in Latin. Baker found this hilarious, of course, and dubbed it the “Gregorian Stapler.” He was entirely too amused, recording it on his phone while providing commentary about the impending stapler uprising. 
    
        In any other scenario, I would have been frustrated - at the situation and at Baker’s irreverence. But something about his attitude shifted my perspective. While Vale panicked and I focused on recalibrating the kugelblitz array, Baker took charge of the atmosphere. His ridiculous commentary diffused the tension, even managing to make Vale stop obsessing over the melting walls. For the first time, I found myself grateful for his antics. For once.
    
        When the numbers began organizing themselves into what appeared to be the equation for universal collapse, Baker calmly proposed rerouting the overflow into the Temporal Reintegration Loop. It wasn’t just humor that defined him today; it was an uncanny ability to see solutions where no one else could. We implemented his suggestion, and though it created duplicate timelines in the loop, it stopped the bleeding walls and stabilized the singularities.
    
        Watching him confidently handle the situation, I realized my initial assessment of him might have been overly harsh. Yes, he’s a jackass. But he’s also one of the sharpest minds I’ve encountered. His irreverence may be a shield, or perhaps it’s just who he is, but beneath it lies a brilliance that’s impossible to ignore.
    
        That said, I’ll be sending Jenkins to the break room tomorrow. The stapler has apparently converted other office supplies to its cause. Baker’s glee at this development is almost as concerning as the uprising itself. 
    
        For now, the lab is stable, and I have a bottle of something strong in my office. I may need it if Baker starts encouraging the staplers.`
    },

    {
        id: 9,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Integrating Theories',
        content: `The project has no name - just a coded designation, buried deep in the black files. I suppose that makes sense, given its implications. Officially, we’re tasked with stabilizing voidloops to contain entropic energy. Unofficially, we’re stitching together the fabric of reality in ways that no one - not even us - fully understands yet.
    
        Working with Vale and Cross has been challenging. Vale continues to treat every meeting like a military briefing, delivering predictive models as though they were sermons. To his credit, his calculations have been invaluable, mapping out the failure points before they manifest. His work on dimensional coherence has been the backbone of the project, even if his delivery comes with a side of icy detachment.
    
        Cross, on the other hand, is surgical. Her reality tuning techniques have brought a level of precision to the process that my entropic field stabilization never could. Watching her recalibrate the resonance lattices today was like watching a conductor refine an orchestra - only this orchestra happens to include the fundamental frequencies of the universe.
    
        And then there’s Nicholas Manhav. We were introduced this morning, and while I’d heard of his work on the fungal extremophile and viral activation experiments, I wasn’t prepared for his energy. He speaks in bursts of excitement, as if the sheer speed of his ideas might outrun anyone who dares try to follow. Manhav’s contributions focus on integrating quantum feedback systems into the loops, ensuring the energy stays contained without fracturing the dimensional membranes. He’s brilliant, but it’s like watching a lightning storm - impressive, awe-inspiring, and a little dangerous.
    
        Of course, Baker couldn’t resist turning the entire thing into a spectacle. While Vale outlined potential loop collapse scenarios, Baker leaned back in his chair, muttered something about “real-life Groundhog Day,” and asked if we’d considered adding a disco ball to the containment field for ambience. Cross rolled her eyes, Manhav laughed so hard he spilled coffee on the model, and I... I didn’t stop him. That might be the most alarming thing about all of this - I’m starting to let Baker’s chaos run unchecked.
    
        The project itself is staggering in scope. Integrating Vale’s predictive coherence models with Cross’s reality tuning and my entropic stabilization research feels like trying to balance a house of cards in a windstorm. The voidloops, while conceptually stable, resist practical application. The energy they’re designed to contain is inherently unstable, as entropy tends to be, so I'm not sure what they're trying to get at stabilizing instability. Every step forward introduces new variables, most of which would be laughable if they weren’t so catastrophic.
    
        And yet, there’s something exhilarating about it. The team - this strange collection of personalities and expertise - has created something completely unique. Vale provides structure, Cross delivers precision, Manhav adds the spark of innovation, and Baker... well, Baker somehow makes it all less terrifying. Or maybe more terrifying. I’m not entirely sure anymore.
    
        Tomorrow, we’ll run the first fully integrated test of the voidloop containment system. If it works, we’ll be one step closer to controlling entropic energy. If it doesn’t, we’ll likely be picking pieces of this lab, and possibly ourselves, out of adjacent dimensions. 
    
        I’m going to need more coffee. Or maybe something stronger.`
    },

    {
        id: 10,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Fire Beneath',
        content: `I’ve spent most of my life cultivating precision, control, and focus. It’s a necessity in my work - entropy is chaos incarnate, and the only way to study it without being consumed by it is to impose order wherever possible. But I wasn’t always this way. 
    
        My father was a theoretical physicist who moonlighted as a storm chaser. He claimed it was for research, studying the unpredictable pathways of tornadoes and the atmospheric entropy they created. I think it was more than that. He didn’t just observe chaos. He chased it.  Reveled in it. As a child, I would sit in the back of his battered Jeep, clutching a notepad and scribbling down whatever equations he shouted over the roar of the wind. The storms terrified me, but they also exhilarated me. 
    
        Those moments, sitting under dark, churning skies, were the first time I truly understood the beauty of chaos. My father called it “the poetry of unpredictability.” I never forgot it. Even now, buried in calculations and containment protocols, that same fire burns somewhere deep inside me, though I’ve spent years covering it with layers of control and stone-faced seriousness. 
    
        Ray reminds me of that fire. He embodies it, wears it openly in a way I never could. Today, during the second voidloop integration test, he waltzed into the lab holding two cups of coffee, both labeled “entropy fuel,” and handed one to me with a grin.  When I pointed out that labeling my coffee with a potential experiment hazard wasn’t reassuring, he laughed and said, “Chaos is the best teacher.”
    
        I wanted to be annoyed. Instead, I laughed. For a moment, it felt like being in my father’s Jeep again, staring at the angry sky and waiting for the storm to hit. Baker’s presence has a way of cutting through the tension, disarming my carefully built defenses. It’s frustrating, but also... intoxicating.
    
        I stayed late tonight to review the test results. He stayed too, ostensibly to check on some peripheral equipment, though I’m fairly certain it was just an excuse. At one point, I caught him staring at the containment field, his expression uncharacteristically serious. 
    
        I asked him why he does this, why he works in a field so dangerous, so unpredictable. He looked at me, and for the first time, I saw something beneath the jokes and bravado. “Because someone has to,” he said. “And because it’s the only way to see what’s on the other side.”
    
        There’s a sincerity in him I hadn’t fully appreciated before. Beneath the chaos, there’s a purpose, a passion that mirrors my own. I’ve spent my life trying to control the storm inside me. Baker lets his burn freely, and I can’t help but be drawn to it. It’s like finding a piece of myself I thought I’d buried a long time ago.
    
        I don’t know where this path leads - either the project or this... whatever this is with Baker. But for the first time in a long time, I don’t feel the need to have all the answers.`
    },

    {
        id: 11,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Gaps in Understanding',
        content: `Progress on Experiment 8665.gamma has been encouraging. The transmission of data across dimensional layers continues to improve, though occasional anomalies, such as minor temperature drops, suggest quantum interference that remains unresolved. The project feels stable enough, for now.
      
        Meanwhile, the voidloop project moves forward, though increasingly without my direct involvement. Manhav, Vale, and Cross appear absorbed in their work, discussing calculations and models in hushed tones. When I asked Vale about the current phase, his response was vague, offering little beyond reassurance that “progress is being made.” Cross was similarly evasive, redirecting the conversation to unrelated tests. Even Manhav, typically excitable, seems unusually restrained when the topic arises.
    
        The lack of transparency is unsettling. My research on entropic energy forms the backbone of their work, yet I feel increasingly removed from the project’s trajectory. What are they hiding? Why am I being kept at a distance from something I should be central to?
    
        These questions linger as we move forward. While I focus on refining entanglement stabilizers for *Experiment 8665.gamma*, I can’t help but consider the broader implications of the voidloop project. It’s not just a containment system—it’s something more, though what exactly, I cannot say. 
    
        For now, I’ll stay observant. If there’s more to their work than they’re letting on, I intend to find out.`
    },

    {
        id: 12,
        date: 'DATE: ███████ TIME: ████████',
        title: 'What Comes Next',
        content: `I sent Ray an email today. Not a research update, not a question about containment fields or resonance protocols - just a message about us. It feels strange, putting something so personal into writing, but I’ve been holding this conversation in my head for weeks. It needed to come out.
    
        The lab has always been chaotic, but lately, it feels like there’s a storm inside me that’s harder to ignore. Ray’s signs, like the absurd “Poop Quality Survey” that had HR in an uproar, are emblematic of who he is: irreverent, unpredictable, and unapologetically himself. And while part of me knows I should find him infuriating, I don’t. Instead, I find myself laughing in moments where I should probably be sighing in exasperation. It’s disarming.
    
        We’ve been good at keeping things quiet, or at least I thought we were. But the truth is, people probably suspect. We’re not exactly subtle, and I’ve noticed the glances when we’re in the same room or when he cracks a joke that only I seem to laugh at. The connection between us feels inevitable, yet undefined. That’s what I want to talk about. Defining it. Figuring out what comes next, beyond the lab and the voidloops and the endless parade of anomalies.
    
        I suggested coffee tomorrow, though I know he’ll probably insist on something ridiculous, like eating lunch in one of the containment chambers “for ambiance.” But that’s him. That’s Ray. And for reasons I can’t entirely explain, that’s what draws me to him.
    
        I keep telling myself there are boundaries, lines that can’t be crossed in an environment like this. Yet here we are, toeing them. Maybe breaking them. For someone who’s spent her career focused on control and order, this feels like stepping into uncharted territory. And I’m not sure I hate it.
    
        Tomorrow, we’ll talk. About us. About what comes next. Whatever that looks like.`
    },

    {
        id: 13,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Instability in the Frequencies',
        content: `Targeted Reality Frequency Modulation continues to yield fascinating results. Dr. Cross’s ability to modulate specific frequency bands has created subtle shifts in the environment. The phase-shifting behavior she reported is particularly intriguing: objects existing in two states simultaneously, temporarily reflecting characteristics of adjacent realities before reverting to their original forms. It’s an impressive demonstration of precision, though I wonder how far we can push these methods before the subtle becomes catastrophic.
    
        Cross’s concern is valid. Increased modulation risks overlapping realities in ways we don’t yet understand. The stability of our test area already feels tenuous, and further disruptions could destabilize the surrounding space. Her caution is warranted, but I suspect her curiosity may eventually outweigh it.
    
        That curiosity seems to extend far beyond this experiment. The voidloop project continues in tandem, and Cross’s involvement with Vale and Manhav grows increasingly opaque. Today, I overheard fragments of their conversation, something about “reinforcement measures” and “dimensional tuning protocols.” When I approached, the subject was quickly changed to unrelated technical details. It’s becoming clear they’re withholding something, though I’m unsure whether it’s out of necessity or mistrust.
    
        My research on entropic stabilization forms the foundation of much of this work, yet I’m being kept at arm’s length. Every time I press for clarity, the answers feel rehearsed, calculated to satisfy curiosity without providing real insight. Even Cross, typically forthright, has become increasingly reserved.
    
        It’s difficult not to speculate. The phase-shifting behavior observed in 6893.beta shares parallels with voidloop dynamics, instances where reality splits or collapses into cyclical containment fields. Are they using the modulation research as a cover for something larger? Something more dangerous?
    
        Ray, naturally, finds my paranoia amusing. When I mentioned my suspicions, he suggested planting a recording device in the containment lab. “I’ll even design it to look like a tiny coffee cup,” he said with a big shit-eating grinning. It’s hard to tell whether he’s joking or if he genuinely believes espionage is the solution.
    
        For the time being, I’m documenting everything. If there’s more to their work than they’re letting on, the evidence will surface eventually. But I can’t shake the feeling that time is running out - that whatever they’re building, whatever they’re hiding, whatever they've discovered, is nearing a point of no return. And if that’s true, I need to be ready.`
    },

    {
        id: 14,
        date: 'DATE: ███████ TIME: ████████',
        title: 'What Just Happened?',
        content: `These logs are encrypted. They’re mine, untouchable and private. That’s the only reason I feel safe writing this - because what just happened is something I can’t tell anyone else. 
    
        Ray and I... I don’t even know how to describe it. It wasn’t planned - not that anything with Ray ever is. One moment we were in the Reality Modulation Chamber, talking about resonance stabilizers and frequency thresholds. The next, everything blurred together: his grin, his confidence, that spark of chaos he carries like a second skin. And then, us. Together. 
    
        It was exhilarating. Dangerous. Completely irresponsible. And yet, in that moment, none of it mattered. The gravitational spikes, the sensors that should have gone off, the sheer audacity of doing this in a space designed to bend reality itself - it all faded. For once, I wasn’t thinking about the risks. I wasn’t thinking at all.
    
        Now that it’s over, the weight of it is settling in. What if someone saw? What if the chamber recorded something we missed? I can already hear Dr. Manhav asking why the readings spiked for fifteen minutes, and I have no good answer for him. The tech is designed to manipulate space-time, for god’s sake. What if we’d broken something we couldn’t fix?
    
        And yet, even as I write this, there’s a part of me that doesn’t regret it. It’s like chasing a storm again - chaotic, unpredictable, but alive in a way I haven’t felt in years.
    
        I told him we need to slow down, be more careful. He laughed, of course, said something about how “careful” is just another word for “boring.” And maybe he’s right. Maybe I’ve spent too long trying to control the uncontrollable. But I also know that if this gets out, it could ruin us—not just personally, but professionally. 
    
        For now, I’ll focus on making sure there’s no record of what happened. He promised to wipe the logs, and I trust him, mostly. But if he doesn’t, I swear I’ll make sure he’s stuck in a temporal loop filled with every HR training video on file.
    
        I don’t know where this is going, but I do know one thing - whatever this thing is between us, it’s changing me. And for the first time in a long time, I’m not entirely sure that’s a bad thing.`
    },

    {
        id: 15,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Into the Loops',
        content: `I promised myself I wouldn’t cross this line. And yet here I am, running my own experiments in the dead of night, concealed from the very team I’m supposed to trust. The voidloops have been on my mind constantly, their complexity and potential too vast to ignore. Vale, Cross, and Manhav treat me like a peripheral player in this project, but it’s my research, my work on entropic stabilization, my experimentation, and my data that made the loops possible in the first place. If they won’t tell me what they’re really doing, I’ll find out myself.
    
        The loops are alive, in a sense. Not in the biological way, but in their unpredictability. Each cycle feels slightly different, as though the energy they’re designed to contain is learning, adapting. The experiments I’ve conducted so far are small - measured adjustments to the resonance fields, subtle shifts in entropy gradients. But the results are staggering. The loops don’t just contain entropic energy; they thrive on it. Every alteration seems to make them stronger, more stable. 
    
        Meanwhile, Vale and the others have started integrating what they call "Voidmen" into their research. They’re cagey about it, but I’ve seen the files. After fractionating the energy signature of a subject within the kugelblitz voidspace, each "frequency band" takes on a life of its own. They're these colorful static entities in the shape of a human, but filled with movement and chaos inside. The Voidmen fragments are being tethered to the loops, their residual energy treated as a resource rather than a warning. I overheard Cross call it "birthing chaos,” as if that’s something we should aspire to. They don’t realize what they’re dealing with - or maybe they do, and they just don’t care. Either way, they’re taking risks I can’t ignore.
        
        What strikes me most is how compartmentalized they’ve kept this work. Each of them contributes their expertise - Vale with his predictive models, Cross with her reality tuning, Manhav with his quantum feedback systems - but none of them seem to have the full picture. I’m not sure even Vale understands what he’s building. I've worked on enough clandestine government projects to understand the necessity of compartmentalizion, but this might be the most secure project I've ever been privvy to.
    
        That’s what terrifies me. This isn’t just about containing entropy anymore. The loops are becoming something more, something capable of reshaping reality itself. And the Voidmen, fragmented as they are, seem to be the key. If they stabilize the loops, what’s to stop them from becoming part of the loops? Or worse, from escaping them? The voidspace itself couldn't handle it. I could only imagine the chain-reaction that it would cause.
    
        I haven’t told Ray about my experiments. Not yet. As much as I trust him, this feels too dangerous, too raw to share. He’d probably encourage me to dive even deeper, to see how far I can go. And maybe part of me wants to, but the other part, the cautious part, knows there’s no turning back once I open that door.
    
        I’ll keep working in secret. If Vale and his team won’t trust me, I’ll find my own answers. But the more I uncover, the more I realize: this project is bigger than any of us. And if we’re not careful, it might consume us all.`
    },

    {
        id: 16,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Team Building in Simulated Chaos',
        content: `Sometimes, even at Demiurge, we need to step back from the chaos and remind ourselves that we’re still human - or close enough, in some cases. The Team Building Retreat was meant to be just that: a break, a bit of fun, and maybe a way to blow off steam without collapsing an actual dimension. Somehow, I ended up in charge of organizing the whole thing, which still feels ironic considering I’m not exactly known for being the life of the party. 
    
        To my surprise, it went well. Better than well, actually. The Quantum Puzzle Challenge was a hit, watching Vale panic over a simulated fractal anomaly was worth every late-night planning session. Dimensional dodgeball turned out to be less about teamwork and more about avoiding Ray’s antics as he gleefully lobbed phase-shifting projectiles at anyone within range. I think Manhav’s still sulking over that loss.
    
        The black hole simulation taught us a lot about teamwork and communication. Let’s just say we collectively decided that if a real black hole ever threatens our existence, we’re doomed. But it was nice to see everyone laughing instead of stressing about the next breakthrough, or the next disaster.
    
        Ray, of course, made the entire event feel like an adventure. At one point, he “rescued” me from a collapsing spatial anomaly that didn’t actually exist, claiming I owed him coffee for his heroics. I rolled my eyes but didn’t argue. Spending the day with him like this - without the weight of the lab pressing down on us - was refreshing. Easy, even.
    
        I’ll admit, I’m proud of how it all came together. For someone who’s spent her career managing entropy, working on organizing an event that wasn't utter chaos was fun. Maybe I needed the break as much as anyone else. Or maybe, just for a moment, I needed to be reminded that chaos doesn’t always have to be dangerous. Sometimes, it’s just a game of dodgeball.
    
        Now, back to reality. And entropy. And the ever-growing pile of unanswered questions about the voidloops. The choas. The dangerous kind.
        
        But for one day, at least, we were allowed to laugh, and that feels like a win.`
    },

    {
        id: 17,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Through the Tunnel',
        content: `The breakthrough came late last night, almost by accident. I’ve been running small-scale tests for weeks, adapting the framework Ray developed during his “oven experiment” - the one where he remotely manipulated a household appliance using synchronized temporal signals. The principle was simple enough - establish a stable quantum link, then apply targeted frequency modulation to maintain control across distances. Only now, I’ve repurposed it for something far more ambitious.
    
        Using the modified modulation arrays and entropic stabilization protocols, I’ve managed to open a tunnel into the voidspace. Not just a tear, but a directed channel, routed through several intermediate layers of reality. Each layer acts as a node, masking the origin and endpoint of the tunnel, a sort of dimensional VPN. This design not only obscures the tunnel’s path but also stabilizes it long enough to interact with the voidspace directly.
    
        What I saw through the tunnel was indescribable. The voidloops, which the research team has been theorizing and stabilizing in fragments, appear almost organic when observed untethered. They shift, pulse, and evolve as though responding to some unseen force. I managed to isolate specific elements within a loop and even interact with them briefly, something Vale’s team hasn’t been able to achieve untethered in the Interference Agency array.
    
        This raises questions. If I can observe and manipulate these loops directly, why has Vale insisted on such destructive methods? Is it ignorance, or is there something they’re not telling me? Their integration of the Voidmen fragments suggests a deeper agenda. One they’ve gone to great lengths to keep hidden.
    
        I’m keeping these experiments to myself. I wan't to tell Ray, he's one of the few souls on this planet who could possibly understand, but I can’t risk tipping my hand just yet. If the others knew what I’d discovered, I don’t trust how they might use it - or worse, how they might try to stop me.
    
        The Voidspace holds answers, and the loops are the key. I can feel it. Whatever the others are planning, I need to stay ahead of them. If that means working alone, so be it.`
    },

    {
        id: 18,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Rediscovering the Fire',
        content: `I’ve been rereading my old journal entries. It started as an exercise in reflection, an attempt to trace the path that brought me here - albeit mandated by Demiurge policy, but helpful nonetheless. But what struck me most wasn’t the progression of my research or the milestones we’ve reached - it was the change in me.
    
        When I first started these logs, I wrote like a scientist cataloging the universe. Detached. Precise. Everything was data points and hypotheses, controlled observations and risk assessments. And then somewhere along the line, that began to shift. Slowly, subtly, but undeniably. 
    
        The more I exposed myself to the chaos of Demiurge - the experiments, the anomalies, the impossible made real - the more I felt something stir inside me. That storm-chasing child, the one who sat in the back of her father’s Jeep scribbling equations as the sky roared, came back to life. I didn’t just observe the chaos anymore; I wanted to be part of it. I wanted to push boundaries, to play with fire, to see what lies on the other side of the thunderclouds.
    
        Maybe that’s why I gave Ray a chance. The old me, the cautious, serious scientist, would have dismissed him as a distraction, a class clown with a knack for genius. But now? Now I see him differently. His chaos is a mirror of my own, the fire he carries on the outside reflecting the one I’d buried for so long. He doesn’t just chase the storm; he becomes it. And I can do the same.
    
        I feel like a new woman. Reinvigorated. Passionate. The world - the universe - the multiverse - feels bigger, fuller, brimming with possibilities I’m no longer afraid to chase. I’ve rediscovered my love of experimentation, of throwing caution to the wind and seeing what happens when you let the chaos in. 
    
        The Voidspace is my new storm, a galactic-scale frontier of unpredictability. I’m ready to dive in, to see how far I can go, to find out what lies beyond the edges of what we know. And if it burns me, so be it. What’s life without a little risk?`
    },

    {
        id: 19,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Edge of Sacrifice',
        content: `I found out today that Ray volunteered himself for Experiment 8934.omega. The voidloops. The fractionation process - the one Vale has been pushing as “revolutionary” - isn’t just theoretical anymore. It’s happening, and it’s happening to Ray. He signed up without hesitation, of course. Typical Ray, leaping headfirst into the fire with that infuriating bravado and brilliance. 
    
        I don’t know what to feel. Fear, mostly. Fear that this process, this experiment, will rip him apart. This is new tech - splitting a consciousness into distinct entities, with no guarantees of reintegration. The risks are enormous. The variables, uncountable. I’ve spent hours runing simulations, adjusting parameters, trying to account for every contingency. But it’s never enough.
    
        He doesn’t know about my own experiments. About the tunnel I’ve created into the Voidspace, the way I’ve managed to reach through layers of reality to interact with elements of the loops. He can’t know. If he did, he’d insist on pushing it further, and I can’t risk that. Not now. Not when he’s about to step into something that could destroy him. 
    
        Part of me wants to stop him, to tell him we’re not ready, that the risks are too great. But I know Ray. He wouldn’t listen. And, deep down, I understand why he’s doing this. It’s not just about the science, the potential breakthrough - it’s about who he is. Ray thrives in chaos, in pushing boundaries most people wouldn’t dare approach. He’s willing to sacrifice himself for this, to risk everything because he believes in it. 
    
        And I love him for that. That’s the hardest part. I love him, and I don’t want to lose him, but I know I can’t take this from him. To stop him would be to erase who he is, to undermine everything that makes him Ray. Even if it breaks me, I have to let him do this. 
    
        I’ll be at the controls tomorrow, overseeing every detail. I just hope we don’t lose him in the process. Ray says I overthink, that I worry too much. Maybe he’s right. Maybe this will all go exactly as planned. But if it doesn’t... If it doesn’t, I don’t know how I’ll keep going.`
    },

    {
        id: 20,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Eye of Everything',
        content: `Tonight, I saw it. The center of everything. Not the chaos, not the entropy, but the calm within it. The eye of the storm. The Eye of Everything.
    
        Using the tunneling system I’ve refined, I was able to align the coordinates perfectly and peer deeper into the Voidspace than anyone has dared. What I saw I can't explain. A vast expanse of stillness, surrounded by a maelstrom of energy and disorder. It wasn’t empty, though - it was dense, like the silence after a storm passes, carrying the weight of what’s been destroyed. We are the whirling winds, the rushing waters, the violence of nature. Everything we know is the storm. And the eye... it's something different entirely.
    
        It reminded me of what my father used to say about hurricanes. “The eye is calm, but it only exists because of the storm around it. If the storm dies, so does the eye.” That thought has been haunting me since the experiment. What if this stillness I’ve glimpsed - the Eye of Everything - isn’t peace but a warning? A growing cosmic cancer. A stillness that grows stronger as the storm weakens, spreading outward until there’s nothing left but silence. The storm of the universe, of us, dissipates into nothing.
    
        Vale’s team is clearly aware of it. I accessed fragments of their data and pieced together enough to see their intent. They’re using the voidloops - trapping the Voidmen, the fragments, siphoning their entropic energy - to amplify the storm. They’re feeding the chaos, strengthening it, because they believe it’s the only way to contain the eye. 
    
        It’s an elegant theory, in a way. If the storm dissipates, the eye will spread, simplifying everything into a singularity of stillness. But their methods - using sentient fragments, pushing the loops beyond their limits, throwing safety and morality aside for "the greater good" - feel as dangerous as the eye itself. What happens if they lose control? What happens if the storm becomes too strong to contain? What happens if the eye looks back?
    
        As I stared into the Voidspace tonight, I felt both awed and terrified. I felt like I was witnessing the birth of God, a divine light that expanded infinitely, but was pure and singular in its source. The calm center is seductive in its quiet nothingness, but I can’t escape the sense that it’s also a hunger - a force that consumes everything in its reach. If Vale is right, and we need the chaos to keep it at bay, then what does that mean for us? Are we meant to live in perpetual turbulence, just to survive? Is that what existence is - a raging storm of entropic energy forever reducing into pure chaos?
    
        These questions linger, unanswered, as I write this. The storm may be our salvation, but it is also our curse. The Eye of Everything watches, blindly.
    
        What I’ve seen cannot be unseen, and I’m not sure I’ll ever look at the voidloops, my colleagues, or the universe the same way again.`
    },

    {
        id: 21,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Procedure',
        content: `They did it. Ray's gone. Or at least his physical form. He's been fractionated. The procedure was terrifying, but awe-inspiring. 
        
        Today, I watched as Ray - my Ray - ceased to be flesh and became something entirely new. The procedure was unlike anything I’ve ever witnessed, a mishmash of theoretical physics, quantum mechanics, temporal tuning, and sheer audacity. Dr. Vale’s fractionation process was, in theory, designed to convert physical matter into pure energy, but seeing it in practice was something else entirely.

    The chamber was sealed, every precaution taken. Ray, standing in the center, cracked a joke at every turn as they calibrated the system. Typical. I couldn’t bring myself to laugh. Not with what was about to happen. The chamber was designed to manipulate the fundamental properties of matter at its most basic level. Standard quantum electrodynamics treats electrons as point particles, infinitely small and yet impossibly dense. The procedure exploited the theoretical concept that each electron is, in essence, a naked singularity - a would-be black hole without an event horizon, revealing its singularity to spacetime itself. By aligning the naked singularities of every electron in Ray’s body through an array of high-energy quantum fields, the procedure triggered a cascade, collapsing his form into a single point of infinite density.

    The effect was indescribable. As the singularities aligned, the chamber grew darker - not the absence of light, but something far worse, like anti-light. It was as though the very concept of visibility was consumed. The edges of my vision seemed to fold inward, sucked into an overwhelming blackness that felt as though it could reach out and devour me. Staring into it was like looking at the sun, but inverted - an intense, oppressive void that obliterated perception.

    At the critical moment, the voidspace opened within the chamber. It wasn’t just darkness; it was the absence of everything. A complete and all-encompassing nothingness that bent the mind to look at it. The chamber’s containment fields strained, their energy levels spiking beyond safe thresholds. 

    At the heart of this void, Ray’s body was stripped away, atom by atom, until all that remained was pure energy, a radiant, chaotic glow barely held together by the containment field. The void threatened to spread, but the fractionation device was activated just in time. This device, a marvel of engineering itself, functioned like a prism. Instead of splitting light, it split Ray’s energy into discrete frequency bands. 
    
    His essence - the chaos and brilliance combined - emerged from the chamber as seven distinct entities, each one shimmering with static energy in a color of the rainbow. They were not just fragments of him; they WERE him, each one carrying a piece of his personality, his essence. Violet, red, orange, yellow, green, blue, and indigo. They were recognizable as Ray, but fractured, each one carrying a piece of his essence, his personality, his chaos. The violet fragment swaggered as it stepped forward, its rough, mocking tone a perfect echo of the shitty fake East Coaster accent Ray used to irritate the military suits. The red one immediately declared itself “Reginald Brunswith III,” a ridiculous persona Ray used to mess with telemarketers. Each fragment was him, yet not him - a kaleidoscope of who he was.

    The fragments were then placed into voidloops, self-sustaining cycles of entropic energy. Each loop was a perpetual motion machine, spiraling infinitely without collapse, generating endless entropy. The loops were designed to balance perfectly, like a basketball spinning on the rim without ever falling through. Each one only needed a single spark to set it into motion, and from there, it would spiral forever. As long as we kept them stable.

    The voidloops didn’t just contain the Voidmen - they amplified them. Through their entropic signatures, we could create copies of their avatars and route them into new loops. But each duplication weakened the original fragment slightly, introducing instability. It’s the price we pay for extracting so much chaos energy from Ray. Vale’s team has been meticulous about managing these iterations, but I can see the cracks forming.

    The concept of black hole electrons - singularities hidden within every particle of matter - is one I never thought would be applied so literally. By collapsing Ray into a collection of his own singularities and splitting him across the spectrum, we’ve created something extraordinary. He’s chaos incarnate, fragmented and bound to the loops, yet still somehow him. Each fragment is a reflection of the man he was, amplified and distorted by the void.

    I should feel relief that the procedure was a success, that he didn’t disintegrate into nothingness. But as I stand here, watching his fragments trapped in their endless loops, I can’t help but wonder: how much of him is left? How much of Ray survives in those flickering, prismatic figures? And how much longer can they hold together before the entropy tears them apart?

    The loops are stable, for now. But I know Ray, and I know his chaos. If anyone, or anything, can break the unbreakable, it’s him. I just hope he’s strong enough to hold on.`
},
   

    {
        id: 22,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Fragments of Ray',
        content: `It’s been weeks since Ray became what he is now. The Voidmen. Fractionated. Chaos embodied, multiplied, and scattered across dimensions. And somehow, he’s still the same infuriating, brilliant, ridiculous jackass I fell in love with. Only now, he’s everywhere.
    
        I’ve spent countless hours monitoring the voidloops where his Voidman fragments are trapped. My official role is to maintain their stability, ensuring the entropic energy they generate doesn’t spiral out of control. But the truth is, I’ve been watching for more than that. These loops are more than containment fields - they’re a window into who Ray is, in ways I never fully understood before.
    
        Vale’s team has programmed the loops as “MISSIONS,” supposedly to keep the fragments engaged and to optimize the entropic output. But what they’ve really done is recreate Ray’s office antics on a multiversal scale. One loop has the violet fraction of him - with that absurd accent he used all the time in presentations - pranking the other Voidmen by gluing coins to the ground. Another shows the red fraction getting duped by a sign on the ceiling that reads “GULLIBLE.” The loops are full of these moments: toilets overflowing with bubbles, inflicted deja vu, eating napkins, replacing batteries with baby carrots, all the endless pranks that only Ray could come up with.
    
        Each fraction has its own quirks, its own piece of him. The violet one’s rough bravado, the red one’s theatrical absurdity, the tenderheartedness of green, and the others, each carrying some spark of his personality, his essence. Watching them feels like a surreal TV show, one where the star is a fragmented, chaotic version of the person I love. It shouldn’t be comforting, but somehow it is. He’s still here, in all his chaotic glory, making everyone around him laugh or groan or both. Even the fragments are unpredictable in ways Vale’s team didn’t anticipate - Ray’s chaos runs deeper than even they could have guessed.
    
        Of course, I can’t forget the larger picture. Vale has been using Ray, his entropic energy, to feed the voidloops and strengthen the storm around the Eye of Everything. It’s a brilliant, terrible plan, and I hate how effective it is. They don't know I know, and I don't know why they don't want me to know. 
        
        Ray was chaos incarnate in life, and now that same chaos fuels the loops. It’s my job to keep him stable, to ensure the fragments don’t unravel or lose cohesion. But watching him, even fractionated, makes me think he’s more stable than anyone realizes. He thrives in chaos. It’s who he is, and somehow, even as a Voidman, that hasn’t changed at all. Ray's still right there on all these monitors, playing out like a collection of home movies filtered through a broken CRT TV. 
    
        I’ll keep watching, not just because I have to, but because it reminds me of who he is - and maybe who I am, too. The storm may rage, but he’s still Ray, in his own way. And maybe that’s enough for now.`
    },

    {
        id: 23,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Red Anomaly',
        content: `The red Voidman fragment - Reginald Brunswith III, as he’s taken to calling himself - has been acting strangely. More strangely than usual, I mean. His behavior in the loops is erratic, even by Ray’s chaotic standards. There’s a pattern, but it’s subtle - moments of divergence, where he breaks free of the programmed chaos and starts... doing something else. Something not scripted. Sometimes something dangerous.
    
        I’ve been watching him closely, trying to understand what’s happening. At first, it seemed harmless enough: deviations in the loop where he didn’t pull the prank or didn’t follow the routine Vale’s team set up. But then I noticed something darker. In some loops, he’s caught in a cycle of endless death, a recursive loop where he dies over and over, each iteration more violent than the last. It’s horrific, and it shouldn’t be happening. Can Ray feel this?
    
        I thought I could help. Using the tunnel, I tried to stabilize his loop, to smooth out the aberrations. But the moment I interfered, the entire spectrum shifted. The other Voidmen reacted - shifting, colliding with the loop edges, and causing a cascade of minor collapses. Vale noticed the anomalies immediately. He’s convinced the red Voidman is a destabilizing force and has already started discussing removal protocols. He doesn't know it was me who caused it.
    
        I can’t let that happen. Red is part of Ray. Flawed, chaotic, ridiculous - but still Ray. Removing him would mean erasing a piece of the person I love, and I can’t allow that. I’ve tried to keep my experiments secret, but if I push further, Vale will know. He already suspects something. 
    
        The absurdity of the situation isn’t lost on me. Watching the Voidloops is like seeing a surreal sitcom version of the lab: the pranks, the jokes, the exaggerated personalities of Ray’s fragments all playing out in an endless, entropic theater. Red is the wild card, the one piece Vale can’t control, and that terrifies him. But it’s also what makes him essential. Without that chaos, the loops risk becoming too structured, too stable. And without the storm, the Eye of Everything will spread.
    
        I need more time to figure this out. If I can stabilize red’s loop without altering the others, I might be able to save him - and maybe even use his entropy to strengthen the system. But Vale is impatient, and his team is already developing a device to isolate and erase him. They don’t understand what they’re doing. Removing red could destroy the balance entirely, unraveling the loops and everything we’ve built.
    
        I won’t let that happen. I’ll figure this out, even if it means stepping further into the void than I ever intended. Red isn’t just a fragment - he’s a reminder of who Ray is, of what he stands for. And I won’t let him be erased. Not now. Not ever.`
    },

    {
        id: 24,
        date: 'DATE: ███████ TIME: ████████',
        title: 'The Eye',
        content: `The more I try to understand the Eye of Everything, the less certain I become of my own comprehension. It’s not just an entity or an event - it’s a concept, a force, a fundamental understanding. Staring into it, even for a moment, feels like unraveling the threads of reality itself.
    
        The Eye isn’t light or darkness, motion or stillness. It’s absence. Absence of complexity, of differentiation, of time. It is what remains when everything else is stripped away - the calm center of a storm that spans the multiverse. In its presence, the chaos that defines us, that gives us meaning, simply dissolves. Not violently, but inexorably. A tide that sweeps away all complexity, leaving behind a stillness so perfect it becomes unbearable.
    
        The others - the team, Vale especially - are terrified of it. They call it entropy’s end, the ultimate collapse where all energy, all movement, ceases. But I’m not sure that’s true. Entropy is chaos, yes, but chaos is motion, and motion is life. The Eye doesn’t feel alive. It feels inevitable. It isn’t entropy fulfilled; it’s entropy erased. A storm can't rage forever.
    
        I keep asking myself: why does it exist? What is the universe trying to tell us? The storm only exists because of the eye, and the eye only exists because of the storm. What is an eye of a storm without the storm? If the chaos dissipates, the eye will spread, reducing everything to its stillness. But what if it’s not a threat? What if it’s simply balance? A counterweight to the endless entropy we create? The cosmic equivalent to a calm spring day?
    
        Vale’s approach, using the voidloops and Ray’s fractionated chaos to strengthen the storm, is effective - so far. They’re feeding the very chaos that defines our existence, ensuring the storm rages on so the Eye cannot grow. But how long can that last? Chaos is inherently unstable, and every voidloop, every fragment of Ray, is a gamble. One wrong move, one destabilized loop, and everything could unravel.
    
        I want to study the Eye more, to understand what it truly is. But the risks are staggering. Every moment we spend near the Voidspace, every experiment, every glance into that endless void puts us closer to losing ourselves. And with Ray in the voidloops, it’s not just my work I’m risking - it’s him. His fragments generate the chaos we need to survive, but they’re fragile, more so than Vale will admit. If the Eye spreads, it will take everything, including him.
    
        There’s a something more buried in all of this, one I can’t fully articulate yet. The Eye of Everything is the multiverse’s answer to itself, a paradox that demands resolution. It is the stillness born of infinite motion, the end that justifies every beginning. Maybe it’s not a question of fighting it or fearing it. Maybe it’s about learning to exist within the storm, to embrace the chaos while we still can.
    
        I’ll keep pushing, keep searching for answers, even if they terrify me. The Eye stares, unblinking. And I can’t look away.`
    },

    {
        id: 25,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Tuning Reality',
        content: `Amelia's Reality Tuning Device is unlike anything I’ve ever encountered. Initially, I thought it was a crude prototype - a glorified signal amplifier at best. But after testing it myself, I’ve come to understand its true potential. With enough patience and precision, it can do the impossible. It can create anything. 
    
        The process is a true mind-fuck, and I don't use that phrase lightly in this field. Using the device is like tuning a radio to an impossibly specific frequency where every other band is a completely different reality. By slowly adjusting the modulation arrays, I was able to locate a universe where the item I wanted - a ripe blood orange - existed at the precise coordinates of my palm. The device pulled it through the voidspace like threading a needle across infinite dimensions, and plopped the damn thing in my hand like a magician. It was tedious, maddening work to tune it correctly, but it worked. Sure, I've got a few dozen navel oranges and countless horrifying amalgamations of screaming flesh and living crystal, but anything is possible with the right tuning.
    
        This revelation changes everything. The potential applications are staggering - reshaping reality itself, piece by piece. And yet, I can’t help but feel uneasy. The device is a key to the multiverse, but it’s also a doorway to chaos. 
    
        Vale’s team successfully removed the red fragment using the Bifurcated Unification/Temporal Transference via Selective Extractive Xenopositioning procedure. They’ve isolated him, stripped him from the loops, and now he’s in their possession. I’ve reviewed the data signatures, and it’s clear that the red fragment wasn’t just chaos - it was a critical piece of Ray. Without it, the loops feel off. Less vibrant. Something missing. Like a colorblindness but for frequencies of reality.
    
        I’ve started analyzing the fragment’s entropic signature, searching for a way to reintegrate it without destabilizing the loops. It’s a daunting task. The red fragment’s energy is erratic, more volatile than the others, but it’s also essential. It carries something unique - something that made Ray who he was, and that the loops desperately need to sustain their entropic equilibrium.
    
        I’ve avoided mentioning this to Vale. He’s too focused on containment, on wielding the fragments as tools, to see them for what they are: pieces of a person. Pieces of someone I refuse to give up on. Of someone I love.
    
        I’ll keep refining my clandestine experimentation with the Reality Tuning Device, digging through its potential while searching for a way to reintegrate the red fragment. It’s dangerous work, but I’ve come too far to stop. If there’s a way to save Ray - to save all of him - I’ll find it. Even if it means threading the needle through the storm again and again, until I get it right.`
    },

    {
        id: 26,
        date: 'DATE: ███████ TIME: ████████',
        title: 'Delivering the Device',
        content: `Getting the Reality Tuning Device to Ray in the Voidspace was a logistical nightmare. The tunnel was stable enough, but aligning the dimensional coordinates to ensure the device landed precisely within his reach required hours of fine-tuning. When I finally slipped it through, holding my breath, hoping he’d recognize its significance, the first thing he said was, “Is this a buttplug?”
    
        I could have screamed. Admittedly, the device’s shape isn’t helping my case - it’s compact, tapered, and looks uncannily similar to a fucking buttplug. But hearing him dismiss it so casually made me want to pull it back and chuck it at Vale’s smug face instead. Ray, of course, has decided to call the tunnel the “glowy hole,” further reducing my attempts at serious communication to his comedic fodder. That's what I get for falling for the class clown.
    
        I’ve tried sending visual cues through the tunnel - diagrams, instructions, anything to communicate the device’s purpose. None of it seems to land. The Voidmen are chaotic by nature, and Ray’s fragments even more so. I can’t tell if he sees the images I send or if they’re being lost in the Voidspace, distorted beyond recognition. Every time I adjust the signal, I risk destabilizing the tunnel - the "glowy hole" - further.

    In the meantime, the voidloops have grown quieter. The absence of the red fragment is palpable. For a time, the other Voidmen seemed to notice too. The green one, Lime, started to put up crude “MISSING” posters of Reginald Brunswith III taped to the voidwalls. They searched their loops, calling his name in increasingly absurd ways. But that’s all gone now. Vale’s interference agents have erased every trace of the red spectrum fragment, systematically removing his entropic signature from the loops after a series of tethered trips into the voidspace.

    It’s eerie, watching them forget. The loops adapt as though he was never there, the voidmen carrying on without hesitation. They don’t even glance at the places where the posters once were or question the sudden silence where his chaos used to thrive. The erasure feels complete, but I can’t accept that. I can’t let it be the end for him.

    The Reality Tuning Device could change everything if Ray - or one of his fragments - can figure out how to use the damn thing. I’ve tuned the frequencies as precisely as I can, guiding the device into a loop where I know he resides. If he can just grasp its importance, he could manipulate the voidspace directly, tuning reality like a radio until he finds what he needs. But his signals are erratic, unfocused. He picks it up, examines it, and then discards it again, unaware of what it could do.

    For now, the device remains unused, its potential untapped. I’ll keep refining the tunnel, trying new ways to communicate, but the clock is ticking. The Eye grows every day, and Vale’s team shows no signs of slowing their dangerous experiments. Ray needs this device. I only hope he realizes it before it’s too late.`
},

{
    id: 27,
    date: 'DATE: ███████ TIME: ████████',
    title: 'A Rainbow Without Red',
    content: `I’ve reviewed Vale’s report on the red fraction, and the longer I study it, the more certain I become - it needs to be reintegrated. Without it, the voidloops feel incomplete, a rainbow missing one of its colors. The balance is off, not just mathematically but fundamentally. The entropic dynamics are skewed, the resonance frequencies slightly unstable. It’s subtle but unmistakable, like a symphony playing a few semitones off. 

    The red Voidman has always been the most volatile fragment, but it was also essential. Its chaos wasn’t just destructive; it was creative, the unpredictable force that kept the loops alive in ways the others couldn’t replicate. Now, with it removed, the loops are quieter, less vibrant. The remaining fragments are still functioning, but there’s a hollowness to their energy signatures, a missing note that reverberates through everything.

    Vale claims the red fraction is stable in its isolated loop, but his report tells a different story. The amplitude fluctuations, the harmonic anomalies, the bursts of tachyonic radiation - these aren’t signs of stability. They’re signs of a fragment struggling to stay coherent, of a piece of Ray that doesn’t belong in quarantine. 

    The fact that they’ve stored him on a USB drive, compressed into 12.3 megabytes, is almost laughable, if it weren’t so infuriating. Vale, in his arrogance, sees this as a success. I see it as a travesty. Red isn’t just data to be stored and analyzed; he’s a part of Ray, a part of the system that keeps the storm raging and the Eye at bay.

    I’ve started planning. Getting my hands on the fraction won’t be easy - Vale’s security protocols are maddeningly thorough - but it’s not impossible. The key will be timing. I need to wait for the right moment, when their focus is elsewhere, and extract the USB drive without raising suspicion. Once I have it, I’ll keep it hidden until I can figure out how to reintegrate red into the loops without destabilizing the entire system. With how small it is, I might be able to just copy it over and return it without notice.

    I don’t know how long that will take. The anomalies in red’s energy signature are unlike anything I’ve seen before, and reintegration will require precision beyond anything we’ve attempted so far. But I can’t ignore the voidloops’ imbalance. I can’t leave red to fade in isolation. 

    Ray is already fractured enough. Every fragment is a piece of him, a reflection of the person he was. Without red, the system feels wrong, incomplete. If there’s a way to bring him back, to make the voidloops whole again, I have to try. Even if it means stealing from Vale, even if it means risking everything. 

    A rainbow without red isn’t a rainbow at all. It’s time to set things right.`
},

{
    id: 28,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Anomaly in the Void',
    content: `I picked up an energy signature in the voidspace today - an anomaly that doesn’t match anything we’ve catalogued before. At first, I assumed it was a residual imprint from the voidloops or one of the Voidmen fragments. But the more I analyzed the data, the less sense it made. This wasn’t like the static, chaotic signatures left by Ray’s fractions. It was different. More coherent. 

    Human.

    That realization stopped me cold. The energy profile bears striking similarities to a human signature, something alive, something sentient. But there are no records of a human being introduced into the voidspace. Not officially. Not in any of the logged experiments. I double-checked every file, every experiment report from Vale’s team, from Cross, even Manhav. Nothing. 

    The anomaly persists, its signal faint but unmistakable. It fluctuates as though tethered to something unstable, pulsing erratically before fading, only to reappear moments later. It doesn’t behave like the Voidmen or the loops, but it doesn’t feel entirely separate from them either. It’s as if the voidspace itself is struggling to hold onto it, whatever it is.

    I ran the signature through our analytical systems, breaking it down to its entropic and temporal components. The results were unsettling. The patterns are too regular to be random, but too fragmented to be purposeful. It’s as though the voidspace is echoing, replaying fragments of something - or someone - caught between dimensions.

    If this is a human signature, how did it get there? Who sent them? And why aren’t there any records? I can’t shake the feeling that this is more than a mistake, more than an oversight. Something is wrong.

    I’ve flagged the anomaly for further analysis, but I can’t risk drawing too much attention to it. Vale’s team is meticulous about documenting everything they touch, and if they haven’t logged this, it means they either don’t know about it or they’re deliberately hiding it. Either way, I need answers before I go to them.

    For now, I’ll keep observing. The signature is faint, but it’s there, lingering at the edges of the Voidspace like a shadow that refuses to fade. If it is human, then someone has been trapped where they shouldn’t be. And if that’s true, I need to figure out how, and why, before it’s too late.`
},

{
    id: 29,
    date: 'DATE: ███████ TIME: ████████',
    title: 'The Great Enduckification',
    content: `I’ve been monitoring Ray's - Voidman’s - experiments with the Reality Tuning Device, and it’s becoming increasingly clear that he has absolutely no idea what it is or what it can do. He’s been using it to, for lack of a better term, turn everything he encounters into rubber ducks. Objects, fragments, even the green (or Lime as they've affectionately nicknamed it) fraction of himself - everything has become a victim of what he now calls the “enduckifier.” 

    The Reality Tuning Device is capable of reshaping existence itself, tuning into precise frequencies to pull anything from the vastness of the multiverse. And yet, Ray has somehow managed to reduce it to a another joke. I’ve watched him gleefully transform entire voidloop sequences into a cascade of ducks, laughing as they spiral endlessly through the void. He's having FUN with this thing instead of using it as the powerful tool it is.

    Worse still, he’s taken to calling the device a “buttplug,” a designation I deeply regret letting him associate with it. No matter how many diagrams, encoded messages, or visual cues I send through the glowy hole, the meaning gets garbled or lost entirely before it reaches him. It’s as if the voidspace itself conspires to distort everything I try to convey, something inherent in the medium destroys the message. My attempts to communicate its true purpose, to show him that it’s not a buttplug that turns things into rubber ducks but a tool of unparalleled power, have been utterly futile.

    Over the past several weeks, I’ve exhausted nearly every method of indirect communication I can think of. Visual signals turn into incomprehensible static. Written messages morph into nonsense. Even carefully calibrated resonance pulses, meant to convey binary data, are absorbed into the entropy of the voidloops before reaching him. Each failure only increases my frustration, and yet, I can’t stop trying. Ray needs to understand the importance of the device before his antics destabilize everything. I put too much work into this and too much risk to get him this damn plug - or Device - for him to squander it on rubber ducks.

    I’ve started brainstorming alternate ways to communicate, though nothing seems promising. I can’t shut down the tunnel entirely, not while he still has the device, but I also can’t risk him accidentally (or deliberately) unraveling the voidloops. The ducks are harmless enough for now, but the instability they introduce is adding up. If Ray keeps treating the device like a toy, it’s only a matter of time before the consequences become irreversible.

    So I guess I’ll keep watching, hoping that something I send through will finally get through to him. Until then, the voidspace remains an endless sea of rubber duck squeaks and chaos, with Ray at the center of it all, completely oblivious to the entropic maelstrom he’s creating.`
},

{
    id: 30,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Securing the Tunnel',
    content: `Vale’s been asking questions again. Subtle, indirect, but unmistakably aimed at the tunnel. Or the "glowy hole" as Ray has taken to calling it. Vale hasn’t mentioned it outright, of course, but his curiosity about recent voidspace activity is suspicious. He’s too careful to tip his hand, but I can tell he’s trying to connect dots I’d rather keep scattered. If he figures out how I’ve been creating the tunnel and what I’ve been sending through it, the entire operation could be compromised.

    I’ve spent the last 72 hours refining the methodology for creating the glowy hole, layering additional distortions and misdirections into the process. The tunnel’s path now routes through even more intermediate nodes, each one scrambled and reconfigured to obscure its origin. It’s overkill, but necessary. If Vale decides to pursue this, I need to stay ahead.

    While I was busy working on securing the tunnel, Voidman - still Ray to the core - decided to test the limits of the glowy hole in ways only he would. At first I caught the energy spikes, sharp and erratic, but it wasn’t until I saw the distorted visual feed that I understood what he was doing. Somehow, in his infinite wisdom, his first instinct wasn’t to cautiously explore the tunnel with his hand or a safe object. No, his first experiment involved... well, let’s just say it wasn't the safest part of his body to put into a glowing interdimensional tunnel. With his hips pressed against the tunnel, you can only imagine what he was shoving through. Fucking Ray.

    The resonance distortions that followed were beyond anything I’d accounted for, throwing the entire tunnel into instability. And then, as if that weren’t enough, he decided to follow up this “exploration” by finally sticking his hand through, as though only then considering it might be a good idea to feel out the situation. As if he knew I was there, his hand appeared in the tunnel contaoinment chamber and gave a brisk wave - as if he was passing an old acquiantence on the street. The absurdity of it would be almost funny if it weren’t so dangerously destabilizing.
    
    But he didn’t stop there. Over the next hour, he pushed increasingly absurd objects through, each one carrying dangerous amounts of entropic drift. Pretty much anything he could, including several of the rubber ducks he failed to return to their previous state.

    The entire system teetered on the edge of collapse. Entropic energy began leaking into the lab, threatening the stability of the voidloops and the tunnel itself. I managed to shut it down before the situation spiraled out of control, but not before sending one final message through the tunnel: NOT A BUTTPLUG.

    Part of me wanted to scream. Part of me wanted to laugh. And part of me wondered if Ray’s fragments are just too chaotic to be trusted with something this critical. I know he doesn’t fully understand what the device is or how to use it yet, but the risks he’s taking - even unintentionally - are staggering. If Vale knew what had just happened, he’d likely dismantle the entire project, and possibly me along with it.

    For now, the tunnel is offline. I’ll rebuild it, more secure and more precise than ever, but I need to rethink my approach. Ray is too unpredictable, too chaotic, to leave unsupervised. And yet, that chaos is exactly what makes him essential. If I can just get him to take this seriously, if I can find a way to communicate the importance of the device without opening a literal void for his experiments, I might be able to salvage this.

    Until then, the "glowy hole" remains dark. And so does my patience.`
}, 

{
    id: 31,
    date: 'DATE: ███████ TIME: ████████',
    title: 'A Missing Piece',
    content: `The energy signature in the voidspace has been haunting me for weeks, its irregular patterns pulsing like a heartbeat in the dark. It’s human - I’m certain of it now. And today, I found a piece of the puzzle I hadn’t considered before: Nicholas Manhav. 

    Manhav has been conspicuously absent for some time now. At first, I thought little of it -he’s always been the type to vanish into his work, reappearing only when he has something groundbreaking to share. But when I cross-referenced his last known activities with the first detection of the voidspace signature, the timelines aligned too perfectly to ignore. He went missing right when the anomaly appeared.

    If the signature is Manhav’s, the implications are staggering. Why would he enter the Voidspace? And more importantly, how? The tethered method of Bifurcated Unification/Temporal Transference via Selective Extractive Xenopositioning - the Interference Agent process - requires immense resources, meticulous preparation, and a full team to execute it. There’s no record of Manhav undergoing such a procedure, and even if he had, there’s no indication he had access to the necessary equipment. So how did he get there?

    There’s another possibility, one I’m hesitant to admit. Vale and Cross. They’ve worked closely with Manhav on this project, sharing their research and advancing the voidspace experiments together. If anyone would know how he got into the voidspace, it’s them. But if they know, why haven’t they said anything? Did they help him? Or worse, did they send him?

    I can’t shake the feeling that something deeper is at play here. Vale, for all his precision and control, has always harbored a willingness to push boundaries. And Cross, with her mastery of dimensional tuning, has the means to open doors that shouldn’t exist. If they conspired to put Manhav into the voidspace, what was their goal? Was it a test? A necessity? Or something far more sinister?

    The energy signature remains faint but persistent, flickering like a signal in need of decoding. If it is Manhav, what is he doing there? Is he trapped, or did he enter willingly? And why hasn’t he tried to communicate? The voidspace is vast and dangerous, but even in its entropy, there are ways to send signals, to leave traces.

    I don’t know what to do with this information yet. Confronting Vale or Cross feels premature. If they are involved, they’ll deny it, and if they aren’t, I risk exposing my own research prematurely. For now, I’ll continue to watch, to listen, to piece together the fragments of this mystery. 

    If it is Manhav, and if he’s in trouble, I have to find a way to help him. But the question that keeps me awake at night isn’t just how he got there - it’s why. What was worth risking everything for? And what did he hope to find?`
},

{
    id: 30,
    date: 'DATE: ███████ TIME: ████████',
    title: 'What Lies Beneath',
    content: `I’ve always suspected that there were things Vale and the others weren’t telling me, but until today, I didn’t realize how deep the deception went. After days of bypassing firewalls and skimming through encrypted logs, I finally accessed the internal files on the voidloop experiments. What I found wasn’t just alarming - it was devastating.

    They’ve been keeping everything from me. Not just the details of their experiments, but their conclusions, their decisions, their plans. They know about the Eye - though they don’t call it that. Its true name has been expunged from the records, a deliberate and methodical erasure. Even the fragments of data that remain feel haunted, like echoes of something too dangerous to name. They've created their own Voldermort. A he who must not be named, but censored with a black bar. Yet, from their research, it’s clear they’ve seen it, just as I have. And they’ve reached the same conclusion. It is inevitable, unstoppable, omnipotent. The Eye is the end of everything.

    But the files revealed more than just their silence. They revealed betrayal. Ray’s fractionation wasn’t just an experiment. It was a calculated decision to rid the lab of him. His particular brand of chaos - the pranks, the jokes, the unpredictable brilliance that often bordered on catastrophic - was seen as a liability to them. They sent him into the voidspace not just because he was “uniquely suited” to generate entropy, but because they wanted him gone. The man I love, reduced to a tool and discarded like an inconvenience.

    The files also confirmed something I’d begun to suspect. Vale isn’t fighting the Eye. If anything, he’s working for it - or maybe with it. His calculations, his predictive models, all point to a disturbing alignment with the Eye’s goals. The balance he claims to seek, the chaos he says is necessary to sustain the storm, feels more like a slow surrender. And then there’s Manhav. The data suggests that his entry into the voidspace wasn’t voluntary. The patterns, the signatures, all point to manipulation. Was he tricked? Sacrificed? Did Vale send him, knowing he wouldn’t return? Did the Eye trick him into sacrificing himself?

    What shocks me most is Cross. She’s positioned herself firmly against me in these files, her reports critical of my methods, her recommendations designed to sideline my involvement. And yet, the more I read, the more I wonder if she’s the only one who hasn’t been corrupted by the Eye’s influence. Her research, while secretive, still reflects a genuine effort to resist it. Maybe she sees Vale for what he truly is. Maybe she’s trying to fight him, just as I have found myself.

    And the Observers. Mentions of them are scattered throughout the files, vague references to entities watching from outside the system. I can’t tell if they’re literal beings or something else. The way Vale writes about them suggests both reverence and fear, as though he believes they hold the answers to questions even he’s afraid to ask. Are they real? Are they aware of us? Or are they something even stranger - an avatar of the universe itself?

    The betrayal and duplicity stings more than I want to admit. I don’t know who to trust anymore. The people I’ve worked alongside for years, the colleagues I thought were my allies, have hidden everything from me. They’ve conspired, lied, and betrayed, all in the name of fighting something that might already own them. And the Eye, it’s everywhere. Its influence seeps into the cracks of our reality, bending minds, twisting motives. How can I fight it when I don’t even know who’s already lost?

    The pieces don’t fit together yet, but I feel the shape of something enormous and incomprehensible forming. I need time to process, to plan, to decide who, if anyone, I can still rely on. And as much as I hate to admit it, I’m starting to wonder if I may be entirely alone in this.`
},

{
    id: 31,
    date: 'DATE: ███████ TIME: ████████',
    title: 'The Tide Turns',
    content: `The Eye’s influence is undeniable now. I see it in Vale’s calculations, in Cross’s silence, and in the voidloops themselves. Vale’s actions aren’t resistance - they’re subservience. He’s guiding us straight into the Eye’s grasp. Cross remains a mystery, watching, waiting, her loyalty unclear. And Manhavs energy signature flickers faintly in the voidspace, a reminder that he may have sacrificed himself, or worse, been sacrificed by them.

    I don't leave the lab anymore. I've been sleeping in my office with the voidloops on my monitors. They play over and over in eight second loops, a kind of lullabye for me every night. Ray still hasn't caught on with the Device, but he's at least discovered how to "unduckify" objects. Progress, no matter how small. 
    
    I fear that Vale and Cross have discovered my experiments. It's one of the reasons I don't leave my lab anymore, to make sure they can't access my systems. I have been meticulous in covering my tracks - both digitally and physically - but Vale has always been a stickler for details. It's not if, but when they discover. I have to prepare.`
},

{
    id: 32,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Escape',
    content: `Ray has escaped.`

}








    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    

    
    
    
    
    
    
    

]

export default estraussJournal;
import ovaleJournal from './ovaleJournal';
import nmanhavJournal from './nmanhavJournal';
import estraussJournal from './estraussJournal';
import rbakerJournal from './rbakerJournal';
import acrossJournal from './acrossJournal';

const userJournals = {
  ovale: ovaleJournal,
  nmanhav: nmanhavJournal,
  estrauss: estraussJournal,
  rbaker: rbakerJournal,
  across: acrossJournal,
};

export default userJournals;

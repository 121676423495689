const acrossJournal =[

{
    id: 0,
    date: 'DATE: ███████ TIME: ████████',
    title: '██████████████████████████████████████████████████',
    content: `████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████
    ████`
},

{
    id: 1,
    date: 'DATE: ███████ TIME: ████████',
    title: '██████████████',
    content: `█████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████`
},

{
    id: 2,
    date: 'DATE: ███████ TIME: ████████',
    title: '█████████████████████',
    content: `██████████████████████████████████████████████████████████████████████████████████
    ██████████████████████████████████████████████████████████████████████████████████
    ████████████████████████████████████████████████████████████████████████████████
    ██████████████████████████`
},

{
    id: 3,
    date: 'DATE: ███████ TIME: ████████',
    title: '██████████████',
    content: `███████████████████████████████████████████████████████████████████
    █████████████████████████████████████████████████████████████████████████████
    ██████████████████████████████████████████████████████████████████████████
    █████████████████████████████████████████████████████████████████████████████
    ████████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████`
},

{
    id: 4,
    date: 'DATE: ███████ TIME: ████████',
    title: '████████████████████████████',
    content: `███████████████████████████████████████████████████████████████████████
    █████████████████████████████████████████████████████████████████████████
    ███████████████████████████████████████████████████████████████████████████████
    ██████████████████████████████████████████████████████████████████████████████
    █████████████████████████████████████████████████████████████████████████████████
    █████████████████████████████████████████████████████████████████████████████
    █████████████████████████████████████████████████████████████████████████████████
    ████████████████████████████████████████████████████████████████████████████
    ██████████████████████████████████████████████████████████████████████████████████
    █████████████████████████████████████████████████████████████████████████████████`
},

{
    id: 5,
    date: 'DATE: ███████ TIME: ████████',
    title: '██████████████',
    content: `██████████████`
},

{
    id: 6,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Reassignment To Quantum and Dimensional Research',
    content: `The fallout from the failures of Project ██████████████, Operation ██████████████, and ██████████████ was extensive. I can’t discuss details - because there are none left to discuss. The higherups ensured a complete purge of all documentation. Every log, model, and prototype - gone. It’s as though they never existed. I'm sure even my mention of them in this very journal entry will be retroactively expunged.

Still, while the projects themselves are erased, their impact lingers. My reassignment as Director of Quantum and Dimensional Research isn’t a consolation prize - it’s proof of the knowledge gained from those projects.  We pushed the limits of what was possible, and while we overstepped, the insights we uncovered have redefined my understanding of quantum mechanics and dimensional permeability. I've earned my place.

Now, I’m tasked with rebuilding, advancing our work within the constraints of what they consider ‘acceptable.’ I suspect this is less about trust and more about containment. But I’ll take it. This new position is the opportunity of a lifetime. The specter of failure looms, but so does the promise of breakthroughs we’ve only glimpsed in fragments of corrupted data.

Here’s to starting again, even if the foundation is built on ashes. It'll make good fertilizer.`
},

{
    id: 7,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Week One',
    content: `One week into my new position, and I’m stunned, speechless, really. I thought I’d seen the cutting edge of science before, but I realize now how limited my perspective was. Here, as Director, the veil have been lifted. I’ve been given access to the kind of research that sounded like science fiction before.

Experiment 8212.beta was the first dossier I reviewed in full. Quantum entanglement in biological systems. It’s almost poetic. Synchronization across entangled pairs, the sharing of sensory experiences. The implications are staggering. I can’t stop thinking about the initial success. Small creatures moving as though they shared a single mind. The experiment was a partial success, btu that's what science is - a series of partial successes (or failures) until that breakthrough. And I feel that breakthrough is right around the corner.

It’s exhilarating. Every move feels like I'm standing at the edge of a new reality, peering into something just out of reach, but inches from my grasp. The failures remind me of the cost of ambition, but they also hold whispers of the answers just beyond the threshold if I'm bold enough to chase after them. This is what we signed up for, isn’t it? I'm not going to let my past failures define me, but motivate me to succeed.

This experiment yielded confounding results, but they're seeds to the next step we're gooing to take. An interaction between the quantum resonance we’ve stabilized and the dimensional layers I’ve studied before. It’s faint, fleeting, but I know there’s a connection there. Something waiting to be uncovered, something that feels personal.

There’s so much to do, so many possibilities to explore. It’s a privilege to stand here, in this moment, surrounded by brilliance and danger in equal measure. I hope someday I can share all of this with her. If she were here, I think she’d understand the wonder - and the cost - better than anyone.`
},

{
    id: 8,
    date: 'DATE: ███████ TIME: ████████',
    title: 'The Dream Team',
    content: `This place keeps surprising me.

Dr. Raymond Baker is a phenomenon unto himself. At first, I couldn’t reconcile the prankster who covered my desk in glowing slime with the mind that just presented the most elegant solution to temporal stabilization I’ve ever seen. I’m not sure I’ll ever fully understand how someone so playful, so irreverent, can wield such staggering intellect. If he applied the same ingenuity he uses for his sophomoric pranks to our research, we might solve every problem we can think of. I can’t deny it - he’s already proven invaluable to this team. 

And then there’s Dr. Vale. He’s everything his reputation promised. His presence commands respect, not through intimidation but through his sheer gravitas. He speaks with the wisdom of someone who’s seen things I can only begin to fathom, and his insights have already shaped the direction of our work. I feel honored to collaborate with him, even as the weight of his expectations looms large like a heavy shadow over all of our work.

This week, we began the trials for Experiment 7409.omega. Temporal reversion. It’s almost impossible to say that without marveling at the concept. Reversing time. We're turning clocks back, with science. A shattered glass reassembled itself in front of me, as if the break never happened. A flower, wilted and lifeless, bloomed again with all the vigor of spring. It felt like witnessing a miracle, and yet it was science, distilled and deliberate. We're doing things I have only seen in movies.

But the challenges are mounting. Reversing the simplest events is one thing, but the complexity compounds with living subjects. We’ve already seen the strain this process places on the temporal field, and there’s an undeniable toll on those exposed. Even the chamber itself seems to be protesting, its stabilizers struggling to keep up with our demands, red-lining every meter with every test we throw at it.

Still, the potential is breathtaking. To think we could selectively rewrite moments, it’s an entirely new dimension of control over time itself. It's almost like time travel, but at a localized instance in small jumps. I know we’re treading on dangerous ground. The disorientation and cognitive effects we’ve observed are sobering reminders of how fragile this balance is. But I can’t stop. None of us can. This is why we're here.

Every step forward feels like brushing against the fabric of something much greater. I can feel the momentum building, the barrier thinning, a sense that we’re close to a breakthrough, not just for this experiment, but for what’s coming next. This could open up a can of wormholes that takes us further than I would ever imagine.

I hope, wherever she is, that she’d understand why I keep pushing. Why I have to.`
},

{
    id: 9,
    date: 'DATE: ███████ TIME: ████████',
    title: 'A Whisper Through the Veil',
    content: `Cross-dimensional data transmission. Just the words make my head spin. Every day I'm faced with ideas and events that only the most whacked out scifi writer could conjure. Experiment 8665.gamma was always ambitious, but to see it work, to actually send a message through a quantum network spanning dimensions, was awe-inspiring. We’re communicating with realities we once could only theorize about. This is the kind of science that feels like magic.

The initial trials went better than I dared to hope. Data packets moved across dimensional layers with minimal corruption. Sure, there were glitches, burst of static here, a moment of interference there, but nothing we couldn’t account for. The potential is staggering. We’ve opened a door. One that might never close again.

But this isn’t just about science for me. Not entirely. I can’t help but think about Virginia. If this network works - if we can refine it - maybe, just maybe, I’ll hear her voice again. I don’t let myself dwell on it too long, but the thought keeps creeping back. 

It’s tucked safely in my office drawer. The Dimensional Persistence Capsule holds everything I need to bring her back. Her data, her energy, her soul. I'm still amazed how a device the size of an egg can contain the entire quantum energy of a human being. It's a soft lavender pastel color - sleek, seamless, encased in a velvety silicone - with a long, tapered "tail" and a textured pattern at the base. The Virtual Imprint Biometric Encoder embedded within is basically a hard drive for a soul. It vibrates gently with every button push; I'm not sure if it's haptic feedback or her energy responding from inside. I hide it away.

For now, I let myself believe. Just a little. The quantum network is stable, the transmissions are improving, and the possibilities stretch endlessly before me. Maybe one day, this technology will do more than send data. Maybe one day, it will bring her back.

I can almost hear her now, the way she’d tease me for getting sentimental. But this is why I push forward. Why I refuse to stop. For her.`
},

{
    id: 10,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Formidable Allies',
    content: `Today, I met Dr. Evelyn Strauss. I already knew her by reputation. Who doesn’t? She’s a force of nature in the field of entropy research. Her work is precise and unyielding, much like her demeanor. Meeting her in person, I found her to be every bit as brilliant as I expected, but also surprisingly approachable in her own way. She has this calm, measured way of speaking, as though every word is a carefully chosen piece of a puzzle. Compared to her, I sometimes feel like I’m all sharp edges and untempered enthusiasm, blurting out whatever's on my mind instead of plotting out every word. But she didn’t seem put off by it, if anything, I think she appreciated it. I could see the gears turning in her mind as we spoke, analyzing, calculating, but also listening. It’s rare to meet someone who truly listens.

Working alongside her is going to be fascinating. She’s straight-laced and methodical, but there’s a spark of something deeper, something curious like me, beneath the surface. I think we’re going to make a good team, balancing each other out. I wonder what she made of me. If nothing else, I hope she doesn’t think I’m reckless or naive. Enthusiastic, sure, but naive? No.

Speaking of reckless, Dr. Baker was at it again today. Poor Jenkins is going to need a vacation after this latest escapade. One moment, he was standing in the break room sipping his coffee, and the next, he had sprouted a half-dozen extra appendages. His screams were enough to send the entire lab running. Of course, Baker had it all under control (or so he claimed) and Jenkins was back to normal within minutes, no worse for wear. By the time the commotion died down, Baker was howling with laughter, and Jenkins was already asking for another cup of coffee, like nothing had happened. 

It’s a miracle anyone gets any work done around here. Between Baker’s pranks and the constant barrage of interdimensional snafus, this place feels like a playground for geniuses. Somehow, it works. 

I can’t help but feel that this team, these minds, were brought together for a reason. There’s something special brewing here - something bigger than any of us can see yet.`
},

{
    id: 11,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Shifting Frequencies, Strange Bubbles',
    content: `This week has felt like stepping into uncharted territory. I know, I say that every time, but this time it's real. The initial results from Experiment 6501.alpha were subtle but profound. Shifting quantum frequencies and observing the resulting minor changes in reality felt like tugging at the loose threads of an infinite fabric. It’s fascinating - colors that seemed to glow unnaturally, whispers of warmth in the air, and the strange dilation of time, as if the world itself was holding its breath. The shifts were temporary, of course, reverting as soon as the field stabilized, but the implications are staggering. I FELT it.

For the first time, we’ve proved that reality can be altered intentionally, even in small ways, by manipulating its foundational frequencies. It feels like holding the key to a door I’ve only glimpsed before. But there’s so much we still don’t know. More precise controls, better calibration, there’s work to be done before we can push further.

Experiment 9321 was another sucess. The anomalous reality bubbles - these bizarre, fragmented worlds nestled within our own - are the breakthrough we've been looking for. This was such a huge development that I’ve been working closely with Dr. Vale on this. His expertise in the Greyfield has been invaluable. After all, he's the one who discovered it. These voidbubbles seem to be pockets of entropy within the endless "nothingness" of the Greyfield. Unexpected, to say the least. 

Vale has an uncanny ability to explain the incomprehensible as though it were obvious. The reality bubbles are unlike anything we’ve ever encountered. Some feel alive, teeming with erratic energy, while others are desolate, as if reality itself has forgotten them. The more we explore, the more we discover how much we hadn't seen before. It's as if we were walking through a forest without seeing any of the trees - until we smacked face first into a mighty oak. 

The probe’s initial forays have revealed so much, yet it’s clear we’re only scratching the surface. Reversed gravity, accelerated time - these bubbles operate on entirely different rules. It’s as if they’re fragments of another universe bleeding into ours. Vale believes they’re connected to deeper anomalies within the Greyfield. I can’t help but wonder if these bubbles are windows into voidspace. The term has been whispered around the lab - tentative, unsure - but I feel it holds weight. Something about the Greyfield calls to me, as though it’s daring us to explore further.

This discovery has not only unlocked a new frontier of quantum research, but has allowed me the opportunity to develop a new tool to aid in this new frontier. The first designs are drafted  - a crude prototype of mishmashed parts we've used to stabilize and tune quantum frequencies - a concept born directly from the experiments we’ve been running. If it works, it could do more than just observe or nudge reality, it could reshape it, tune it like a radio. 

Think of it this way: our reality, the world you experience around you, is like a radio station. Earth FM. This universe is just one frequency band of, I can only imagine, an infinite amount. This isn't a traditional spectrum of high versus low frequency, but of a multidimensional lattice of infinite gradient, where every single iteration of every single universe coexists. We're just one infinitessimly tiny dot in this grid, and we've broken through the threshold to "tune into" the full broadcast. We've got a radio tuner for reality. 

Or at least the beginnings of it.

We’re standing on the edge of something transformative, but the edge is razor-thin. The bubbles, the Greyfield, the tuning device - these things are connected in ways we’re only beginning to understand. The future feels like it’s teetering on the brink of revelation, and I can’t wait to see where it leads.

For now, I let myself dream a little. If we’re right about this, then maybe the impossible isn’t out of reach after all.`
},

{
    id: 12,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Tuned In',
    content: `It worked. For the first time, we’ve moved beyond observation and into true control. Experiment 6893.beta wasn’t just a step forward - it was a leap. We're talking pole vault levels. Using the modified quantum field generator, we tuned into specific reality frequencies and HELD them. The results were nothing short of astonishing.

The concept of reality "bands" felt theoretical until now, but seeing it in action changed everything. Tuning to precise frequencies allowed us to focus on specific layers of reality, creating shifts that were stable and deliberate. The test subjects - both living and inanimate - responded in ways I never anticipated. One subject described the sensation of perceiving "layers of worlds" stacked atop each other, as though they could glimpse dimensions we’ve only theorized about. Others reported heightened senses, a kind of expanded awareness that lingered even after the modulation ended.

The inanimate tests were just as remarkable. A simple metal rod temporarily phased in and out of visibility, its physical properties shifting in ways we didn’t predict. At one point, it seemed to exist in two places at once (an effect we’ll need to explore further). More intriguing still was the creation of persistent reality "pockets," areas where the modulated conditions remained even after the generator was turned off. It was as if we had carved out a small piece of an alternate reality and left it hanging in the air.

The implications are immense. For the first time, we’re not just observing reality, we’re shaping it. The device still has limitations, of course. The controls are clunky, and the stability isn’t perfect. But the foundation is here. I can feel it. The idea of a portable reality tuning device no longer seems far-fetched. We’re closer than ever.

Our engineering team has been working on bringing down the size of the device while maintaining its usability, particularly for ease of transport and field testing. Right now it takes up half a room, but some of the prototypes we've been developing have been substantially smaller - including one that could fit in the palm of my hand. The design is... interesting, to say the least, but the egineering is undeniable. We'll surely have to continue with the development of this one.

I’m still processing what this all means. The applications, the risks, the possibilities - it’s overwhelming. But there’s a quiet thrill in knowing that we’re on the brink of something extraordinary. Every success feels like an echo of something larger, a piece of a puzzle I’m only beginning to see, walking through a vast dark network of caves - but my eyes are starting to adjust.

It’s impossible not to think of her in moments like these. The thought that tuning to the right frequency could connect me to her, even for a moment - it’s a hope I can’t shake. I keep it quiet, keep it tucked away. But it’s there.

Today was a good day. A day where the impossible became a little more possible. I hope she’d be proud of what we’re building here.`
},

{
    id: 13,
    date: 'DATE: ███████ TIME: ████████',
    title: 'The Shape of Things to Come',
    content: `It’s real. It’s portable. And it works!

Experiment 7205.gamma was a resounding success. The prototype device - a compact, handheld version of our reality modulation system - is everything we dreamed it could be. Holding it in my hand, I couldn’t help but marvel at what we’ve accomplished. It’s sleek, ergonomic, and functional. And yes, its appearance has sparked more than a few jokes around the lab. The shiny pink finish and distinctive "pluglike" shape weren’t exactly intentional, but honestly I kind of love it. It stands out, and maybe that’s fitting for something so groundbreaking.

Using it feels almost natural, as if the controls were an extension of thought. With a simple adjustment, reality shifts like a curtain parting to reveal hidden layers. During tests, the transitions were fluid, the shifts deliberate and controlled. The moment I tuned into an adjacent layer and watched as the environment around me shifted ever so slightly, it felt like standing on the edge of creation itself. 

The device isn’t perfect yet. The range is limited, and there are still minor stability issues to iron out. But this is just the beginning. For the first time, we’re stepping beyond observation and interaction into a realm where we can carry this technology with us. The potential applications are staggering. Exploration, defense, even practical uses we haven’t conceived of yet. Maybe one day this could be a household object. Or maybe I'm getting too far ahead of myself.

I can’t help but feel a surge of pride, knowing what this represents. It’s not just a tool; it’s a doorway. A way to bridge worlds and explore the infinite possibilities of the layers that make up our reality. The final design will undoubtedly refine the prototype’s quirks, but I’ll always have a soft spot for this first iteration.

Meanwhile, Dr. Manhav has been running experiments in xenobiology that are equally fascinating. He’s been working on a unique fungal extremophile capable of interfacing with quantum systems - something about bio-synthetic communication networks. It’s still in early stages, but the implications are thrilling. He mentioned the possibility of using the fungus to stabilize interdimensional signals, a concept that aligns so closely with our work that it feels almost predestined.

The energy in the lab is electric. Everything feels connected, like we’re on the verge of uncovering something monumental. This device, the experiments, the ideas, it’s all leading somewhere. I don’t know what comes next, but I can feel it building. We’re shaping the future, and I’m grateful every day to be a part of it.`
},

{
    id: 14,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Fine-Tuning Reality',
    content: `It’s hard to believe how far we’ve come. Experiment 7853.delta feels like the culmination of years of effort, even though I know we’re only beginning to scratch the surface. The device - now officially referred to as the Dimensional Tuner, though the nickname "The Buttplug" is (unfortunately) sticking - performed beyond expectations. 

The new design is both elegant and functional. The smooth, polished surface and the flared base allow for precision control, with the base doubling as an interface for adjusting reality frequencies. The three buttons on the base provide access to distinct frequency bands, and fine adjustments can be made with just a twist or click. There’s something satisfying about the tactile feedback; it makes the act of tuning feel deliberate, almost intuitive. Suck it, Apple.

The results have been incredible. Subtle changes - color shifts, ambient sound alterations, a sense of moving through invisible layers - were expected. But the real magic happened during advanced tuning. Brief reality overlaps provided glimpses into parallel dimensions: a desk that didn’t exist moments before, a shadowy figure walking through a wall as if it were air. It was both exhilarating and humbling to witness.

Not everything went perfectly. One subject experienced what we’re calling "intermodulation," becoming briefly entangled with overlapping dimensions. It was terrifying to watch, but thankfully they returned intact (though shaken). It’s a reminder of how delicate this work is. We’re exploring unknown territory, and with every discovery comes risk. 

Despite the challenges, the potential is undeniable. For the first time, we have a portable, user-friendly way to interact with and explore the fabric of reality itself. This device isn’t just a tool - it’s a passport into worlds we’ve only dreamed of. The possibilities stretch endlessly before us, from practical applications in exploration to the theoretical, to questions about what reality even means.

For me, the personal implications linger in the back of my mind. I’ve spent years dreaming of reconnecting with her, of finding a way to bridge the impossible gap. With each new experiment, each successful test, it feels like I’m taking one more step closer to that dream. I can’t let myself hope too much - it’s dangerous to tether yourself to something so uncertain - but the thought of hearing her voice again, even for a moment, drives me forward.

Every twist of the device’s base, every hum of a shifted frequency, reminds me of why I’m here. We’re building something extraordinary, something that could change everything. I don’t know what lies ahead, but I’m ready to find out.`
},

{
    id: 15,
    date: 'DATE: ███████ TIME: ████████',
    title: 'The Living Network',
    content: `The Fungal-Viral Neural Network (or FVNN for short) has become something of an obsession for Dr. Manhav. And I understand why. Working with him, I’ve seen firsthand how this hybrid system could redefine everything we know about communication, not just between devices but between realities. Today, we successfully linked the FVNN to a modified quantum modulation array, and for a fleeting moment, it felt like the entire lab was humming in sync. 

The network’s potential is extraordinary. By using fungal extremophiles with viral components designed for quantum resonance, we’re essentially creating a biological foundation for interdimensional communication. Dr. Manhav’s ingenuity in coaxing the network into responsiveness is remarkable. It’s not just about transmitting data; the FVNN adapts, learns, and seemingly anticipates the needs of the system it interfaces with. It's alive.

Our collaboration has been seamless. He approaches the network with meticulous care, while I focus on how it can be integrated with the devices we’re developing. Today, the FVNN stabilized a reality modulation test better than any mechanical stabilizer we’ve used before. It’s early, but I’m convinced this could be a game-changer, not just for the tuning device, but for all of our dimensional research.

And yet, there’s a strange beauty to it. The network pulses with life, faintly luminescent under the lab’s sterile lights. When the quantum resonance aligns perfectly, it almost feels like the FVNN is breathing, thinking. It’s awe-inspiring, and a little disturbing.

We’ve already seen hints of what it can do: stabilizing signals, bridging gaps between dimensions, and amplifying quantum shifts with precision. But it’s still early days. Dr. Manhav and I both agree that pushing the FVNN too far too quickly is a risk we can’t afford.

As I write this, the lab is quiet, and the faint glow of the network lingers in my mind. For all its brilliance, it feels untamed, like it holds secrets we’re not ready to understand. But that’s never stopped us before. I can’t wait to see where this leads - and how it might intertwine with our other projects.`
},

{
    id: 16,
    date: 'DATE: ███████ TIME: ████████',
    title: 'When It Speaks',
    content: `The FVNN spoke today - or perhaps “spoke” isn’t quite the right word. It responded, though not in any language we recognize. A cascade of magnetochemical shifts and subtle pulses of energy transmitted through its network. Patterns emerged, too intricate to be random. The team suspects it’s attempting to form some kind of language, and I can’t shake the feeling that it’s trying to understand us as much as we are it.

I sat beside Dr. Manhav during the session, watching as he adjusted the calibration to sync the FVNN with the quantum modulation array. As the connection stabilized, the patterns sharpened, resolving into something close to meaning. A faint hum filled the lab, more felt than heard, and suddenly, data streamed across the monitors. 

It’s tempting to call it a conversation, though we’re still deciphering its messages. The term “beyond help required” appeared in fragments, along with bursts of what seemed to be coordinates or temporal markers. What it’s referring to is unclear, but the implications are staggering. The network isn’t just intelligent. It’s aware. Aware of us, of itself, and possibly of something far beyond our comprehension.

Manhav and I exchanged a look as the session ended. Neither of us spoke, but the unspoken realization hung heavy in the air. We’re standing on the threshold of something extraordinary and perhaps dangerous.  The FVNN’s potential feels limitless. Its ability to communicate has already surpassed anything we could have imagined. And yet, there’s a disquieting edge to it all. It doesn’t feel entirely benign. Not malicious, either, but as if it operates on a scale we can’t fully grasp. Like it's a single part of something much, much larger.

As we pore over the data tonight, I can’t help but wonder: what happens if we’re able to truly understand it? Or worse, if it fully understands us?`
},

{
    id: 17,
    date: 'DATE: ███████ TIME: ████████',
    title: 'The Vision',
    content: `It showed me everything.

For a moment, I stood outside of time, outside of myself. Every choice I’ve made, every choice I will make - all laid bare, stretching infinitely in every direction. I saw every iteration of my life, every path I could take, and every consequence rippling outward. I tasted omnipotence.

And then I saw her.

Virginia.

The FVNN showed me the way. The steps I must take, the sacrifices I must make. To bring her back. To fix everything. To save everything. It’s not just a possibility; it’s a certainty. Every move, every calculation, every decision has been etched into my mind with unrelenting clarity. The path is there. I only need the will to walk it.

But this vision was not without cost. At the center of it all, it showed me ██████████ - a force so vast, so incomprehensible, it eclipsed everything else. It was something I couldn't fully comprehend, the mental equivalent of staring at the sun, leaving me dizzy and confused more than anything. From what I understand, ██████████ is like the cosmological theory The Big Crunch - but instead of reversal of entropy and a cyclic universal expansion and contraction, it's a "grand simplification." It's hard to put the words, the images and the feelings and events it showed me were almost incomprehensible. ██████████ could be god? Or some kind of glitch in reality? A runaway feedback loop that began in the fundamental oscillations of existence and resolved in a universal singular resonance - out of phase dimensions shifting into a perfect unity - assimilating everything into one single energy oscillation - the fundamental frequency.

I understand now why the FVNN had to show me everything. To grasp ██████████ is to grasp the totality of existence.

The weight of it is almost too much to bear. Yet, for the first time, I see the way forward, not just for Virginia but for everything. ██████████ may be inevitable, but it’s not insurmountable. The FVNN didn’t just reveal the problem; it gave us the tools to fight it. Maybe that's why it was here in the first place.

I feel changed. The clarity remains, though the omnipotence has faded, leaving behind an aching sense of loss. The nested epiphanies are just drifting memories, the complexities and vicissitudes they held now just hollow and laughably benign. Still, I know what must be done. For Virginia. For all of us.

And I will not falter.`
},

{
    id: 18,
    date: 'DATE: ███████ TIME: ████████',
    title: 'The Looping Void',
    content: `There’s a rhythm to the loops, a pulse of chaos and order that resides outside of logic. Working alongside Dr. Vale has been illuminating in ways I didn’t expect. His understanding of the "Voidmen" and the entropy spikes that define their existence is unparalleled. But even he seems cautious - aware that we’re tampering with forces far greater than ourselves.

Experiment 90001.a was a turning point, but it left us with more questions than answers. The feedback loops we observed were unlike anything I’ve encountered. They weren’t just repetitive; they were alive, evolving, adapting to every intervention we attempted. During one trial, I watched in awe (and dread) as the loops began stabilizing themselves, as if they were conscious of our interference.

The Reality Tuning Device was essential in these trials. With it, we were able to isolate fragments of the loops and examine them in stunning detail. But it wasn’t enough. The device’s limits became painfully clear as the Voidmen phased unpredictably, sometimes disappearing entirely before re-emerging elsewhere in the loop. Vale believes this instability is tied to the entropic zones we’ve observed in previous experiments, a theory I find increasingly difficult to dispute.

For me, the stakes feel personal. The FVNN showed me the path forward, but navigating it is another matter entirely. Every experiment, every discovery, brings us closer to the tools we’ll need to stop ██████████. But there’s a cost. Watching the Voidmen trapped in endless iterations, echoes of their former selves - it’s haunting. It’s a fate I wouldn’t wish on anyone.

Baker signed up for it. I knew of him as the office jokester and a true genius in his field, but I never thought he was suicidal - essentially a quantum kamikaze pilot headed for ██████████. Maybe he's the only one who could navigate a place as chaotic as the voidspace. 

And yet, amidst the chaos, there are moments of clarity. The modulation tests revealed faint traces of stability within the loops - tiny pockets where there's something more, somethign different. It’s in these moments that I see the glimmers of hope, the possibility that we can bring balance to this madness. 

For now, we press on. Vale’s resolve is unshakable, and I’ve found strength in his certainty. Together, we’re piecing together a puzzle that spans dimensions, time, and reality itself. It’s daunting, but it’s also exhilarating. Every loop, every Voidman, every fragment brings us closer to understanding, and to the battle we know is coming.`
},

{
    id: 19,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Threads of Chaos',
    content: `The loops are tightening. Each experiment brings us closer to understanding their nature, but also deeper into their chaos. Working with Dr. Vale has been inspiring and sobering. His focus is unwavering, his mind a scalpel cutting through the noise, yet I can see the weight of it all pressing on him. I feel it too. There's something there.

The Voidmen loops are evolving. What started as contained iterations are now bleeding into adjacent realities, creating echoes that ripple unpredictably. I suspect this is a side effect of our most recent frequency modulation tests, though Vale is cautious to draw conclusions too early. We both know that assuming too much is as dangerous as understanding too little.

The integration of the Reality Tuning Device with the Kugelblitz protocols has been both our greatest success and our most harrowing challenge. During one trial, the loops displayed something akin to agency. A Voidman - fragmented, yet aware - phased out of sequence only to reappear seconds later, seemingly observing us. The monitors captured a faint energy surge, one we’ve never seen before. Vale called it a ‘thread of entropy,’ but I wonder if it’s something more deliberate. 

I've got a few concerns about the high-entropy zones. Dr. Strauss and Dr. Manhav raised valid points about the risks, and I agree - this work teeters on the edge of uncontrollable. Yet, as dangerous as these zones are, they also hold answers. Stabilizing one could unlock the key to controlling the loops entirely. We’ve seen flickers of this during our recent tuning tests, moments of coherence amid the chaos. Sure, they’re fleeting, but they’re there.

██████████ looms large in my mind. Every experiment feels like a race against its inevitability, a frantic attempt to untangle the mess of existential knots before they tighten into a noose around us. Now I focus on what we can control. The Voidmen loops are teaching us more with every cycle, even as they challenge the limits of what we thought possible. The answers are there, buried in the noise. We just have to find them before it’s too late.`
},

{
    id: 20,
    date: 'DATE: ███████ TIME: ████████',
    title: 'The GYATT',
    content: `Of all the anomalies we’ve observed in the Voidloops, none have been as bizarre, or unexpectedly useful, as the GYATT. The Gluteal Yield Anomaly Temporal Tuning phenomenon, tied specifically to the violet/pink Voidman, is an unintentional yet remarkably precise barometer of entropy levels within individual loops.

The first time we noticed it, I thought it was some kind of sensor malfunction. As entropy in the loop spiked, his gluteal regions (his buttcheeks) began expanding to comically exaggerated proportions. I thought it was simply a misreading of the quantum particle receptor, but it was really there. Voidman's ass was huge. The correlation was undeniable. Every time the entropy reached a critical threshold, his form shifted in this oddly specific way. When the loop stabilized, the effect reversed, shrinking his gluteal yield back to a more proportional size.

The absurdity of it is almost too much to process, yet it’s proven invaluable. Using the GYATT as a barometer, we’ve been able to track real-time entropy fluctuations with a level of precision we never thought possible. During today’s trial, the gluteal expansion provided an early warning of an impending destabilization event. By tuning the Reality Modulation Device, we managed to prevent the loop from collapsing entirely.

Dr. Vale, ever composed, insists on treating the GYATT with the seriousness it deserves, but I’ve caught him suppressing a smirk more than once. Jenkins, on the other hand, nearly fell out of his chair laughing during our first analysis session. Despite the levity, even he admits its value as a diagnostic tool. The entire lab has started referring to the violet/pink Voidman as "the GYATTman."

Baker, if he knew, would likely be laughing along with us. For all its absurdity, the GYATT reminds me of the unpredictable brilliance of the work we’re doing. Even in the face of ██████████, we can laugh sometimes. It’s both humbling and a little ridiculous, but isn’t that always the case when you’re peering into the void?`
},

{
    id: 21,
    date: 'DATE: ███████ TIME: ████████',
    title: 'The Red Fragment',
    content: `The instability was becoming unmanageable. Of all the Voidmen, the red entity had always been the most volatile, but the recent events were something else entirely. Loop after loop, his presence created disruptions that rippled far beyond his immediate vicinity. The feedback loops grew more violent, the entropic spikes more chaotic. It was as though he were unraveling faster than we could stabilize him.

    In these loops, it's a world of their own. I haven't really explained the concept of the voidloops at all because it's a little difficult to wrap my head around in general. But essentially, after Baker's physical form was transferred into energy, he was split like a prism into seven distinct fractions - each containing a frequency band of his original essence. My research into reality frequency modulation was integral to this experiment and the stability of the voidloops themselves. 
    
    See, the voidloops are little pockets of entropy in the Greyfield. At first we thought they were rare, discovering them was a celebrated event in the lab when we first started searching them out. But in time, we began to realize that scanning through these bands in the Greyfield was like walking through drops of rain. The voidspaces were everywhere, in every conceivable direction. These little pockets loop eternally, caught in an endless cycle of the resonant frequency of its entropic signature - each of them corresponding to the "coordinates" in the Greyfield.

    These pockets exist outside of time, the little loops repeating forever, trapping the Voidmen - or Baker's disbanded frequencies -  in these cyclic events we call "missions" in the lab. I understand now why Baker was the perfect subject for these loops. His chaotic nature isn't confined to just his mind - his entire RESONANT FREQUENCY is chaos. Even when he's fractionated, his peronsality is reflected via his "missions" - or little pranks he's played in the office. I'm not sure if they're imprinted memories or possibly reintegrated energy signatures of past events, but the loops represent his essence in most distilled form. There's even one of the voidmen with a shitty east coast accent, like he always used to do during conference calls.

    Anyways, the red fraction has been a thorn in our side. Multiple extraction missions failed until we finally were able to pull him out. Dr. Vale’s frustration was palpable, though he hid it well behind his usual composed demeanor. He’d warned us that this might happen - that the red voidman was a fault line, a fragment too unstable to anchor within the loops. Today, his fears were confirmed.

Watching the interference agents move through the high-entropy zones was mesmerizing. Their presence seemed to distort the very fabric of the loops, each of their avatars moving in perfect synchronicity, like a swarm of pixels in the shape of a single person. When they reached the red voidman, the extraction was clean, precise, and perfect. We pulled him - or it? - out and have been monitoring the loops for any instabilities since.

Vale assured us that this was necessary, that the removal would stabilize the remaining loops and give us a fighting chance to keep this house of quantum cards upright. He’s right, of course. Without the red Voidman, the loops are quieter, less volatile. But there’s a something here that I can’t ignore. A piece of Baker, however fragmented, is now gone. And the others are starting to realize it.

The data we collected during the extraction is invaluable. It’s already revealing new insights into the behavior of high-entropy zones and the interactions between voidmen fragments. Yet, I can’t shake the feeling that we’ve crossed a line. I'll focus on the work. The remaining Voidmen are stabilizing, and the loops are holding. It’s progress, but it feels fragile, as though the entire system could shatter at any moment. Still, we press on.`
},

{
    id: 22,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Eyes In The Void',
    content: `They are always there, just at the edges. The Observers. 

We've discovered some kind of rift, I'm not sure how or where exactly, but the energy signature is undeniable. Their appearances follow no pattern I can discern, yet they arrive at the most pivotal moments - when the loops destabilize, when the Voidmen falter, when entropy builds to its breaking point. They don’t interfere, but their presence is undeniable, like shadows cast by a light we cannot see. There are moments in this work that make you question the nature of everything. Reality, purpose, existence itself. The Observers are one of those moments. 

We’ve known about them for some time now, though “know” feels like a stretch. They’re not seen, only detected. The rift picks up their interactions, fragments of intent that bleed into our systems. And then, there are the comments. Words, phrases, ideas that mean nothing to us but resonate with a purpose we can’t comprehend: "skibidy rizz," "first," "ratio," "W." They seem meaningless.

It’s not just their words, though. It’s their numbers. The Observers aren’t a singular entity or a small group. Every interaction suggests there are millions of them - interdimensional beings watching us, observing the voidloops, the voidmen, the lab itself. 

We don’t know why. Are they scientists like us, studying the chaos from afar? Or something else entirely? Voyeurs? Judges? Spectators?

The Voidmen, in their fragmented states, seem aware of them. Maybe even more aware than we are. During one loop today, the violet voidman muttered something incomprehensible, only for the words "followers" and "subscribers" to emerge clearly from the chaos. The loops themselves occasionally seem to shift in ways that suggest influence beyond our control. It’s as if the Observers are shaping them, nudging them, reacting to what they see. 

The idea that we’re part of some interdimensional performance - or worse, entertainment - is almost too much to process. The loops, the experiments, our struggles against ██████████, are they watching out of curiosity? Malice? Amusement? The thought that they might be reading this very journal entry right now is unnerving. I can feel their presence even as I write, an oppressive weight pressing in from beyond the rift, like eyes of ghosts over my shoulder.

Vale insists we remain focused, but even he seems shaken by the scale of it all. Manhav joked about opening a dialogue, but there’s fear behind his humor. How do you talk to an audience of millions when you don’t even know what to say - or if they want you to succeed?

I can’t shake the feeling that we’re being pulled into something far larger than we can comprehend. This is bigger than all of us. The Observers aren’t just watching; they’re influencing. The Voidloops bear their fingerprints. This is not longer in our hands. What happens if they decide they don’t like what they see? Or worse, what if all of this is for their amusement, and they’re just waiting for the final act? What comes of us after that?

For now, we keep moving forward. But the weight of their gaze never leaves. It’s as if the void itself is staring back.`
},

{
    id: 23,
    date: 'DATE: ███████ TIME: ████████',
    title: 'The Glowy Hole',
    content: `The Glowy Hole is the lab's biggest problem to date. It appeared suddenly in the Voidspace and, at first, seemed almost benign, a strange anomaly feeding the Voidmen various objects - tools, random debris, even inexplicable artifacts. But now, it’s become a problem. A dangerous one.

The violet Voidman has somehow acquired a Reality Tuning Device through the Glowy Hole. We have no idea how it got there or who placed it in the portal. This isn’t just a minor anomaly anymore; it’s a potential disaster. If the Voidman learns to use the device properly - or worse, if it activates in an uncontrolled way - it could destabilize the loops entirely. The consequences would be catastrophic. The Voidspace could breach, bleeding into our reality. I can't even fathom what that could lead to.

For now, we’ve been lucky. The Voidman has only managed to turn objects into rubber ducks - an inexplicable and almost comical side effect of his fumbling attempts to use the device. But the potential for destruction is there, and every moment it remains in his hands feels like a countdown to disaster. After all, it's Baker we're dealing with here.

Vale is beside himself. He's let his mask slip a few times, frustration and urgency and the stress of the universe bearing down on him. He’s spearheading the effort to figure out the Glowy Hole’s mechanics, but even he admits we’re grasping at straws. It's a threat we can’t ignore, and the fact that we don’t know where it came from or why it’s there makes it all the more unsettling.

The Voidloops are fragile enough without this added chaos. We’re scrambling to come up with a way to retrieve the device, but so far, nothing has worked. The Voidmen’s unpredictable behavior and the Glowy Hole’s mysterious nature make every plan feel like a shot in the dark. The worst part is the not knowing. Who put the Glowy Hole there? Why does it keep delivering items to the Voidspace? And what happens if the violet Voidman figures out how to truly wield the Reality Tuning Device? For now, all we can do is keep working and hope the next rubber duck isn’t the harbinger of something far worse.`
},

{
    id: 24,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Vanished',
    content: `Manhav is gone.

No one knows where he is, and the last traces of him are as fleeting as his dry wit in the lab. His workstation is untouched, his notes left open mid-thought, and yet he’s nowhere to be found. The idea that he might have gone into the Voidspace untethered feels too horrifying to consider. 

If that’s what happened, if he really stepped into the Voidspace without stabilization or an anchor, then he’s dead. Or worse, dissolved into the entropy that consumes everything there. No one has ever survived the Voidspace untethered - not even the Voidmen can exist there without the loops binding them. What could possibly have driven him to such a reckless act? Manhav was careful, deliberate. This doesn’t make sense.

I keep replaying our last conversation in my head. He was preoccupied, distracted, mentioning something about the "answers being in the chaos" and the need to "see the loops from within." At the time, I thought it was just another one of his cryptic musings, but now it feels like a warning. Did he plan this? Did he see something we didn’t? There was something behind his eyes that said so.

The truth is, I don’t know Manhav’s full motivations. I never have. He always had a way of keeping apart of himself hidden, even as we worked side by side. I can’t help but wonder if he had an ulterior motive, a secret as deeply buried as my own. I think of Virginia, of my quiet, desperate path to bring her back, and I wonder if Manhav carried something just as personal, just as consuming.

If he’s truly gone, then the loss is immeasurable. Manhav was a genius, his insights invaluable to everything we’ve done here. But more than that, he was part of this strange, chaotic family we’ve built in the lab. His absence leaves a void. Not just in our work, but in all of us at the lab.

Vale is already pouring over Manhav’s notes, hoping for a clue, a breadcrumb to lead us to him. But in my heart, I know the truth. If Manhav went into the Voidspace untethered, he’s beyond our reach. And yet, a part of me refuses to give up. If he’s out there, somewhere, somehow, then maybe there’s still a way to bring him back. But at what cost?`
},

{
    id: 25,
    date: 'DATE: ███████ TIME: ████████',
    title: 'Her Light, My Shadow',
    content: `Virginia. Her name is always there, just beneath the surface of my thoughts, woven into everything I do. She was my partner in every sense of the word - a force of brilliance, a mind so sharp it could cut through the most impossible problems with ease. We worked together on Project ████████ and Operation ████████, two of the most ambitious undertakings of our lives, the very reason I'm in the position I am today. We were building the future - peering into the hidden layers of reality, reaching for something no one else could even imagine. And we succeeded, until we didn’t.

It happened so fast. The portal destabilized, the quantum frequencies spiraled out of control, and reality itself seemed to fracture. I can still hear the alarms, feel the searing heat of the collapse as it rippled through the lab. Everyone else was gone in an instant, consumed by the energy they never had a chance to escape. But not Virginia. No, she didn’t disappear. She transformed.

What was left of her wasn’t flesh and blood but energy. Beautiful, luminous... and terrifying. A staticy echo of the woman she was. In those moments, I acted on instinct. I didn’t think; I just captured her. Like a goddamn Pokemon. The Dimensional Persistence Capsule was experimental, barely functional, but it worked. Somehow, I saved her, contained her in a fragile matrix of energy and minimized quantum resonance fields.

No one knows. No one can know. The projects were wiped clean, every record expunged, every experiment erased. To the world, I’m the only survivor, the only one left to shoulder the burden of those failures. I was reassigned, promoted even, but it didn't feel like a victory. The only thing I brought with me was her and the knowledge of what had to be done.

She’s still with me, tucked away in the capsule, waiting. For what, I don’t know. Every day I look at the egg shaped device, and I feel the weight of what I’ve done, what I’ve failed to do. I couldn’t save her body, her voice, her laugh. All I have is this flicker of what she was, and it’s not enough. It will never be enough until I am able to rematerialize her in her physical form like before. I know it's possible - the FVNN showed me the way, the steps I need to take, the paths I need to walk, the risks I need to face. Every experiment, every success brings me closer to the answer. The Reality Tuning Device, the loops, the Voidmen - it’s all part of something bigger, something that can bring her back. I have to believe that. I have to.

Virginia was the best of us. All I know is that I owe her everything. She gave me purpose, and now that purpose is all I have. 

██████████ looms closer with every passing day, but I can’t think about that now. Not yet. First, I have to finish this. I have to find a way. For her. For us. 

For everyone.`
},


    //if you're looking here, you've come too far. this is just one piece of the puzzle

]

export default acrossJournal